<template>
  <v-card color="grey lighten-4 elevation-0">
    <v-card-text v-if="meeting.status == 'draft'">
      <v-checkbox v-model="doSendInvitations" disabled color="secondary" hide-details label="Envoyer des invitations à la publication"></v-checkbox>
      <v-checkbox v-model="doCheckSettings" hide-details label="Faire valider la réunion avant de publier"></v-checkbox>
      <v-row align="center">
        <v-col class="md-6">
          <select-user
            v-if="doCheckSettings" v-model="validatingUser" :userlist="companyUsers" label="Faire valider par" autofocus hide-details
            class="ml-6" style="max-width: 400px; margin-top: -5px;">
          </select-user>
        </v-col>
        <v-col class="mx-4 px-1 md-6">
          <v-btn
            v-if="doCheckSettings && ! requestingPublish.success" :disabled="! validatingUser || requestingPublish.inprogress" color="accent" rounded outlined
            @click="requestPublishValidation">
            Envoyer le mail avec lien de validation
          </v-btn>
          <v-icon v-if="requestingPublish.success" color="green" style="justify-content: flex-start;">fa-check</v-icon>
          <span v-if="requestingPublish.error" class="error--text">{{ requestingPublish.error }}</span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <div class="mb-1"><b>Les invitations ont déjà été envoyées :</b></div>
      <div v-for="(user, index) in meeting.users" :key="index">
        <avatar-tooltiped :user="user"><b>{{ $filters.username(user) }}</b></avatar-tooltiped>
        <span v-if="user.invitation_sent_at">envoyée le {{ $filters.moment(user.invitation_sent_at, 'datetime') }}</span>
        <span v-else>non envoyée</span>
      </div>
      <v-btn :disabled="requestingPublish.inprogress" color="accent" rounded outlined style="min-width: 250px;" @click="resendInvites">
        <div v-if="! requestingPublish.inprogress" class="display-flex align-center"><v-icon size="16" class="mr-2">fa-paper-plane</v-icon>Envoyer une mise à jour</div>
        <v-progress-linear v-else :active="requestingPublish.inprogress" indeterminate></v-progress-linear>
      </v-btn>
      <div class="ml-2">
        <div v-if="requestingPublish.success">
          <v-icon size="16" class="success--text">fa-check</v-icon> &nbsp;Mise à jour envoyée à {{ $filters.moment(requestingPublish.success, 'time') }}
        </div>
        <div v-if="requestingPublish.error">
          <v-icon size="16" class="error--text">fa-exclamation-triangle</v-icon> &nbsp;{{ requestingPublish.error }}
        </div>
      </div>
    </v-card-text>
  </v-card>
  <!-- <v-card color="grey lighten-4">
    <v-card-text>
      <v-checkbox input-value="true" color="secondary" hide-details label="Faire apparaitre l'ordre du jour dans l'invitation"></v-checkbox>
      <v-checkbox input-value="true" color="secondary" hide-details label="Faire apparaitre la liste des participants dans l'invitation"></v-checkbox>
      <v-textarea v-model="invitationMsg" label="Message à ajouter à l'invitation" rows="3" @change="saveMeeting"></v-textarea>
    </v-card-text>
  </v-card> -->
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    data() {
      return {
        doSendInvitations: true,
        doCheckSettings: false,
        validatingUser: "",
        requestingPublish: { inprogress: false, success: false, error: "" },
      };
    },
    computed: {
      companyUsers() {
        return this.$store.getters['users/getCompanyUsers'];
      },
      ...mapState('current', ['meeting']),
    },
    created() {
      if (! this.meeting.users) this.$set(this.meeting, 'users', []);
    },
    methods: {
      requestPublishValidation() {
        this.requestingPublish = { inprogress: true, success: false, error: '' };
        this.meeting.requestPublishValidation({ user_id: this.validatingUser.id }).then(() => {
          this.requestingPublish.inprogress = false;
          this.requestingPublish.success = true;
        }).catch((error) => {
          this.requestingPublish.inprogress = false;
          this.requestingPublish.error = error.message;
        });
      },
      resendInvites() {
        this.requestingPublish = { inprogress: true, success: false, error: '' };
        this.meeting.sendInvites().then(() => {
          this.requestingPublish.inprogress = false;
          this.requestingPublish.success = moment();
        }).catch((error) => {
          this.requestingPublish.inprogress = false;
          this.requestingPublish.error = error.message;
        });
      },
    },
  };
</script>
