<template>
  <div>
    <div v-if="loading" class="text-center pa-4" style="font-size: 18px">
      <i class="fas fa-spinner fa-spin fa-2x fa-fw"></i>
    </div>
    <v-card v-else-if="currentSubscription" class="pa-6" style="max-width: 350px">
      <template v-if="isAdmin">
        <b class="primary--text text-uppercase">Votre abonnement actuel</b>
        <div v-for="subscriptionItem in currentSubscription" class="mt-1">
          {{ planDescriptions[subscriptionItem.plan] || subscriptionItem.plan }} : {{ subscriptionItem.quantity }}
        </div>
        <v-divider class="my-4"></v-divider>
        <b class="primary--text text-uppercase">Ajuster le nombre d'organisateurs</b>
        <div class="display-flex align-center mx-auto" style="max-width: 180px">
          <v-btn color="secondary" small icon outlined @click="maxorganizers = maxorganizers - 1"><v-icon small>fas fa-minus</v-icon></v-btn>
          <v-text-field v-model.number="maxorganizers" type="number" min="1" max="50" class="text-center"></v-text-field>
          <v-btn color="secondary" small icon outlined @click="maxorganizers = maxorganizers + 1"><v-icon small>fas fa-plus</v-icon></v-btn>
        </div>
        <div class="grey--text">Le changement sera répercuté sur la prochaine facture au prorata du mois en cours.</div>
        <div class="display-flex mt-4 justify-center">
          <v-btn :disabled="saving.inprogress" color="secondary" rounded large outlined @click="close">{{ $t('GLOBAL.CANCEL') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn :loading="saving.inprogress" color="accent" rounded large class="px-12" @click="adjustUsersQuota()">{{ $t('GLOBAL.VALIDATE') }}</v-btn>
        </div>
        <div class="red--text">{{ saving.error }}</div>
      </template>
      <div v-else class="px-6" style="max-width: 350px">
        <div class="mb-2">Seul un administrateur du compte peut modifier les abonnements.</div>
        <v-btn color="secondary" block outlined rounded class="mt-6" @click="close">Fermer</v-btn>
      </div>
    </v-card>
    <div v-else-if="premiumFutureExpireDate" class="px-6" style="max-width: 350px">
      <template v-if="canModifyBillingPlan">
        <div class="mb-2">Vous n'avez pas d'abonnement actif.</div>
        Vous bénéficiez de vos accès Premium jusqu'au {{ $filters.moment(premiumFutureExpireDate) }}.
        <v-btn color="secondary" block outlined rounded class="mt-6" @click="close">Fermer</v-btn>
      </template>
      <template v-else>
        <div class="mb-2">Votre abonnement ne peut pas être modifié ici.</div>
        Contactez-nous à <a href="mailto:premium@bubblemeeting.net">premium@bubblemeeting.net</a> pour un suivi personnalisé de votre compte.
        <v-btn color="secondary" block outlined rounded class="mt-6" @click="close">Fermer</v-btn>
      </template>
    </div>
    <template v-else>
      <b class="primary--text text-uppercase">Souscrire un abonnement Premium</b>
      <div v-if="! showPayForm" class="ml-6">
        <div class="display-flex align-center">
          <span>Nombre d'organisateurs :</span>
          <v-btn color="secondary" small icon outlined class="mx-2" @click="buyorganizers = buyorganizers - 1"><v-icon small>fas fa-minus</v-icon></v-btn>
          <v-text-field v-model.number="buyorganizers" type="number" min="1" max="50" class="text-center flex-grow-0"></v-text-field>
          <v-btn color="secondary" small icon outlined class="mx-2" @click="buyorganizers = buyorganizers + 1"><v-icon small>fas fa-plus</v-icon></v-btn>
        </div>
        <v-btn color="accent" rounded @click="showPayForm = true">Choisir le pack {{ buyorganizers < 2 ? 'START' : `TEAM pour ${buyorganizers} organisateurs` }}</v-btn>
        <div class="grey--text mt-1" style="font-size: 80%">Abonnement mensuel sans engagement de durée.</div>
        <div class="mt-12" style="font-size: 80%">Besoin d'aide pour choisir votre offre ? Contactez-nous à <a href="mailto:premium@bubblemeeting.net">premium@bubblemeeting.net</a></div>
      </div>
      <v-slide-x-reverse-transition>
        <premium-pay-form v-if="showPayForm" plan="MEETING" :quantity="buyorganizers"></premium-pay-form>
      </v-slide-x-reverse-transition>
    </template>
  </div>
</template>

<script>
  import apiSrv from '@/services/apiSrv';
  import PremiumPayForm from './PremiumPayForm';

  export default {
    components: {
      PremiumPayForm,
    },
    data() {
      return {
        loading: true,
        maxorganizers: 0,
        currentSubscription: null,
        buyorganizers: 5,
        showPayForm: false,
        saving: { inprogress: false, error: "", success: false },
        planDescriptions: {
          MEETING: 'Organisateurs Bubble Meeting',
          'MEETING COMBINE': 'Organisateurs Bubble Meeting (combiné)',
          '10CONSULT': 'Packs de 10 participants Bubble Meeting',
          TEAM: 'Contributeurs Bubble Plan',
          PERSONAL: 'Contributeurs Bubble Plan',
        },
      };
    },
    computed: {
      canModifyBillingPlan() {
        return ['TEAM', 'PERSONAL', 'MEETING'].includes(this.company && this.company.billing_plan);
      },
      premiumFutureExpireDate() {
        if (! this.isPremium) return null;
        const premiumExpireDate = moment(this.company.premiumExpireDate);
        return premiumExpireDate.isAfter() ? premiumExpireDate : null;
      },
      company() {
        return this.$store.state.users.user && this.$store.state.users.user.company;
      },
      isAdmin() { return this.$store.state.users.accessRight.isAdmin; },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    watch: {
      maxorganizers(newVal) {
        if (newVal < 1) this.$nextTick(() => { this.maxorganizers = 1; });
        if (newVal > 50) this.$nextTick(() => { this.maxorganizers = 50; });
      },
      buyorganizers(newVal) {
        if (newVal < 1) this.$nextTick(() => { this.buyorganizers = 1; });
        if (newVal > 50) this.$nextTick(() => { this.buyorganizers = 50; });
      },
    },
    created() {
      apiSrv.call('buy/plan', 'index').then(({ data } = {}) => {
        this.currentSubscription = data && data.subscription;
        if (this.currentSubscription) {
          const plansOrder = ['MEETING', '10CONSULT', 'TEAM', 'PERSONAL'];
          let meetingPlan = this.currentSubscription.find(item => item.plan == 'MEETING');
          if (! meetingPlan) {
            meetingPlan = { plan: 'MEETING', quantity: 0 };
            this.currentSubscription.push(meetingPlan);
          }
          this.maxorganizers = meetingPlan.quantity || 1;
          this.currentSubscription.sort((a, b) => (plansOrder.indexOf(a.plan) < plansOrder.indexOf(b.plan) ? -1 : 1));
        }
      }).finally(() => { this.loading = false; });
    },
    methods: {
      adjustUsersQuota() {
        this.saving = { inprogress: true, error: "", success: false };
        apiSrv.call('buy/plan/quantity', 'update', { id: this.maxorganizers, product: 'MEETING' }).then(() => {
          this.close();
          window.location.reload();
        }).catch((message) => {
          this.saving = { inprogress: false, error: `Error : ${message}` };
        });
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>
