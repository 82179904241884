<template>
  <v-list-item @click="openModal">
    <avatar-tooltiped v-if="user" :user="user"></avatar-tooltiped>
    <v-list-item-content :style="! user ? { 'margin-left': '56px' } : {}">
      <v-list-item-title>{{ action.title || 'Nouvelle action' }}</v-list-item-title>
      <v-list-item-subtitle v-if="completed">finie le {{ $filters.moment(action.completed_at, 'mediumDate') }}</v-list-item-subtitle>
      <v-list-item-subtitle v-else-if="action.due_date" :class="isLate ? 'error--text' : 'secondary--text'">pour le {{ $filters.moment(action.due_date, 'mediumDate') }}</v-list-item-subtitle>
      <v-list-item-subtitle>{{ action.category }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn v-if="completed" title="Terminé" icon ripple @click.stop="uncomplete">
        <v-icon color="grey lighten-1">far fa-check-circle</v-icon>
      </v-btn>
      <v-btn v-else title="Marquer comme terminé" icon ripple @click.stop="complete">
        <v-icon color="grey lighten-1">far fa-circle</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  export default {
    props: {
      action: { type: Object, required: true },
    },
    computed: {
      completed() {
        return !! this.action.completed_at;
      },
      user() {
        return this.$store.getters['users/getUserById'](this.action.user_id);
      },
      isLate() {
        return ! this.action.completed_at && moment(this.action.due_date).isBefore();
      },
    },
    methods: {
      openModal() {
        this.$emit('click-modal');
      },
      complete() {
        this.$emit('click-complete');
      },
      uncomplete() {
        this.$emit('click-uncomplete');
      },
    },
  };
</script>
