import minutesStructure from '@/client_customs/EdA/minutesStructure.json';

function recursiveFlatStructure(substruct, depth = 0) {
  const flatStructure = [];
  substruct.forEach((item) => {
    item.depth = depth;
    flatStructure.push(item);
    if (item.children) {
      flatStructure.push(...recursiveFlatStructure(item.children, depth + 1));
      delete item.children;
    }
  });
  return flatStructure;
}

export default {
  minutesStructure,
  flatStructure: recursiveFlatStructure(JSON.parse(JSON.stringify(minutesStructure))),
};
