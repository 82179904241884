import axios from 'axios';
import store from '@/store';
import debugSrv from '@/services/debugSrv';

let host = 'https://api.bubbleplan.net/';
const snapBaseUrl = 'https://snap2.bubbleplan.net/export/';
let liveSnapServer = 'https://snap3.bubbleplan.net/';
let integrationsServer = 'https://integrations.bubbleplan.net/';
if (debugSrv.local) {
  host = `http://${window.location.hostname}:8080/BBPApi/api/`;
  //    snapBaseUrl = 'https://snap2.bubbleplan.net:3443/export/';
  liveSnapServer = 'http://localhost:3001/';
  integrationsServer = 'http://localhost:3002/';
}

function url(api, action, data) {
  let urlApi = api;
  if (action == 'show' || action == 'destroy') {
    if (typeof data === 'object') {
      urlApi = `${api}/${data.id}`;
    } else {
      urlApi = `${api}/${data}`;
    }
  }
  if (action == 'update' && data && data.id) urlApi = `${urlApi}/${data.id}`;
  if (action == 'share') urlApi = `plannings/${data.id}/${urlApi}`;
  if (action == 'export') return urlApi == 'view' ? `${liveSnapServer}view/${data.id}` : liveSnapServer + data.id;
  if (api == 'pdf') return `${liveSnapServer}pdf/`;
  if (typeof urlApi == 'string' && urlApi.substr(0, 'integrations'.length) === 'integrations') return integrationsServer + urlApi.substr(13);
  return api.indexOf('meeting') > -1 ? `${host}v2/${urlApi}` : `${host}v1/${urlApi}`;
}

function method(api, action) {
  if (action == 'store') return "POST";
  if (action == 'destroy') return "DELETE";
  if (action == 'update') return "PUT";
  return "GET";
}

function getAuthToken() {
  return localStorage.getItem("api_token");
}

function setAuthToken(token) {
  return localStorage.setItem("api_token", token);
}

function deleteAuthToken() {
  localStorage.removeItem("api_token");
}

/* mobile token */
function getMobileToken() {
  return sessionStorage.getItem("mobile_token");
}

function setMobileToken(token) {
  return sessionStorage.setItem("mobile_token", token);
}

//  function deleteMobileToken() {
//    sessionStorage.removeItem("mobile_token");
//  }

function call(api, action, data, silent) {
  const mymethod = method(api, action);
  const myurl = url(api, action, data);
  let myparams = {};// window.location.search;
  if (mymethod == "GET" && data !== null && typeof data === 'object') myparams = data;
  if (api == 'pdf' && data && data.html) {
    const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname || '/'}`;
    data.html = data.html.replace(/>[\n\t ]+</g, "><").replace(/url\(\/?static\//g, `url(${baseUrl}static/`).replace(/"\/?static\//g, `"${baseUrl}static/`);
  }
  if (debugSrv.debug) console.log(`calling api ${mymethod} ${myurl}`);
  const authToken = getAuthToken();
  const mobileToken = getMobileToken();
  return axios({
    method: mymethod,
    url: myurl,
    data,
    params: myparams,
    headers: _.extend({}, authToken && { 'X-Auth-Token': authToken }, mobileToken && { 'Mobile-Auth-Token': mobileToken }),
  }).catch((axiosError) => {
    if (axiosError.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { response } = axiosError;
      let errorMessage = response && response.data && response.data.message || response.statusText || (store.state.lang.i18n.t("API.SOMETHING_WRONG") + response.status);
      if (! response.status || response.status == -1) errorMessage = store.state.lang.i18n.t("API.SERVER_NOT_RESPONDING");
      try {
        errorMessage = JSON.parse(errorMessage);
      } catch (e) {}
      if (typeof errorMessage == 'object') {
        errorMessage = Object.keys(errorMessage).map((key) => {
          const messages = errorMessage[key];
          if (Array.isArray(messages)) return `- ${messages.join('\n- ')}`;
          return messages;
        }).join("\n");
      }
      console.log("Error :", errorMessage);
      console.log(response, silent);
      if (response.status == 401 && ! silent) {
        store.commit('ui/setLoginWindow', true);
      }
      const returnError = new Error(errorMessage);
      returnError.status = response.status;
      return Promise.reject(returnError);
    }
    if (axiosError.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(axiosError.request);
      return Promise.reject(new Error(store.state.lang.i18n.t("API.SERVER_NOT_RESPONDING")));
    }
    // Something happened in setting up the request that triggered an Error
    console.log('Error axios', axiosError.message);
    return Promise.reject(new Error(axiosError.message));
  });
}

function login({ captcha, source, ...params }) {
  return call('users/login', 'store', { 'g-recaptcha-response': captcha, ...params }).then((response) => {
    // console.log("login success");
    setAuthToken(response.data && response.data.token);
    window.location.reload();
  });
}

function logout() {
  const token = getAuthToken();
  if (! token) {
    deleteAuthToken();
    return Promise.reject(new Error("Already logged out"));
  }
  return axios({
    method: 'GET',
    url: url('users/logout'),
    headers: { 'X-Auth-Token': token },
  }).then(deleteAuthToken);
}

function ssoLogin(params) {
  return call('users/login/sso', 'store', params).then((response) => {
    console.log("sso login success");
    const { token, source } = response.data || {};
    setAuthToken(token, source);
  });
}

let isDisplayingSuspended = false;
function displaySuspended() {
  if (isDisplayingSuspended) return;
  isDisplayingSuspended = true;
  /*  $uibModal.open({
    animation: false,
    template: '<div premium-suspended-window></div>',
    backdrop: 'static',
    windowClass: 'backdrop-dark',
    keyboard: false,
  }); */
}

function uploadResource(file, apiFile = 'files') {
  if (debugSrv.debug) console.log('upload file', file);
  const filename = file.name.replace(/'/g, "’");

  const data = new FormData();
  data.append('name', filename);
  data.append('file', file, filename);
  return axios({
    method: 'POST',
    url: url(apiFile, 'store'),
    data,
    headers: {
      'X-Auth-Token': getAuthToken() || undefined,
      'Mobile-Auth-Token': getMobileToken() || undefined,
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
    onUploadProgress(progressEvent) {
      file.progress = Number(100.0 * progressEvent.loaded / progressEvent.total);
    },
  }).then((response) => {
    if (debugSrv.debug) console.log('file uploaded', response);
    return response;
  }).catch((axiosError) => {
    if (axiosError.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { response } = axiosError;
      let errorMessage = response && response.data && response.data.message || response.statusText || (store.state.lang.i18n.t("API.SOMETHING_WRONG") + response.status);
      if (! response.status || response.status == -1) errorMessage = store.state.lang.i18n.t("API.SERVER_NOT_RESPONDING");
      try {
        errorMessage = JSON.parse(errorMessage);
      } catch (e) {
        console.log(e);
      }
      console.log("Error : ", errorMessage);
      console.log(response);
      if (response.status == 401) {
        store.commit('ui/setLoginWindow', true);
      }
      return Promise.reject(errorMessage);
    }
    if (axiosError.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(axiosError.request);
      return Promise.reject(store.state.lang.i18n.t("API.SERVER_NOT_RESPONDING"));
    }
    // Something happened in setting up the request that triggered an Error
    console.log('Error', axiosError.message);
    return Promise.reject(axiosError.message);
  });
}

function error(message) {
  return Promise.reject(message);
}

function success(data) {
  return Promise.resolve({ data });
}

function all(promises) {
  return Promise.all(promises);
}

window.apiSrv = {
  host,
  snapBaseUrl,
  integrationsServer,
  url,
  call,
  uploadResource,
  login,
  logout,
  ssoLogin,
  displaySuspended,
  setAuthToken,
  getAuthToken,
  setMobileToken,
  error,
  success,
  all,
};
export default window.apiSrv;
