<template>
  <v-navigation-drawer
    v-resize="updateWindowSize" :mini-variant="miniVariant" :temporary="! miniVariant && ! isLargeScreen"
    :height="miniVariant ? 'auto' : '80%'" right permanent mini-variant-width="48" clipped enable-resize-watcher fixed app width="500"
    class="pb-0 elevation-4"
    style="top: calc(50% + 24px); transform: translateY(-50%); margin: auto; border-top-left-radius: 12px; border-bottom-left-radius: 12px; overflow: hidden">
    <div class="display-flex" style="height: 100%">
      <div v-if="! miniVariant" cols="12" class="pa-2" style="flex: 1 1 100%; overflow-y: auto;">
        <component :is="'side-Drawer-' + tabIndex" class="mb-12" @close-drawer="miniVariant = true"><div class="pa-4">Erreur : page introuvable</div></component>
      </div>
      <div style="overflow-y: auto; overflow-x: hidden; flex: 0 0 40px">
        <div class="pb-1 display-flex align-center" style="flex-direction: column;">
          <v-btn v-if="! miniVariant" color="grey" text icon small class="mb-6" @click="closeNav"><v-icon small>fas fa-times-circle</v-icon></v-btn>
          <v-tooltip v-for="(item, index) in items" :key="index" left open-delay="0">
            <template #activator="{ on }">
              <v-btn
                :color="tabIndex == item.id ? 'primary' : 'grey'" :disabled="isDisabled(item)" text icon class="mt-1 mb-0"
                v-on="on" @click="clickNavButton(item.id)">
                <v-icon size="20" v-html="item.icon"></v-icon>
                <span v-if="item.id == 'files' && filesCount" class="primary white--text navigation-drawer-notif">{{ filesCount }}</span>
                <span v-if="item.id == 'chat' && commentsCount" class="primary white--text navigation-drawer-notif">{{ commentsCount }}</span>
              </v-btn>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<style>
  .navigation-drawer-notif {
    border-radius: 50%;
    font-size: .8em;
    font-weight: bold;
    position: absolute;
    padding: 0px 5px;
    top: -8px;
    right: -2px;
  }
</style>

<script>
  import { mapState, mapGetters } from 'vuex';
  import SideDrawerSettings from './SideDrawerSettings';
  import SideDrawerMinutes from './SideDrawerMinutes';
  import SideDrawerNotes from './SideDrawerNotes';
  import SideDrawerFiles from './SideDrawerFiles';
  import SideDrawerDetails from './SideDrawerDetails';
  import SideDrawerChat from './SideDrawerChat';

  export default {
    components: {
      SideDrawerSettings,
      SideDrawerMinutes,
      SideDrawerNotes,
      SideDrawerFiles,
      SideDrawerDetails,
      SideDrawerChat,
    },
    data() {
      return {
        items: [
          { id: 'details', icon: 'fas fa-info', title: 'Détails pratiques' },
          { id: 'minutes', icon: 'fas fa-file-signature', title: 'Compte rendu' },
          { id: 'files', icon: 'fas fa-folder', title: 'Documents' },
          { id: 'chat', icon: 'fas fa-comments', title: 'Discussions' },
          { id: 'notes', icon: 'fas fa-user-secret', title: 'Notes personnelles' },
          { id: 'settings', icon: 'fas fa-cog', title: 'Configuration' },
        ],
        miniVariant: true,
        tabIndex: '',
        isLargeScreen: false,
      };
    },
    computed: {
      filesCount() {
        return this.meeting.links && this.meeting.links.length;
      },
      commentsCount() {
        return this.meeting.comments && this.meeting.comments.length;
      },
      ...mapState('current', ['meeting']),
      ...mapGetters('current', ['isMeetingAdmin']),
    },
    mounted() {
      this.updateWindowSize();
    },
    methods: {
      clickNavButton(tab) {
        if (tab == this.tabIndex) {
          this.closeNav();
        } else {
          this.tabIndex = tab;
          this.miniVariant = false;
        }
      },
      closeNav() {
        this.miniVariant = true;
        this.tabIndex = '';
      },
      updateWindowSize() {
        this.isLargeScreen = window.innerWidth >= 1264;
      },
      isDisabled(item) {
        if (item.id == 'minutes') return ! this.isMeetingAdmin || ! ['animation', 'minutes'].includes(this.meeting.status);
        if (item.id == 'settings') return ! this.isMeetingAdmin || this.meeting.status == 'closed';
        return false;
      },
    },
  };
</script>
