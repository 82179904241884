<template>
  <container-template>
    <template v-if="meeting.status == 'preparation'">
      <v-stepper
        v-if="userPreparationSteps.length && stepIndex != null" :value="stepIndex" vertical non-linear class="elevation-0"
        @change="changeIndex($event)">
        <div v-for="(step, index) in userPreparationSteps" :key="index">
          <v-stepper-step
            :rules="[() => stepIsPrepared(step) || index >= stepIndex - 1]" :complete="stepIsPrepared(step)" :step="index + 1"
            error-icon="fas fa-stopwatch" editable edit-icon="fas fa-check" @click="clickStep(index + 1)">
            {{ step.title }}
          </v-stepper-step>
          <v-stepper-content :step="index + 1">
            <component :is="'preparation-' + step.module" :module-name="step.module_name" class="mb-6"></component>
            <div class="pb-2">
              <v-btn color="accent" rounded class="mx-2" @click.native="saveStep(index, step, true)">Valider</v-btn>
              <v-btn color="secondary" rounded outlined class="mx-2" @click.native="saveStep(index, step)">Revenir plus tard</v-btn>
            </div>
          </v-stepper-content>
        </div>
      </v-stepper>
      <div class="display-flex align-center justify-center">
        <v-btn :to="{ name: 'Home' }" :color="isComplete ? 'accent' : 'secondary'" :outlined="! isComplete" rounded>
          <div style="border-radius: 30px" class="mx-2 px-2 primary lighten-1">{{ preparedSteps.length }} / {{ userPreparationSteps.length }}</div>
          {{ isComplete ? "Préparation terminée" : "Revenir à l'accueil" }}
        </v-btn>
      </div>
    </template>
    <template v-else>
      <p v-if="meeting.status == 'draft'">La préparation sera ouverte une fois la réunion publiée.</p>
      <div v-else>
        <p>La préparation de cette réunion est close.</p>
        <div class="text-h6 my-6">Préparation des participants</div>
        <users-preparation-table></users-preparation-table>
      </div>
    </template>
  </container-template>
</template>

<style scoped>
  .error--text {
    color: #ff9b1d !important;
  }
</style>

<script>
  import { mapState } from 'vuex';
  import ContainerTemplate from '@/components/Reusables/ContainerTemplate';
  import PreparationMoods from './PreparationMoods';
  import PreparationIssues from './PreparationIssues';
  import PreparationTopics from './PreparationTopics';
  import PreparationKpis from './PreparationKpis';
  import PreparationNews from './PreparationNews';
  import PreparationActionsEvolution from './PreparationActionsEvolution';
  import UsersPreparationTable from './partials/UsersPreparationTable';

  export default {
    components: {
      PreparationMoods,
      PreparationIssues,
      PreparationTopics,
      PreparationKpis,
      PreparationNews,
      'preparation-actionsevolution': PreparationActionsEvolution,
      ContainerTemplate,
      UsersPreparationTable,
    },
    data() {
      return {
        stepIndex: null,
        preparedSteps: [],
      };
    },
    computed: {
      userPreparationSteps() {
        return this.meeting.getUserPreparationSteps(this.meetingUser);
      },
      isComplete() {
        return this.preparedSteps.length == this.userPreparationSteps.length;
      },
      ...mapState('current', ['meeting', 'meetingUser']),
    },
    mounted() {
      this.firstNonValidatedStep();
    },
    created() {
      this.preparedSteps = this.userPreparationSteps.filter((step) => {
        const module = this.meeting.modules.find(item => item.module_name == step.module_name && item.user_id == this.meetingUser.id);
        return module?.validated_at;
      });
    },
    methods: {
      passStep(index) {
        this.firstNonValidatedStep(index + 1);
      },
      stepIsPrepared(step) {
        return this.preparedSteps.findIndex(stepItem => step.module_name == stepItem.module_name) != -1;
      },
      saveStep(index, step, validated = false) {
        const module = this.meeting.modules.find(item => item.module_name == step.module_name && item.user_id == this.meetingUser.id);
        if (! module) return;
        this.meeting.sendModule(module, validated);
        if (! module.first_validated_at) module.first_validated_at = moment().format();
        module.validated_at = moment().format();
        if (this.preparedSteps.indexOf(step) == -1) this.preparedSteps.push(step);
        this.passStep(index);
      },
      clickStep(index) {
        if (this.stepIndex !== null && index == this.stepIndex) this.$nextTick(() => { this.stepIndex = -1; });
      },
      changeIndex(index) {
        const step = this.userPreparationSteps[index - 1];
        if (index < 1 || ! step) return;
        this.stepIndex = this.stepIndex == null ? null : index;
      },
      firstNonValidatedStep(index = 0) {
        let stepIndex = -1;
        if (! this.userPreparationSteps.length) {
          this.stepIndex = null;
          return;
        }
        for (let i = index; i < this.userPreparationSteps.length; i++) {
          if (! this.stepIsPrepared(this.userPreparationSteps[i])) {
            stepIndex = i + 1;
            break;
          }
        }
        this.stepIndex = stepIndex == -1 ? this.userPreparationSteps.length + 1 : stepIndex;
      },
    },
  };
</script>
