<template>
  <container-template id="meeting-home">
    <template #actions>
      <div v-if="meeting.status == 'closed'" class="display-flex">
        <!-- Export Word -->
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn :loading="exportingWord.inprogress" color="secondary" outlined rounded class="export-hidden" v-bind="attrs" v-on="on">
              <v-icon small class="mr-2">fas fa-file-word</v-icon>
              <span>Export docx</span>
              <v-icon dark small class="ml-1">fas fa-caret-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(closureTemplate, index) in meetingClosureTemplates" :key="index" link
              :to="! closureTemplate.url ? '/manage?tab=configuration' : ''"
              @click="closureTemplate.url ? wordExport(closureTemplate) : ''">
              <v-list-item-title>{{ closureTemplate.name || closureTemplate.url }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- Export PDF -->
        <v-btn :loading="exportingPdf.inprogress" color="secondary" outlined rounded class="ml-2 export-hidden" @click="pdfExport">
          <v-icon small class="mr-2">fas fa-file-pdf</v-icon>Export pdf
        </v-btn>
      </div>
    </template>
    <v-row>
      <v-col v-if="hasSettingsButton" cols="12">
        <v-btn :to="{ name: 'Settings' }" color="secondary" rounded right>
          <i class="fa fa-cog mr-2"></i>Modifier les paramètres
        </v-btn>
      </v-col>
      <v-col sm="6" class="display-flex export-hidden">
        <div style="width: 32px; flex: 0 0 auto"><v-icon small>fa-user</v-icon></div>
        <meeting-details content="aboutme" style="flex: 1 1 100%"></meeting-details>
      </v-col>
      <v-col sm="6">
        <div class="display-flex">
          <div style="width: 32px; flex: 0 0 auto"><v-icon small>fa-calendar-alt</v-icon></div>
          <meeting-details content="date" style="flex: 1 1 100%"></meeting-details>
        </div>
        <div class="display-flex mt-2">
          <div style="width: 32px; flex: 0 0 auto">
            <v-icon small>fas {{ (meeting.location_address || '').startsWith('http') ? 'fa-webcam' : 'fa-map-marker-alt' }}</v-icon>
          </div>
          <meeting-details content="place" style="flex: 1 1 100%"></meeting-details>
        </div>
      </v-col>
      <v-col cols="12">
        <v-divider color="grey" style="margin-left: 32px"></v-divider>
      </v-col>
      <v-col cols="12" class="display-flex">
        <div style="width: 32px; flex: 0 0 auto"><v-icon small>fas fa-users</v-icon></div>
        <meeting-details content="attendees" style="flex: 1 1 100%"></meeting-details>
      </v-col>
      <v-col cols="12">
        <v-divider color="grey" style="margin-left: 32px"></v-divider>
      </v-col>
      <v-col cols="12" class="display-flex">
        <div style="width: 32px; flex: 0 0 auto"><v-icon small>far fa-list-alt</v-icon></div>
        <meeting-details content="description" style="flex: 1 1 100%"></meeting-details>
      </v-col>
      <v-col v-if="meeting.status == 'closed'" cols="12">
        <v-divider color="grey" style="margin-left: 32px"></v-divider>
      </v-col>
      <v-col v-if="meeting.status == 'closed'" cols="12" class="display-flex">
        <div style="width: 32px; flex: 0 0 auto"><v-icon small>fas fa-file-signature</v-icon></div>
        <meeting-details content="summary" style="flex: 1 1 100%"></meeting-details>
      </v-col>
    </v-row>
  </container-template>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import apiSrv from '@/services/apiSrv';
  import defaultConfig from '@/js/defaultConfig';
  import ContainerTemplate from '@/components/Reusables/ContainerTemplate';
  import MeetingDetails from '@/components/MeetingDetails/MeetingDetails';

  export default {
    components: {
      ContainerTemplate,
      MeetingDetails,
    },
    data() {
      return {
        openPreparationStatusModal: false,
        exportingWord: { inprogress: false, success: false, error: false },
        exportingPdf: { inprogress: false, success: false, error: false },
      };
    },
    computed: {
      hasSettingsButton() {
        return this.isMeetingAdmin && this.meeting.status != 'closed';
      },
      meetingClosureTemplates() {
        const { closureTemplates } = this.$store.getters['users/accessRight/config'];
        return closureTemplates.length ? closureTemplates : [defaultConfig.wordDefaultTemplate(), { name: 'Charger un modèle' }];
      },
      ...mapState('current', ['meeting']),
      ...mapGetters('current', ['isMeetingAdmin']),
    },
    methods: {
      wordExport(template) {
        import('@/services/wordSrv').then((wordSrv) => {
          _.extend(this.exportingWord, { inprogress: true, success: false, error: false });
          wordSrv.default.wordExport(template, this.meeting).then(() => {
            this.exportingWord.inprogress = false;
            this.exportingWord.success = true;
            setTimeout(() => { this.exportingWord.success = false; }, 3000);
          }).catch((error) => {
            this.exportingWord.inprogress = false;
            this.exportingWord.error = error?.message || "Error : not exported";
            this.$store.dispatch('ui/msgbox/open', { title: `${this.$t("GLOBAL.ERROR")} : génération du fichier docx impossible`, body: this.exportingWord.error });
          });
        });
      },
      pdfExport() {
        _.extend(this.exportingPdf, { inprogress: true, success: false, error: false });
        const $html = document.querySelector("html").cloneNode(true);
        let queryEl = $html.querySelector('#main-card');
        if (queryEl) queryEl.style.setProperty('box-shadow', 'none', 'important');
        queryEl = $html.querySelector('#main-card-header');
        if (queryEl) queryEl.classList.add('pl-5');
        queryEl = $html.querySelector("#meeting-home");
        $html.querySelector("body").innerHTML = `<div class="container container--fluid pa-6">${queryEl.innerHTML}</div>`;
        $html.querySelector('body').classList.add('v-application');
        $html.querySelectorAll(".export-hidden, .category-corner").forEach((el) => { el.parentNode.removeChild(el); });
        $html.querySelectorAll(".v-avatar").forEach((el) => {
          const imgTag = el.querySelector('img');
          el.outerHTML += imgTag && imgTag.alt || '';
        });
        $html.querySelectorAll(".v-divider").forEach((el) => { el.classList.add('my-0'); });
        $html.querySelectorAll(".editor-text > *").forEach((el) => { el.style.setProperty('break-inside', 'avoid'); });

        const meetingDate = moment(this.meeting.date).format("dddd DD MMMM YYYY [à] HH:mm");
        apiSrv.call('pdf', 'store', {
          html: $html.innerHTML,
          orientation: "portrait",
          logo: 'meeting',
          footer: `Réunion ${this.meeting.title} <span style="white-space: nowrap">du ${meetingDate || ''}</span>`,
        }).then((response) => {
          if (response && response.data && response.data.pdfurl) {
            this.exportingPdf.inprogress = false;
            this.exportingPdf.success = true;
            setTimeout(() => { this.exportingPdf.success = false; }, 3000);
            window.open(`${response.data.pdfurl}/${this.meeting.title} du ${meetingDate || ''}.pdf`, "_blank");
          }
        }).catch((message) => {
          this.exportingPdf.inprogress = false;
          this.exportingPdf.error = message || "Error : not exported";
        });
      },
    },
  };
</script>
