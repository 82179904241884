import Vue from 'vue';
import VueI18n from 'vue-i18n';
import vuetifyEN from 'vuetify/src/locale/en.ts';
import vuetifyFR from '@/lang/vuetifyFR';

const texts = require('@/lang/fr.json');

Vue.use(VueI18n);

const initLang = 'fr'; // localStorage.getItem('lang') || 'en';
const i18n = new VueI18n({
  locale: initLang,
  fallbackLocale: 'en',
  messages: {
    en: { $vuetify: vuetifyEN, ...texts },
    fr: { ...vuetifyFR, ...texts },
  },
});

export default {
  namespaced: true,
  state: {
    i18n,
    lang: initLang,
  },
  mutations: {
  },
  actions: {
  },
};
