import apiSrv from '@/services/apiSrv';

/* MUTATIONS */
function setPendingInvites(state, newPendingInvites) {
  state.pending_invites = newPendingInvites || [];
  state.pending_invites.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
}

/* ACTIONS */
function loadPendingInvites({ state }) {
  return apiSrv.call('companyinvites/pending', 'index').then((response) => {
    state.pending_invites = response && response.data && response.data.invites || [];
    state.pending_invites.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
    return response;
  });
}

function deletePendingInvite({ state }, invite) {
  return apiSrv.call(`companyinvites/pending`, 'destroy', invite.email).then((response) => {
    const index = state.pending_invites.indexOf(invite);
    if (index > -1) state.pending_invites.splice(index, 1);
    return response;
  });
}

function sendInvite({ state }, data = {}) {
  return apiSrv.call('companyinvites/invite', 'store', { product: 'meeting', ...data }).then((response) => {
    const invite = response?.data?.invite;
    if (invite) {
      const index = state.pending_invites.findIndex(item => item.email == invite.email);
      if (index > -1) state.pending_invites.splice(index, 1);
      state.pending_invites.push(invite);
      state.pending_invites.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
    }
    return response;
  });
}

export default {
  namespaced: true,
  modules: {},
  state: {
    pending_invites: [],
  },
  mutations: {
    setPendingInvites,
  },
  actions: {
    loadPendingInvites,
    deletePendingInvite,
    sendInvite,
  },
};
