import Vue from 'vue';
import apiSrv from '@/services/apiSrv';

function findModuleOfIssue(issue, modules) {
  for (let i = 0; i < modules.length; i++) {
    const module = modules[i];
    if (_.isArray(module.content) && module.content.find(item => item.id == issue.id)) {
      return module;
    }
  }
  return null;
}

export default {
  namespaced: true,
  modules: {},
  state: {
    actions: [],
    decisions: [],
    fridge: [],
    historicFridge: [],
    historicDecisions: [],
    modulesContent: {},
    currentUserModule: null,
  },
  getters: {
    isIssueInGroup: state => (issue, group) => {
      if (! issue || ! ['decisions', 'actions', 'fridge', 'historicFridge'].includes(group)) return -1;
      return state[group].findIndex(issueItem => issueItem.id == issue.id);
    },
    getGroupOf: (state, getters) => (issue) => {
      const groups = ['decisions', 'actions', 'fridge', 'historicFridge'];
      if (! issue) return null;
      for (let i = 0; i < groups.length; i++) {
        const groupKey = groups[i];
        const index = getters.isIssueInGroup(issue, groupKey);
        if (index != -1) {
          return { groupKey, index };
        }
      }
      return null;
    },
    getById: state => (issueId) => {
      if (! state.modulesContent) return null;
      const modulesId = Object.keys(state.modulesContent);
      for (let i = modulesId.length - 1; i >= 0; i--) {
        const moduleItem = state.modulesContent[modulesId[i]];
        for (let j = moduleItem.length - 1; j >= 0; j--) {
          if (moduleItem[j].id == issueId) return moduleItem[j];
        }
      }
      const issueInHistoricFridge = state.historicFridge.find(item => item.id == issueId);
      const issueInHistoricDecisions = issueInHistoricFridge || state.historicDecisions.find(item => item.id == issueId);
      return issueInHistoricFridge || issueInHistoricDecisions || null;
    },
    unsortedIssues: (state, getters) => {
      const unsortedIssues = [];
      if (! state.modulesContent) return unsortedIssues;
      const modulesId = Object.keys(state.modulesContent);
      for (let i = modulesId.length - 1; i >= 0; i--) {
        const moduleItem = state.modulesContent[modulesId[i]];
        for (let j = moduleItem.length - 1; j >= 0; j--) {
          if (! getters.getGroupOf(moduleItem[j])) unsortedIssues.push(moduleItem[j]);
        }
      }
      return unsortedIssues;
    },
    fridgeVisibleIssues: (state) => {
      const hiddenId = [];
      const issues = state.fridge.reduce((shownIssues, issue) => {
        if (issue.processed) {
          hiddenId.push(issue.id);
        } else {
          shownIssues.push(issue);
        }
        return shownIssues;
      }, []);
      return issues.concat((state.historicFridge || []).filter(item => ! hiddenId.includes(item.id)));
    },
  },
  mutations: {
    add(state, { newIssue }) {
      state.modulesContent[state.currentUserModule.id] = state.modulesContent[state.currentUserModule.id] || [];
      state.modulesContent[state.currentUserModule.id].push(newIssue);
    },
    changeGroup(state, { issue, newGroup = null, formerGroupDatas = null }) {
      if (! formerGroupDatas && ! newGroup) return;
      if (formerGroupDatas && formerGroupDatas.groupKey == 'historicFridge') {
        state.fridge.push(Object.assign(state.historicFridge[formerGroupDatas.index], { processed: true }));
      } else if (formerGroupDatas) {
        if (newGroup && (newGroup == formerGroupDatas.groupKey)) return;
        state[formerGroupDatas.groupKey].splice(formerGroupDatas.index, 1);
      }
      if (newGroup) state[newGroup].push(issue);
    },
    delete(state, issue) {
      const modulesId = Object.keys(state.modulesContent);
      for (let i = modulesId.length - 1; i >= 0; i--) {
        const moduleItem = state.modulesContent[modulesId[i]];
        for (let j = moduleItem.length - 1; j >= 0; j--) {
          if (issue.id == moduleItem[j].id) {
            moduleItem.splice(j, 1);
            return;
          }
        }
      }
    },
    initMeetingIssues(state, { meeting, historicFridge, historicDecisions }) {
      ['decisions', 'fridge', 'actions'].forEach((group) => {
        if (! meeting[group]) Vue.set(meeting, group, []);
        state[group] = group == 'actions' ? meeting.actions.map(item => item.issue).filter(item => item) : meeting[group];
      });
      const stepsName = meeting.steps.filter(item => item.module == 'issues').map(item => item.module_name);
      state.modulesContent = meeting.modules.filter(item => stepsName.findIndex(stepName => item.module_name == stepName) != -1).reduce((modulesContent, item) => {
        if (item.content) modulesContent[item.id] = item.content.filter(issue => issue && issue.id);
        return modulesContent;
      }, {});
      state.historicFridge = historicFridge;
      state.historicDecisions = historicDecisions;
    },
  },
  actions: {
    saveModuleContent({ rootState, state }, othersModule) {
      const { meeting } = rootState.current;
      const module = othersModule || state.currentUserModule;
      if (! module) return;
      module.content = state.modulesContent[module.id];
      meeting.sendModule(module);
    },
    saveGroups({ rootState, state }) {
      const { meeting } = rootState.current;
      meeting.decisions = state.decisions;
      meeting.fridge = state.fridge;
      // action issue is saved in actions store
      rootState.current.meeting.saveFields(['fridge', 'decisions', 'actions']);
    },
    add({ commit, dispatch }, issue) {
      commit('add', { newIssue: issue });
      dispatch('saveModuleContent');
    },
    changeGroup({ getters, commit, dispatch }, { issue, newGroup = null }) {
      const formerGroupDatas = getters.getGroupOf(issue);
      if (getters.isIssueInGroup(issue, 'historicFridge') != -1 && (! newGroup || newGroup == 'fridge')) {
        dispatch('removeFromGroup', { issue });
      } else {
        commit('changeGroup', { issue, newGroup, formerGroupDatas });
        dispatch('saveGroups');
      }
    },
    delete({ dispatch }, issue) {
      dispatch('removeFromGroup', { issue, totalDelete: true });
    },
    updateIssue({ state, getters, dispatch, rootState }, { issue, group }) {
      const groupDatas = getters.getGroupOf(issue);
      if (! group && getters.isIssueInGroup(issue, 'historicFridge') == -1) {
        const formerIssue = getters.getById(issue.id);
        if (! formerIssue) throw new Error("Updating issue does not exist");
        Object.assign(formerIssue, issue);
        const module = findModuleOfIssue(issue, rootState.current.meeting.modules);
        dispatch('saveModuleContent', module);
      }
      if (! groupDatas || groupDatas.groupKey != group) {
        dispatch('changeGroup', { issue, newGroup: group, formerGroupDatas: groupDatas });
      } else if (groupDatas) {
        Vue.set(state[groupDatas.groupKey], groupDatas.index, issue);
        dispatch('saveGroups');
      }
    },
    removeFromGroup({ dispatch, getters, commit, state, rootState }, { issue, totalDelete = false }) {
      const groupDatas = getters.getGroupOf(issue);
      const inHistoricFridge = getters.isIssueInGroup(issue, 'historicFridge');
      if (inHistoricFridge != -1) {
        const fridgeIndex = getters.isIssueInGroup(issue, 'fridge');
        if (fridgeIndex <= -1) return;
        state.fridge.splice(fridgeIndex, 1);
        state[groupDatas.groupKey].splice(groupDatas.index, 1);
        dispatch('saveGroups');
        return;
      }
      if (groupDatas) {
        dispatch('changeGroup', { issue });
        if (! totalDelete) return;
      }
      commit('delete', issue);
      const module = findModuleOfIssue(issue, rootState.current.meeting.modules);
      dispatch('saveModuleContent', module);
    },
    initMeetingIssues({ commit }, meeting) {
      return apiSrv.call(`meetings/${meeting.id}/historicissues`, 'index').then((response) => {
        if (! response || ! response.data || ! response.data.decisions || ! response.data.fridge) throw new Error("Issue in GET historicIssues request");
        commit('initMeetingIssues', { meeting, historicFridge: response.data.fridge, historicDecisions: response.data.decisions });
      });
    },
  },
};
