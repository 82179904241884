<template>
  <div class="mt-4">
    <h3>{{ step.title }} </h3>
    <template v-if="usersValidations.validatedBy.length">
      <h4>Validé par :</h4>
      <template v-for="user in usersValidations.validatedBy">
        <!-- Username -->
        <span style="display: flex; align-items: center;">
          <img :src="user.user_avatar" :alt="user.user_name" class="v-avatar ma-2" style="height: 25px; width: 25px;">
          <span>{{ user.user_name }}</span>
          <span v-if="user.comment" class="ml-2 pre"> : {{ user.comment }}</span>
        </span>
      </template>
    </template>
    <template v-if="usersValidations.pendingValidation.length">
      <h4>En attente de validation :</h4>
      <template v-for="user in usersValidations.pendingValidation">
        <!-- Username -->
        <span style="display: flex; align-items: center;">
          <img :src="user.user_avatar" :alt="user.user_name" class="v-avatar ma-2" style="height: 25px; width: 25px;">
          <span>{{ user.user_name }}</span>
          <span v-if="user.comment" class="ml-2 pre"> : {{ user.comment }}</span>
        </span>
      </template>
    </template>
  </div>
</template>

<script>
  import meetingClosureModuleMixin from './meetingClosureModuleMixin';

  export default {
    mixins: [meetingClosureModuleMixin],

    computed: {
      usersValidations() {
        const validatedBy = [];
        const pendingValidation = [];
        this.meeting.users.forEach((user) => {
          const userModule = this.meeting.getUserModule(this.step.module_name, user, []);
          const userData = {
            user_id: user.id,
            user_avatar: user.avatar,
            user_name: this.$store.getters['users/getUsername'](user),
            comment: userModule.content?.comment,
          };
          if (userModule.content?.validated) {
            validatedBy.push(userData);
          } else {
            pendingValidation.push(userData);
          }
        });
        return {
          validatedBy,
          pendingValidation,
        };
      },
    },
  };
</script>
