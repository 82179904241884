<template>
  <v-row v-if="files" class="py-2">
    <v-col :class="{ 'col-12': fullWidth, 'col-6 offset-3': ! fullWidth }" class="slider-container pa-1" style="position: relative">
      <div :style="{ 'max-height': fullHeight ? '700px' : '100px' }" class="image-container text-center">
        <img :src="files[fileIndex].url" style="max-width: 100%; ">
      </div>
      <div v-if="fileIndex > 0" class="browse-file-btn prev" @click="fileIndex -= 1">
        <v-icon color="white">fa-chevron-left</v-icon>
      </div>
      <div v-if="fileIndex < files.length - 1" class="browse-file-btn next" @click="fileIndex += 1">
        <v-icon color="white">fa-chevron-right</v-icon>
      </div>
      <div style="position: absolute !important;top:-30px; right:-20px">
        <v-btn small fab @click="fullHeight = ! fullHeight">
          <v-icon v-if="! fullHeight" color="accent">fas fa-expand-arrows-alt</v-icon>
          <v-icon v-else color="accent">fas fa-compress</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12">
      <h4 class="text-center"> {{ files[fileIndex].display_filename }} </h4>
    </v-col>
    <v-col cols="12" class="pt-0 text-center">
      <div style="border-radius: 30px; width:60px; display: inline-block;font-weight: bold" class="mx-2 px-2 grey lighten-1 text-center white--text">{{ fileIndex + 1 }} /  {{ files.length }}</div>
    </v-col>
  </v-row>
</template>

<style scoped>
  .browse-file-btn {
    position: absolute;
    height: 100%;
    width: 50px;
    top:0;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
  }
  .browse-file-btn.prev {
    background: linear-gradient(to right,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001) 100%);
    left:0;
  }
  .browse-file-btn.next {
    background: linear-gradient(to left,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001) 100%);
    right:0;
  }

  .slider-container {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgAQMAAACxAfVuAAAABlBMVEX////w8PBJB7MPAAAAdElEQVR4Xu3PMQ5EQACF4VciWyAK7UQkdPYKIxFRiRtwlrFRKBQuIHEScbDtX1Tq1/ztnw+fOry/FMTuKDoKjGdDR4HdzjKgwI1pE1GwZt5VUTAks2kpz6N+z31LwTKlP0N5J5JIIokkkkgiiSSSSCKJJPoDn958rmW4fOIAAAAASUVORK5CYII=);
    border: solid 2px #eee;
  }

  .image-container {
    overflow: hidden;
    transition: max-height .35s ease-in-out;
    line-height: auto;
    align-items: center;
    justify-content: center;
    display: flex
  }
</style>

<script>
  export default {
    props: {
      files: { type: Array, required: true },
      fullWidth: { type: Boolean, required: false, default: false },
    },
    data() {
      return {
        fileIndex: 0,
        fullHeight: false,
      };
    },
  };
</script>
