<template>
  <div>
    <h1 class="secondary--text text-center">{{ step.title }}</h1>
    <v-slide-y-transition>
      <div v-if="usersTopics.length" class="pa-10" style="height: 100%">
        <v-card class="grey lighten-3 elevation-0 pa-2">
          <v-card-text>
            <ul>
              <li v-for="topic in usersTopics" class="mb-4">
                <div>
                  <span style="font-size: 130%">{{ topic.title }}</span>
                  <span v-if="topic.duration" style="font-style: italic;">({{ topic.duration }} min)</span>
                  <span class="ml-3 mr-n2">proposé par {{ topic.user_name }}</span>
                  <avatar-tooltiped :user="topic.user" :size="avatarSize"></avatar-tooltiped>
                </div>
                <!-- LINKS -->
                <div v-if="topic.links && topic.links.length" class="ml-6">
                  <div v-for="(link, linkIndex) in topic.links" :key="linkIndex" class="link-item display-flex align-center">
                    <link-icon :link="link"></link-icon>
                    <a :href="link.url" :style="{ 'vertical-align': link.icon ? 'middle' : '' }" class="text-ellipsis" style="margin-left: 4px;" target="_blank" rel="noopener noreferrer">
                      {{ link.name || link.url }}
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </div>
      <div v-else class="pa-12" style="height: 100%">
        <h2>Aucun sujet à consulter</h2>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import LinkIcon from '@/components/Preparation/partials/LinkIcon';

  function setUsersTopics(modules, moduleName, users) {
    const topicModules = modules.filter(moduleItem => (moduleItem.module_name == moduleName));
    const topics = topicModules.reduce((prevMerge, moduleItem) => {
      const topicsItem = window.deepcopy((moduleItem.content || [])).map((topic) => {
        topic.user_id = moduleItem.user_id;
        const topicUser = (users.find(user => user.id == moduleItem.user_id) || {});
        topic.user = topicUser;
        return topic;
      });
      return prevMerge.concat(topicsItem);
    }, []);
    return topics;
  }

  export default {
    components: {
      LinkIcon,
    },
    props: {
      step: { required: true, type: Object },
    },
    data() {
      return {
        usersTopics: {},
        avatarSize: 30,
      };
    },
    computed: {
      ...mapState('current', ['meeting']),
    },
    watch: {
      step(step) {
        this.usersTopics = setUsersTopics(this.meeting.modules, step.module_name, this.meeting.users);
      },
    },
    created() {
      this.usersTopics = setUsersTopics(this.meeting.modules, this.step.module_name, this.meeting.users);
    },
  };
</script>
