export default {
  methods: {
    canIOpenUser(user) {
      const me = this.$store.state.users.user;
      if (user.id == me.id) return true;
      if (! this.userIsAdmin) return false;
      if (! me.organization) return true;

      let myOrganization;
      me.organization.forEachSubOrga(me.organization, (orga) => {
        if (orga.company_id == me.company_id) myOrganization = orga;
      });
      if (! myOrganization) return false;
      let isUserInSubCompany = false;
      me.organization.forEachSubOrga(myOrganization, (orga) => {
        if (orga && orga.company && orga.company.users && orga.company.users.filter(item => item.id == user.id).length) isUserInSubCompany = true;
      });
      return isUserInSubCompany;
    },
    canIEditUserRights(user) {
      if (['consult', 'user', 'company_admin'].indexOf(user.access_right) === -1) return false;
      return (this.userIsAdmin && this.canIOpenUser(user));
    },
  },
};
