<template>
  <v-autocomplete
    ref="selectuserinput" v-model="model" :clearable="clearable" :label="label" :items="userlist" :filter="filterNames"
    :autofocus="autofocus" :hide-details="hideDetails" color="primary darken-1"
    append-icon="fas fa-caret-down" v-on="inputListeners">
    <template slot="item" slot-scope="data">
      <v-list-item-avatar>
        <img v-if="data.item.avatar" :src="data.item.avatar">
        <v-icon v-else>fas fa-user</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ $filters.username(data.item) }}</v-list-item-title>
        <v-list-item-subtitle v-if="data.item.pending_user">
          <i>(invitation en cours)</i>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template slot="selection" slot-scope="data">{{ $filters.username(data.item) }}</template>
  </v-autocomplete>
</template>

<script>
  export default {
    props: {
      userlist: { type: Array, required: true },
      label: { type: String, required: true },
      modelId: { type: Number, required: false, default: null },
      autofocus: { required: false, type: Boolean, default: null },
      hideDetails: { required: false, type: Boolean, default: null },
      emptyAfterSelect: { required: false, type: Boolean, default: false },
      clearable: { required: false, type: Boolean, default: false },
    },
    data() {
      return {
        model: {},
      };
    },
    computed: {
      inputListeners() {
        const vm = this;
        return {
          ...this.$listeners,
          input(event) {
            vm.model = vm.emptyAfterSelect ? {} : vm.model;
            vm.$emit('input', event);
            vm.$refs.selectuserinput.blur();
          },
          'click:clear': function clearUser() {
            vm.model = null;
            vm.$emit('click:clear');
          },
        };
      },
    },
    watch: {
      modelId(newVal) {
        this.model = this.userlist.find(user => user.id == newVal);
      },
    },
    methods: {
      filterNames(item, queryText) {
        const hasValue = val => (val != null ? val : '');
        const text = hasValue(`${(item.firstname || "")} ${(item.lastname || "")}`);
        const query = hasValue(queryText).split(' ').filter(x => !! x).join(' ');
        return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
      },
    },
  };
</script>
