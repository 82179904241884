<template>
  <v-dialog :value="true" :max-width="600" light persistent @close="dismiss('cancel')">
    <v-card>
      <v-card-title v-if="title" class="text-h5">{{ $t(title) }}</v-card-title>
      <v-card-text v-if="body">
        <div v-html="$t(body)"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="buttons.cancel" color="secondary" outlined rounded @click="dismiss('cancel')">{{ $t(buttons.cancel) }}</v-btn>
        <v-btn v-if="buttons.ok" color="accent" rounded @click="close()">{{ $t(buttons.ok) }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      title: { type: String, default: "" },
      body: { type: String, default: "" },
      buttons: { type: Object, default: () => ({ ok: 'GLOBAL.OK' }) },
    },
    methods: {
      close() {
        this.$emit('close');
      },
      dismiss(reason) {
        this.$emit('dismiss', reason);
      },
    },
  };
</script>
