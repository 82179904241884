<template>
  <div v-if="meeting.users && meeting.users.length">
    <strong>Participants :</strong>
    <ul>
      <li v-for="user in meeting.users" :key="user.id">
        <span>{{ $filters.username(user) }} </span>
        <em v-if="user.attendance && matchAttendance[user.attendance]" style="color: rgb(136, 136, 136)">{{ matchAttendance[user.attendance] }}</em>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      meeting: { type: Object, required: true },
    },
    data() {
      return {
        matchAttendance: { no: "non présent", yes: "présent" },
      };
    },
  };
</script>
