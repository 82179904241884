import Vue from 'vue';

/* ********* */
/* MUTATIONS */
/* ********* */
function set(state, values) {
  Object.keys(values).forEach((prop) => {
    Vue.set(state, prop, values[prop]);
  });
}

function setBusinessConfig(state, values) {
  // if (! state.isBusiness) return;
  Object.keys(values).forEach((prop) => {
    Vue.set(state.businessConfig, prop, values[prop]);
  });
}

/* ******* */
/* GETTERS */
/* ******* */
function config(state) {
  // const { isBusiness } = state;
  // const businessConfig = isBusiness && state.businessConfig || {};
  const businessConfig = state.businessConfig || {};

  return {
    closureTemplates: businessConfig.meeting?.closureTemplates || [],
  };
}

/* ******* */
/* ACTIONS */
/* ******* */
function load({ rootState, commit }) {
  const { user } = rootState.users;
  const isPremium = user.company && user.company.isPremium;
  commit('set', {
    isPremium,
    businessConfig: user.company && user.company.business_config || {},
    isAdmin: ['superadmin', 'admin', 'company_admin'].indexOf(user.access_right) > -1,
    isOrganizer: isPremium && user.meeting_right == 'organizer',
    maxprojects: Math.max((isPremium ? user.company.maxprojects : user.maxprojects) || 0, 1),
    hasSNCFDashboard: user.company_id == 12 || user.access_right == 'superadmin', // Fix me to avoid using company_id code !
    hasMonitoringPlan: user.company && user.company.config && user.company.config.monitoringplan || user.access_right == 'superadmin' || user.access_right == 'admin',
    hasOrganization: user.hasOrganization,
    hasGroups: user.company_id == 12 || user.access_right == 'superadmin' || user.access_right == 'admin', // Fix me to avoid using company_id code !
    hasMeeting: user.company && user.company.hasMeeting || false,
    hasPlanning: user.company && user.company.hasPlanning || false,
    hasVM: user.company && user.company.hasVM || false,
    hasViews: user.company && user.company.config && user.company.config.views || user.access_right == 'superadmin' || user.access_right == 'admin',
  });
}

function updateBusinessConfig({ state, commit, rootState }, values) {
  commit('setBusinessConfig', values);
  const api = `companies/${rootState.users.user.company_id}`;
  const action = 'update';
  const data = { business_config: state.businessConfig };

  const request = window.apiSrv.call(api, action, data);
  commit('ui/saving/start', null, { root: true });
  return request.then((response) => {
    commit('ui/saving/success', null, { root: true });
    return response;
  }).catch((error) => {
    commit('ui/saving/error', { error, request: { api, action, data } }, { root: true });
    return error;
  });
}

export default {
  namespaced: true,
  state: {
  },
  getters: {
    config,
  },
  mutations: {
    set,
    setBusinessConfig,
  },
  actions: {
    load,
    updateBusinessConfig,
  },
};
