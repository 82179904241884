<template>
  <v-container class="px-0 py-8">
    <div v-if="meetings" class="display-flex flex-column">
      <b class="mb-6 primary--text text-uppercase">Consultez les Comptes Rendus de toutes les réunions terminées</b>

      <!-- CLOSED MEETINGS LIST -->
      <v-treeview v-if="treeviewStructure" :items="treeviewStructure" :active.sync="active"
                  shaped activatable active-class="primary--text" open-on-click open-all>
        <template #label="{ item }">
          <template v-if="item.date">
            <b>{{ item.name }}</b>
            <span><i>- {{ item.date }}</i></span>
          </template>
          <span v-else>{{ item.name }}</span>
        </template>
      </v-treeview>
      <div v-else>Aucune réunion terminée.</div>
    </div>
    <v-row v-else style="justify-content: center">
      <v-progress-circular size="70" class="ma-12" indeterminate color="primary"></v-progress-circular>
    </v-row>

    <!-- MODALS -->
    <minutes-dashboard-modal v-if="openModal" :open-modal="openModal" :meeting="openedMeeting"
                             @cancel="active = []; openedMeeting = null">
    </minutes-dashboard-modal>
  </v-container>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import Meeting from '@/models/Meeting';
  import MinutesDashboardModal from './MinutesDashboardModal';

  export default {
    components: {
      MinutesDashboardModal,
    },
    data() {
      return {
        meetings: null,
        nationals: [],
        defaultMeetingTitle: 'Réunion sans titre',
        active: [],
        openedMeeting: null,
      };
    },
    computed: {
      selectedMeeting() {
        const id = this.active[0];
        return id && this.meetings.find(meeting => meeting.id === id);
      },
      openModal() {
        return !! this.openedMeeting;
      },
      meetingsByStructureLevel() {
        const meetingsByStructureLevel = {};
        this.meetings?.forEach((meeting) => {
          const structureLevel = meeting.custom_fields?.structureLevel || '';
          if (! meetingsByStructureLevel[structureLevel]) meetingsByStructureLevel[structureLevel] = [];
          meetingsByStructureLevel[structureLevel].push(meeting);
        });

        Object.values(meetingsByStructureLevel).forEach((meetings) => {
          meetings.sort((a, b) => moment(b.date).diff(moment(a.date)));
        });

        return meetingsByStructureLevel;
      },
      treeviewStructure() {
        const structure = JSON.parse(JSON.stringify(clientConfig.minutesStructure || [{ id: '*', name: 'Toutes les réunions' }]));

        this.recursiveFillStructureWithMeetings(structure);

        if (! structure.some(item => item.children.length)) return null;

        return structure;
      },
    },
    watch: {
      selectedMeeting(newVal) {
        this.openedMeeting = newVal;
      },
    },
    created() {
      Meeting.getAllMinutes().then((result) => {
        this.meetings = result?.data?.minutes || [];
      }).catch((error) => {
        if (error.status == 401) {
          return;
        }
        const errorMessage = error && error.message || "Erreur : Votre liste de réunions est inaccessible.";
        this.$store.dispatch('openErrorModal', { message: errorMessage, canClose: false });
      });
    },
    methods: {
      recursiveFillStructureWithMeetings(structure) {
        structure.forEach((item) => {
          if (item.id === '*') {
            item.children = this.meetings.sort((a, b) => moment(b.date).diff(moment(a.date))).map(meeting => ({
              id: meeting.id,
              name: meeting.title || this.defaultMeetingTitle,
              date: this.displayedDate(meeting),
            }));
          } else {
            if (! item.children) item.children = [];
            this.recursiveFillStructureWithMeetings(item.children);
            item.children = item.children.filter(child => child.children.length);
            this.meetingsByStructureLevel[item.id]?.forEach((meeting) => {
              item.children.push({
                id: meeting.id,
                name: meeting.title || this.defaultMeetingTitle,
                date: this.displayedDate(meeting),
              });
            });
          }
        });
      },
      displayedDate(meeting) {
        const date = moment(meeting.date);
        return date.isValid() ? date.format('DD MMMM YYYY [à] HH:mm') : '';
      },
    },
  };
</script>
