<template>
  <v-row>
    <v-col lg="6" cols="12">
      <v-card class="py-12 px-4 white" style="height: 100%; display: flex; align-items: center;justify-content: center;">
        <img style="width: 100%; display: flex; max-width: 600px;" src="@/assets/images/onBoarding/modules.png">
      </v-card>
    </v-col>
    <v-col lg="6" cols="12">
      <div class="display-flex flex-column" style="height: 100%">
        <v-card class="py-4 px-8">
          <div class="onboarding-card-title">
            Comment concevoir son déroulé de réunion ?
          </div>
          <p>
            Le déroulé de la réunion est la liste des différentes étapes qui seront suivies durant les phases de Préparation et d'Animation.
          </p>
          <p>
            Chaque étape du déroulé est constituée d'un module à choisir dans la liste des modules Bubble Meeting disponibles.
            Le module choisi déterminera le format de l'animation de l'étape ainsi que la préparation demandée aux participants.
          </p>
          <div>
            Il est possible de personnaliser l'étape avec un titre et un temps d'Animation. A noter que pour certains modules, par exemple les tours de table, le temps est indiqué par participant.
          </div>
        </v-card>
        <v-card class="py-4 px-8 mt-3">
          <div class="onboarding-card-title">
            Comment bien choisir ses modules ?
          </div>
          <p>
            Chaque module est destiné à une utilisation bien spécifique durant l'Animation, et requiert ou non une Préparation des participants.
          </p>
          <p>
            Vous disposez donc d'un module de tour de table des actualités des participants, d'un module de revue des actions en cours, d'un module de proposition de sujets de discussions, etc...
            Nous ajoutons également de nouveaux modules au fur et à mesure des mises à jour.
          </p>
          <div>
            Chaque module est détaillé dans les pages suivantes, avec sa Préparation et son Animation.
          </div>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
      };
    },
  };
</script>
