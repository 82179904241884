<template>
  <v-slide-y-transition mode="out-in">
    <v-container class="px-0 py-8">
      <v-card class="pa-4 mb-6">
        <v-row>
          <v-col cols="12">
            <b class="primary--text text-uppercase">Toutes les Actions</b>
          </v-col>
          <v-col cols="12">
            <actions-list-filters @update-filter="filterAction = $event"></actions-list-filters>
          </v-col>
        </v-row>
      </v-card>
      <actions-columns :filter-action="filterAction">
      </actions-columns>
    </v-container>
  </v-slide-y-transition>
</template>

<script>
  import ActionsListFilters from './ActionsListFilters';
  import ActionsColumns from './ActionsColumns';

  export default {
    components: {
      ActionsListFilters,
      ActionsColumns,
    },
    data() {
      return {
        filterAction: (() => true),
      };
    },
    created() {
      this.$store.commit('current/resetMeeting'); // remove actions filter on meeting id
    },
  };
</script>
