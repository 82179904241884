<template>
  <v-container class="pa-0" style="position: relative">
    <v-row>
      <v-col cols="12">
        <v-card class="grey lighten-3 elevation-0 pa-2">
          <div v-for="issue in unsortedIssues" :key="issue.id" class="issueItem">
            <div style="position: relative" class="warning issue mx-6 my-4 py-1">
              <v-text-field v-model="issue.title" class="warning lighten-3 pa-0 mt-0 mr-2" hide-details @change="saveModule"></v-text-field>
              <v-btn small fab outlined color="error" style="position: absolute;right:-45px; top:-1px; width: 32px; height: 32px; font-size: 18px" @click="deleteIssue(issue)">
                <v-icon small>far fa-trash-alt</v-icon>
              </v-btn>
            </div>
          </div>
          <v-form @submit.prevent="createIssue">
            <v-text-field v-model="newIssueTitle" label="Nouveau point d'attention" color="primary darken-1"></v-text-field>
          </v-form>
          <v-btn color="accent" rounded @click="createIssue">Ajouter</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .issue:before {
    content: '';
    position: absolute;
    left: -1.3em;
    top: -0.1em;
    height: 105%;
    width: 3em;
    line-height: 2em;
    border: .3em solid #fff;
    border-radius: 50%;
    background: inherit;
    background-image: url("../../assets/images/issues/issue.png");
    background-size: 60% 60%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .issue {
    position: relative;
    display: block;
    padding: .25em .4em .4em 2em;
    margin: .7em 0 .7em .3em;
    color: #000;
    border-radius: 0 25px 25px 0;
    transition: .2s ease-in-out;
    min-height: 3em;
    line-height: 2.5em;
    cursor: pointer;
  }

  .issueItem .btn{
    display: none;
  }

  .issueItem:hover .btn{
    display: block;
  }
  .issueItem:before {
    left:0;
    right: 0px;
    height: 30px;
    position: absolute;
    content: ' ';
  }

  .issueItem{
    width: 95%;
  }
</style>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      moduleName: { required: true, type: String },
    },
    data() {
      return {
        module: {},
        newIssueTitle: null,
      };
    },
    computed: {
      ...mapState('current', ['meeting', 'meetingUser']),
      ...mapState('users', ['user']),
      unsortedIssues() {
        const allUnsortedIssues = this.$store.getters['issues/unsortedIssues'];
        if (this.$store.getters['current/isMeetingAdmin']) {
          return allUnsortedIssues;
        }
        return allUnsortedIssues.filter(issue => issue.user_id == this.meetingUser.id);
      },
    },
    watch: {
      moduleName() {
        this.setCurrentUserModule();
      },
    },
    created() {
      this.setCurrentUserModule();
    },
    methods: {
      createIssue() {
        if (! this.newIssueTitle) return;
        const issue = {
          title: this.newIssueTitle,
          id: window.uuid(),
          user_id: this.user.id,
        };
        this.$store.dispatch('issues/add', issue);
        this.newIssueTitle = null;
      },
      deleteIssue(issue) {
        this.$store.dispatch('issues/delete', issue);
      },
      saveModule() { this.meeting.sendModule(this.module); },
      setCurrentUserModule() {
        const module = this.meeting.getUserModule(this.moduleName, this.meetingUser, []);
        this.$store.state.issues.currentUserModule = module;
        if (! module.id) {
          this.meeting.sendModule(module, false, true).then((response) => {
            if (! response || ! response.data || ! response.data.meeting_module_user) return;
            module.id = response.data.meeting_module_user.id;
            if (! this.$store.state.issues.modulesContent[module.id]) {
              this.$set(this.$store.state.issues.modulesContent, module.id, module.content);
            }
          });
        }
      },
    },
  };
</script>
