<template>
  <div class="pa-4" style="border: 1px solid #ccc">
    <h3 class="mb-2">Validation du compte-rendu</h3>
    <template v-if="usersValidations.validatedBy.length">
      <h4>Validé par :</h4>
      <template v-for="user in usersValidations.validatedBy">
        <!-- Username -->
        <span class="mr-2" style="display: inline-flex; align-items: center;">
          <img :src="user.user_avatar" :alt="user.user_name" class="v-avatar ma-2" style="height: 25px; width: 25px;">
          {{ user.user_name }}
          <v-tooltip v-if="user.comment" bottom>
            <template #activator="{ on }">
              <v-icon class="ml-2" color="error" v-on="on">fas fa-comment-exclamation</v-icon>
            </template>
            <div class="pre">{{ user.comment }}</div>
          </v-tooltip>
        </span>
      </template>
    </template>
    <template v-if="usersValidations.pendingValidation.length">
      <h4>En attente de validation :</h4>
      <template v-for="user in usersValidations.pendingValidation">
        <!-- Username -->
        <span class="mr-2" style="display: inline-flex; align-items: center;">
          <img :src="user.user_avatar" :alt="user.user_name" class="v-avatar ma-2" style="height: 25px; width: 25px;">
          {{ user.user_name }}
          <v-tooltip v-if="user.comment" bottom>
            <template #activator="{ on }">
              <v-icon class="ml-2" color="error" v-on="on">fas fa-comment-exclamation</v-icon>
            </template>
            <div class="pre">{{ user.comment }}</div>
          </v-tooltip>
        </span>
      </template>
    </template>

    <div class="mt-4">
      <v-btn :color="! userModuleContent.validated ? 'accent' : ''" :outlined="userModuleContent.validated" rounded @click="validateMinutes()">
        {{ userModuleContent.validated ? 'Annuler la validation du compte rendu' : 'Valider le compte rendu' }}
      </v-btn>
      <v-btn v-if="! showComments" class="ml-2" outlined rounded @click="showComments = true">
        Apporter des commentaires
      </v-btn>
    </div>

    <div v-if="showComments" class="mt-4">
      <v-textarea v-model="userModuleContent.comment" label="Commentaires" color="primary darken-1" @change="saveComments"></v-textarea>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      moduleName: { required: true, type: String },
    },
    data() {
      return {
        showComments: false,
      };
    },
    computed: {
      userModule() {
        const userModule = this.meeting.getUserModule(this.moduleName, this.meetingUser, {});
        if (Array.isArray(userModule.content)) userModule.content = {};
        return userModule;
      },
      userModuleContent() {
        return this.userModule.content;
      },
      usersValidations() {
        const validatedBy = [];
        const pendingValidation = [];
        this.meeting.users.forEach((user) => {
          const userModule = this.meeting.getUserModule(this.moduleName, user, {});
          const userData = {
            user_id: user.id,
            user_avatar: user.avatar,
            user_name: this.$store.getters['users/getUsername'](user),
            comment: userModule.content?.comment,
          };
          if (userModule.content?.validated) {
            validatedBy.push(userData);
          } else {
            pendingValidation.push(userData);
          }
        });
        return {
          validatedBy,
          pendingValidation,
        };
      },
      ...mapState('current', ['meeting', 'meetingUser']),
    },
    created() {
      if (this.userModuleContent.comment) this.showComments = true;
    },
    methods: {
      validateMinutes() {
        this.$set(this.userModuleContent, 'validated', ! this.userModuleContent.validated);
        this.meeting.sendModule(this.userModule, true); // true to set validated_at
      },
      saveComments() {
        this.meeting.sendModule(this.userModule, true);
      },
    },
  };
</script>
