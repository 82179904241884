<template>
  <modal-with-background :is-open="openModal" @modal-close="$emit('cancel')">
    <v-tabs v-model="openedTabIndex" slider-color="primary" class="mt-2">
      <!-- REPORT -->
      <v-tab href="#report" class="px-2">Compte rendu</v-tab>
      <!-- LINKS -->
      <v-tab href="#links" class="px-2">Documents ({{ meeting.links && meeting.links.length || 0 }})</v-tab>
    </v-tabs>

    <v-tabs-items v-model="openedTabIndex">
      <!-- TAB ITEM REPORT -->
      <v-tab-item value="report" class="ma-4" style="min-height: 50vh">
        <meeting-details :from-meeting="meeting" content="summary"></meeting-details>
      </v-tab-item>
      <!--TAB ITEM LINKS -->
      <v-tab-item value="links" class="ma-4" style="min-height: 50vh">
        <template v-if="meeting.links && meeting.links.length">
          <div v-for="link in meeting.links" class="text-ellipsis" style="margin-bottom: 2px">
            <link-icon :link="link"></link-icon>
            <span :style="{ 'vertical-align': link.icon ? 'middle' : '' }">&nbsp;<a :href="link.url" target="_blank">{{ link.display_filename || link.url }}</a></span>
          </div>
        </template>
        <div v-else class="mt-2 grey--text">Aucun fichier</div>
      </v-tab-item>
    </v-tabs-items>
  </modal-with-background>
</template>

<script>
  import MeetingDetails from '@/components/MeetingDetails/MeetingDetails';
  import LinkIcon from '@/components/Preparation/partials/LinkIcon';

  export default {
    components: {
      MeetingDetails,
      LinkIcon,
    },
    props: {
      openModal: { type: Boolean, required: true },
      meeting: { type: Object, required: true },
    },
    data() {
      return {
        openedTabIndex: null,
      };
    },
  };
</script>
