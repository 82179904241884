<template>
  <div>
    <v-alert :value="!! showTrialAlert" :text="! trialExpiring" :color="trialExpiring ? 'error' : 'success'" tile transition="slide-y-transition" class="mt-1 mb-0 px-8">
      <div v-if="trialExpiring" class="white--text text-center">
        <div style="display: inline-block">
          <div class="display-flex align-center">
            <i class="fas fa-exclamation-triangle mr-3" style="font-size: 24px"></i>
            <span v-if="remainingDays > 1">Votre essai gratuit expire dans {{ remainingDays }} jours.</span>
            <span v-else-if="remainingDays == 1">Votre essai gratuit expire demain.</span>
            <span v-else-if="remainingDays == 0">Votre essai gratuit expire aujourd'hui.</span>
            <div v-else class="text-left">Votre essai gratuit a expiré.<br>Vous ne pouvez plus créer de nouvelle réunion.</div>
          </div>
          <v-btn color="success" rounded block class="text-uppercase mt-4 mb-2" @click="openPremiumModal = true">Souscrire une offre</v-btn>
        </div>
      </div>
      <div v-else class="display-flex align-center">
        <i class="fad fa-rocket-launch mr-4" style="font-size: 24px"></i>
        Vous êtes en essai gratuit pour {{ remainingDays + 1 }} jours
      </div>
    </v-alert>

    <v-alert :value="!! premiumExpireDate" color="error" tile transition="slide-y-transition" class="mt-1 mb-0 px-8">
      <div v-if="premiumExpireDate" class="white--text text-center">
        <div style="display: inline-block">
          <div class="display-flex align-center">
            <i class="fas fa-exclamation-triangle mr-3" style="font-size: 24px"></i>
            <div class="text-left">
              Votre abonnement est arrivé à expiration le {{ $filters.moment(premiumExpireDate, 'date') }}.<br>Vous ne pouvez plus créer de nouvelle réunion.
            </div>
          </div>
          <v-btn color="success" rounded block class="text-uppercase mt-4 mb-2" @click="openPremiumModal = true">Souscrire une offre</v-btn>
        </div>
      </div>
    </v-alert>

    <!--  MODALS -->
    <modal-with-background :is-open="!! showWelcomeStartTrial" :max-width="900" persistent no-close title="Bienvenue sur Bubble Meeting !" content-class="align-self-start">
      <div class="text-center">
        <img src="@/assets/images/schema-cycle-meeting.jpg" style="max-width:100%">
      </div>
      <template #footer>
        <v-btn :loading="startTrialLoading" color="success" rounded class="text-uppercase px-8 mx-auto my-2" @click="startTrial">
          Démarrez votre essai gratuit 30 jours
        </v-btn>
        <div v-if="startTrialErrorMessage" class="error--text">{{ startTrialErrorMessage }}</div>
      </template>
    </modal-with-background>

    <modal-with-background :is-open="openPremiumModal" :max-width="900" persistent title="Gérer votre abonnement Premium" content-class="align-self-start" @modal-close="openPremiumModal = false">
      <premium-window v-if="openPremiumModal" @close="openPremiumModal = false"></premium-window>
    </modal-with-background>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import PremiumWindow from './PremiumWindow';

  export default {
    components: {
      PremiumWindow,
    },
    data() {
      return {
        openPremiumModal: false,
        startTrialErrorMessage: "",
        startTrialLoading: false,
      };
    },
    computed: {
      showTrialAlert() {
        if (! this.trialEnd) return false;
        if (! this.maxOrganizers) return true;
        if (! this.accessRight.isPremium && this.remainingDays >= 0) return true; // weird case : expired Premium with ongoing trial
        return false;
      },
      showWelcomeStartTrial() {
        return this.user.id && ! this.maxOrganizers && ! this.trialEnd;
      },
      trialEnd() {
        const config = this.user.company && this.user.company.config;
        return config && config.meeting_trial_end;
      },
      remainingDays() { // full days
        let days = moment(this.trialEnd).diff(moment(), 'days');
        if (moment(this.trialEnd).isBefore()) days--;
        return days;
      },
      trialExpiring() {
        return this.remainingDays <= 7;
      },
      maxOrganizers() {
        return this.user.company && this.user.company.maxorganizers;
      },
      premiumExpireDate() {
        if (this.showTrialAlert || this.showWelcomeStartTrial) return null;
        return this.user.company && this.user.company.isPremiumExpired && this.user.company.premiumExpireDate;
      },
      ...mapState('users', ['user', 'accessRight']),
    },
    methods: {
      startTrial() {
        this.startTrialErrorMessage = "";
        this.startTrialLoading = true;
        window.apiSrv.call('meetings/starttrial', 'store').then(() => window.location.reload(true)).catch((error) => {
          this.startTrialErrorMessage = error;
          this.startTrialLoading = false;
        });
      },
    },
  };
</script>
