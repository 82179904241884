<template>
  <v-container class="pa-0">
    <actions-columns v-if="meeting.category" :category="meeting.category" :user-id="meetingUser.id"></actions-columns>
    <div v-else>
      <p class="primary white--text pa-1">
        <v-icon color="white" class="mx-1">fas fa-exclamation-triangle</v-icon>
        Aucune action trouvée car cette réunion ne fait partie d'aucune suite de réunions.<br>
        Pour créer de nouvelles actions attachées à cette réunion, utilisez le module « Points d'attention et prise de décision ».
      </p>
    </div>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';
  import ActionsColumns from '@/components/Dashboards/ActionsList/ActionsColumns';

  export default {
    components: {
      ActionsColumns,
    },
    props: {
      moduleName: { required: true, type: String },
    },
    data() {
      return {};
    },
    computed: {
      ...mapState('current', ['meeting', 'meetingUser']),
    },
    created() {
      this.meeting.getUserModule(this.moduleName, this.meetingUser, {});
    },
  };
</script>
