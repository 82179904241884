<template>
  <v-toolbar color="white" flat class="my-4">
    <span class="text-h6 mr-2">Tri :</span>
    <v-btn-toggle v-model="filters.sort" mandatory>
      <v-btn value="status" text title="Phase de réunion" height="36">
        <v-icon small>fas fa-tasks</v-icon>
      </v-btn>
      <v-btn value="-date" text title="Date" height="36">
        <v-icon small>far fa-calendar-alt</v-icon>
      </v-btn>
      <v-btn value="category" text title="Suite de réunions" height="36">
        <v-icon small>far fa-object-group</v-icon>
      </v-btn>
      <v-btn value="title" text title="Alphabétique" height="36">
        <v-icon small>fas fa-sort-alpha-down</v-icon>
      </v-btn>
      <v-btn value="-title" text title="Alphabétique inverse" height="36">
        <v-icon small>fas fa-sort-alpha-up</v-icon>
      </v-btn>
    </v-btn-toggle>
    <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
    <v-autocomplete
      v-model="filters.category" :items="meetingCategories" label="Suite de réunions" prepend-icon="fas fa-object-group"
      hide-details clearable style="max-width: 250px">
    </v-autocomplete>
    <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
    <v-select
      v-model="filters.status" :items="statusItems" label="Phase de réunion" prepend-icon="fas fa-circle-notch"
      hide-details clearable menu-props="offset-y" style="max-width: 250px">
    </v-select>
    <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
    <v-combobox
      :search-input.sync="filters.title" :items="meetingTitles"
      label="Objet de la réunion"
      prepend-icon="fas fa-search" hide-details clearable>
    </v-combobox>
  </v-toolbar>
</template>

<script>
  import Meeting from '@/models/Meeting';

  const defaultMeetingTitle = 'Réunion sans titre';

  export default {
    props: {
      filters: { type: Object, required: true },
      meetings: { type: Array, default: () => ([]) },
    },
    data() {
      return {
        statusItems: Meeting.getStatusItems(),
      };
    },
    computed: {
      meetingCategories() {
        const meetingCategories = this.meetings.reduce((acc, item) => {
          if (item.category) acc.push(item.category);
          return acc;
        }, []);
        return meetingCategories.sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
      },
      meetingTitles() {
        const meetingTitles = this.meetings.map(item => ({ value: item.title || '', text: item.title || defaultMeetingTitle }));
        return meetingTitles.sort((a, b) => (a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1));
      },
      filteredMeetings() {
        if (! this.meetings) return [];
        const meetings = this.meetings.filter((meeting) => {
          if (! this.filterMeetingByCategory(meeting)) return false;
          if (! this.filterMeetingByStatus(meeting)) return false;
          if (! this.filterMeetingByTitle(meeting)) return false;
          return true;
        });

        // sorting
        let param = this.filters.sort;
        let reverse = -1;
        if (param.startsWith('-')) {
          param = param.slice(1);
          reverse = 1;
        }
        if (param == 'status') {
          const listOrder = ['animation', 'preparation', 'minutes', 'draft', 'closed'];
          return meetings.slice().sort((meetingA, meetingB) => {
            const order = listOrder.indexOf(meetingA.status) - listOrder.indexOf(meetingB.status);
            if (order) return order;
            return moment(meetingA.date).isBefore(meetingB.date) ? 1 : -1;
          });
        }
        return meetings.slice().sort((meetingA, meetingB) => {
          const [paramA, paramB] = [meetingA, meetingB].map((item) => {
            let paramItem = item[param];
            if (param == 'title' && ! paramItem) paramItem = defaultMeetingTitle;
            return (typeof paramItem == 'string') ? paramItem.toLowerCase() : paramItem;
          });
          if (! paramB) return -1;
          if (! paramA) return 1;
          return paramA < paramB ? reverse : -reverse;
        });
      },
    },
    watch: {
      filteredMeetings: {
        handler(newVal) {
          this.$emit('update-filtered-meetings', newVal);
        },
        immediate: true,
      },
    },
    methods: {
      filterMeetingByCategory(meeting) {
        if (! this.filters.category) return true;
        return meeting.category == this.filters.category;
      },
      filterMeetingByStatus(meeting) {
        if (! this.filters.status) return true;
        return meeting.status == this.filters.status;
      },
      filterMeetingByTitle(meeting) {
        return (meeting.title || defaultMeetingTitle).toLowerCase().indexOf((this.filters.title || '').toLowerCase()) > -1;
      },
    },
  };
</script>
