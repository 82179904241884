<template>
  <div>
    <h3>
      <span>{{ step.title }} </span>
      <em v-if="step.time_spent" style="color: rgb(136, 136, 136);">{{ secToMin(step.time_spent) }}</em>
    </h3>
    <template v-for="(userTopics, index) in sortedModuleDatas">
      <template v-if="userTopics.content && userTopics.content.length">
        <!-- Avatar & Username -->
        <span style="diplay: flex; align-items: center">
          <img :src="userTopics.user_avatar" :alt="userTopics.user_name" class="v-avatar ma-2" style="height: 25px; width: 25px;">
          <strong>{{ userTopics.user_name }}</strong>
        </span>
        <!-- Topics -->
        <ul :key="index">
          <li v-for="topic in userTopics.content" :key="topic.id">
            <span>{{ topic.title }} </span>
          </li>
        </ul>
      </template>
    </template>
  </div>
</template>

<script>
  import meetingClosureModuleMixin from './meetingClosureModuleMixin';

  export default {
    mixins: [meetingClosureModuleMixin],
  };
</script>
