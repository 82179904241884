<template>
  <div v-if="isLoaded && (inProgressActions.length || completedActions.length)">
    <!-- ACTIONS IN PROGRESS -->
    <h3>{{ step.title }} </h3>
    <strong>Actions en cours</strong>
    <ul v-if="toDoActions.length">
      <li v-for="action in toDoActions" :key="action.id">
        <span>{{ action.title || 'Nouvelle action' }} </span>
        <img :src="user(action).user_avatar" :alt="user(action).user_name" class="v-avatar ma-2" style="height: 25px; width: 25px;">
      </li>
    </ul>

    <!-- LATE ACTIONS -->
    <strong>Actions en retard</strong>
    <ul v-if="lateActions.length">
      <li v-for="action in lateActions" :key="action.id">
        <span>{{ action.title || 'Nouvelle action' }} </span>
        <em :class="isLate(action) ? 'error--text' : 'secondary--text'">{{ dueDate(action.due_date) }}</em>
        <img :src="user(action).user_avatar" :alt="user(action).user_name" class="v-avatar ma-2" style="height: 25px; width: 25px;">
      </li>
    </ul>

    <!-- ACTIONS COMPLETED -->
    <strong>Actions complétées</strong>
    <ul v-if="completedActions.length">
      <li v-for="action in completedActions" :key="action.id">
        <span>{{ action.title || 'Nouvelle action' }} </span>
        <em style="color: rgb(136, 136, 136)">{{ completedDate(action.completed_at) }}</em>
        <img :src="user(action).user_avatar" :alt="user(action).user_name" class="v-avatar ma-2" style="height: 25px; width: 25px;">
      </li>
    </ul>
  </div>
</template>

<script>
  import meetingClosureModuleMixin from './meetingClosureModuleMixin';

  export default {
    mixins: [meetingClosureModuleMixin],
    data() {
      return {
        isLoaded: false,
      };
    },
    computed: {
      inProgressActions() {
        return this.allActions.filter(action => ! action.completed_at);
      },
      completedActions() {
        // /!\ WARNING : only takes recently completed actions... Why ??
        const prevMonth = moment().add(-1, 'month');
        return this.allActions.filter(action => action.completed_at && moment(action.completed_at).format() > prevMonth.format());
      },
      toDoActions() {
        return this.inProgressActions.filter(action => moment(action.due_date).format() > moment().format())
          .sort((itemA, itemB) => moment(itemA.due_date).format() > moment(itemB.due_date).format());
      },
      lateActions() {
        return this.inProgressActions.filter(action => moment(action.due_date).format() < moment().format())
          .sort((itemA, itemB) => moment(itemA.due_date).format() > moment(itemB.due_date).format());
      },
      allActions() {
        if (this.meeting.category) {
          return this.$store.getters['actions/getByCategory'](this.meeting.category);
        }
        return this.$store.getters['actions/getByMeeting'](this.meeting.id);
      },
    },
    created() {
      this.$store.dispatch('actions/loadAll').then(() => {
        this.isLoaded = true;
      });
    },
    methods: {
      user(action) {
        const user = this.meeting.users.find(item => item.id == action.user_id);
        return {
          user_name: user ? `par ${this.$store.getters['users/getUsername'](user)}` : '',
          user_avatar: user ? user.avatar : "https://secure.gravatar.com/avatar/0?d=mm",
        };
      },
      isLate(action) {
        return ! action.completed_at && moment(action.due_date).isBefore();
      },
      dueDate(date) {
        return date ? `pour le ${moment(date).format('ll')}` : '';
      },
      completedDate(date) {
        return date ? `finie le ${moment(date).format('ll')}` : '';
      },
    },
  };
</script>
