<template>
  <div v-if="isAdmin" class="mx-3">
    <b class="primary--text text-uppercase">MODÈLES DE COMPTE RENDU (.docx)</b>
    <v-card class="grey lighten-3 elevation-0 pa-2 ml-6 mt-5" style="max-width: 580px">
      <v-card-text>
        <!-- SAMPLE TEMPLATE -->
        <div class="my-1">
          <span class="link-icon far fa-file-word"></span>
          <a :href="wordDefaultTemplate.url" class="secondary--text" download>{{ wordDefaultTemplate.name }}</a>
        </div>

        <!-- CUSTOM TEMPLATES LIST -->
        <template v-if="closureTemplates.length">
          <v-divider class="my-2"></v-divider>
          <div v-for="(file, fileIndex) in closureTemplates" :key="file.id" class="link-item display-flex align-center mb-2">
            <link-icon :link="file"></link-icon>
            <a :href="file.url" :style="{ 'vertical-align': file.icon ? 'middle' : '' }" class="text-ellipsis" style="margin-left: 4px;" target="_blank" rel="noopener noreferrer">
              {{ file.name || file.url }}
            </a>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn icon small class="link-menu ml-4" v-on="on" @click="renameFile(file, fileIndex)">
                  <v-icon small>fas fa-edit</v-icon>
                </v-btn>
              </template>
              <span>Renommer</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn icon small class="link-menu ml-2" v-on="on">
                  <file-upload :input-id="`file-closure-templates-${fileIndex}`" :multiple="false" word-only @uploaded="updateFile(file, $event)">
                    <template #content>
                      <v-icon small>fas fa-upload</v-icon>
                    </template>
                  </file-upload>
                </v-btn>
              </template>
              <span>Mettre à jour le modèle</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn icon small class="link-menu ml-2" color="error" v-on="on" @click="removeFile(file, fileIndex)">
                  <v-icon small>fas fa-times</v-icon>
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
          </div>
        </template>

        <v-divider class="mt-2 mb-3"></v-divider>
        <!-- FILE DROP ZONE-->
        <div class="display-flex align-center">
          <file-upload input-id="file-closure-templates" word-only @uploaded="fileUploaded">
            Ajouter un modèle de compte rendu
          </file-upload>
        </div>
      </v-card-text>
    </v-card>
  </div>
  <v-alert v-else color="primary" type="warning" class="mt-4">
    {{ $t('PREMIUM.ONLY_FOR_ADMIN') }} &nbsp;
  </v-alert>
</template>

<style lang="scss">
  .link-menu {
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s;
  }
  .link-item:hover .link-menu {
    visibility: visible;
    opacity: 1;
  }
</style>

<script>
  import defaultConfig from '@/js/defaultConfig';
  import FileUpload from '@/components/Reusables/FileUpload';
  import LinkIcon from '@/components/Preparation/partials/LinkIcon';

  export default {
    components: {
      FileUpload,
      LinkIcon,
    },
    data() {
      return {
        wordDefaultTemplate: defaultConfig.wordDefaultTemplate(),
        closureTemplates: this.$store.getters['users/accessRight/config'].closureTemplates,
      };
    },
    computed: {
      isAdmin() { return this.$store.state.users.accessRight.isAdmin; },
    },
    methods: {
      fileUploaded(newFile) {
        if (! (newFile && newFile.response && newFile.response.file)) return;
        const { file } = newFile.response;
        this.closureTemplates.push({ id: file.id, type: 'file', url: file.url, name: file.display_filename, mime: file.mime });
        this.saveTemplates();
      },
      updateFile(fileToUpdate, newFile) {
        if (! newFile?.response?.file) return;
        const { file } = newFile.response;
        const newFileData = { ...fileToUpdate, id: file.id, url: file.url };
        const fileToUpdateIndex = this.closureTemplates.findIndex(item => item.id == fileToUpdate.id);
        if (fileToUpdateIndex > -1) {
          this.closureTemplates.splice(fileToUpdateIndex, 1, newFileData);
          this.saveTemplates();
          this.deleteFilefromDB(fileToUpdate.id);
        }
        this.fileToUpdate = null;
      },
      renameFile(file, fileIndex) {
        const newName = prompt(this.$t("MANAGE.RENAME_FILE"), file.name);
        if (newName) {
          file.name = newName;
          if (fileIndex > -1) {
            this.closureTemplates.splice(fileIndex, 1, file);
            this.saveTemplates();
          }
        }
      },
      removeFile(file, fileIndex) {
        if (fileIndex > -1) {
          this.closureTemplates.splice(fileIndex, 1);
          this.saveTemplates();
          this.deleteFilefromDB(file.id);
        }
      },
      deleteFilefromDB(fileId) {
        return window.apiSrv.call('files', 'destroy', fileId);
      },
      saveTemplates() {
        this.setParam('meeting', { closureTemplates: this.closureTemplates });
      },
      setParam(prop, val) {
        this.$store.dispatch('users/accessRight/updateBusinessConfig', { [prop]: val });
      },
    },
  };
</script>
