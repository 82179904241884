<template>
  <div>
    <div v-if="isLoaded && isMeetingAdmin && ! userId">
      <v-btn rounded color="accent" @click="createAction"><v-icon dark small class="mr-4">fa fa-plus</v-icon>Ajouter une action</v-btn>
    </div>
    <div v-if="isLoaded && (inProgressActions.length || completedActions.length)">
      <v-row>
        <v-col sm="4">
          <v-card class="grey lighten-4 pa-4 mb-6" style="height: 100%">
            <v-list two-line class="grey lighten-4">
              <h3 class="text-center">Actions en cours</h3>
              <actions-columns-item
                v-for="action in toDoActions" :key="action.id" :action="action"
                @click-modal="openedAction = action" @click-complete="completeAction(action)">
              </actions-columns-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col sm="4">
          <v-card class="grey lighten-4 pa-4 mb-6" style="height: 100%">
            <v-list two-line class="grey lighten-4">
              <h3 class="text-center">Actions en retard</h3>
              <actions-columns-item
                v-for="action in lateActions" :key="action.id" :action="action"
                @click-modal="openedAction = action" @click-complete="completeAction(action)">
              </actions-columns-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col sm="4">
          <v-card class="grey lighten-4 pointer pa-4 mb-6" style="height: 100%">
            <v-list two-line class="grey lighten-4">
              <h3 class="text-center">Actions complétées</h3>
              <actions-columns-item
                v-for="action in completedActions" :key="action.id" :action="action"
                @click-modal="openedAction = action" @click-uncomplete="uncompleteAction(action)">
              </actions-columns-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="! inProgressActions.length && ! completedActions.length && isLoaded" class="py-12">
      <h2>Aucune action des précédentes réunions à consulter.</h2>
    </div>
    <div v-else class="ma-12 text-center">
      <v-progress-circular :size="70" indeterminate color="primary"></v-progress-circular>
    </div>
    <open-action
      :open-modal="openModal" :updating-action="openedAction || {}"
      @cancel="openedAction = null" @save-action="changeAction" @delete-action="deleteAction">
    </open-action>
    <v-snackbar v-model="hasSaved" :timeout="6000" persistent bottom class="text-center">
      Les modifications des actions ont bien été envoyées.
      <v-btn text @click.native="isOpen = false">Fermer</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import OpenAction from '@/components/Animation/AnimationIssues/OpenAction';
  import ActionsColumnsItem from './ActionsColumnsItem';

  export default {
    components: {
      OpenAction,
      ActionsColumnsItem,
    },
    props: {
      category: { required: false, type: [Number, String], default: null },
      userId: { required: false, type: [Number, String], default: null },
      filterAction: { required: false, type: Function, default: (() => true) },
    },
    data() {
      return {
        openedAction: null,
        isLoaded: false,
        hasSaved: false,
      };
    },
    computed: {
      openModal() {
        return !! this.openedAction;
      },
      inProgressActions() {
        return this.allActions.filter(action => ! action.completed_at && this.filterAction(action));
      },
      completedActions() {
        // /!\ WARNING : only takes recently completed actions... Why ??
        const prevMonth = moment().add(-1, 'month');
        return this.allActions.filter(action => action.completed_at && moment(action.completed_at).format() > prevMonth.format() && this.filterAction(action));
      },
      lateActions() {
        return this.inProgressActions.filter(action => moment(action.due_date).format() < moment().format())
          .sort((itemA, itemB) => moment(itemA.due_date).format() > moment(itemB.due_date).format());
      },
      toDoActions() {
        return this.inProgressActions.filter(action => moment(action.due_date).format() > moment().format())
          .sort((itemA, itemB) => moment(itemA.due_date).format() > moment(itemB.due_date).format());
      },
      allActions() {
        if (this.meeting.category) {
          return this.$store.getters['actions/getByCategory'](this.meeting.category, this.userId);
        }
        return this.$store.getters['actions/getByMeeting'](this.meeting.id);
      },
      ...mapState('current', ['meeting']),
      ...mapGetters('current', ['isMeetingAdmin']),
    },
    created() {
      this.$store.dispatch('actions/loadAll').then(() => {
        this.isLoaded = true;
      });
    },
    methods: {
      createAction() {
        if (! this.isMeetingAdmin) return;
        const newAction = {
          new: true,
          title: "",
          due_date: null,
          meeting_id: this.meeting.id,
        };
        this.openedAction = newAction;
      },
      changeAction(action) {
        this.openedAction = null;
        const storeAction = action.new ? 'actions/create' : 'actions/update';
        return this.$store.dispatch(storeAction, action).catch((error) => {
          this.$store.dispatch('openErrorModal', `L'action "${action.title}" n'a pas pu être ${action.new ? 'créée' : 'mise à jour'} : ${error.message}`);
          return Promise.reject(error);
        });
      },
      deleteAction(action) {
        this.openedAction = null;
        return this.$store.dispatch('actions/delete', action).catch((error) => {
          this.$store.dispatch('openErrorModal', `L'action "${action.title}" n'a pas pu être supprimée : ${error.message}`);
          return Promise.reject(error);
        });
      },
      completeAction(action) {
        action.completed_at = moment().format();
        return this.changeAction(action);
      },
      uncompleteAction(action) {
        action.completed_at = null;
        return this.changeAction(action);
      },
    },
  };
</script>
