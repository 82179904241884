<template>
  <div :class="{ 'grey lighten-1 decision': isDecision, warning: ! isDecision, 'movable-issue': isAdmin }" class="issue">
    <slot></slot>
    <div v-if="isInHistoricFridge" class="ice"></div>
  </div>
</template>

<style>
  .issue:before {
    content: '';
    position: absolute;
    left: -1.6em;
    top: -3%;
    height: 106%;
    width: 3.2em;
    border: .3em solid #fff;
    border-radius: 50%;
    background: inherit;
    background-image: url("../../../assets/images/issues/issue.png");
    background-size: 60% auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .issue.decision:before {
    background-image: url("../../../assets/images/issues/decision.jpg");
    background-size: cover;
    border: none;
  }

  .issue {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px .4em 4px 2em;
    margin: 16px 24px;
    color: #000;
    border-radius: 0 25px 25px 0;
    transition: .2s ease-in-out;
    min-height: 3em;
  }

  .issue .ice {
    background-image: url("../../../assets/images/issues/icecube.png");
    background-size: 200px;
    width: 92%;
    height: 1.5em;
    position: absolute;
    bottom: 0;
    background-repeat: repeat-x;
  }

  .movable-issue {
    cursor: pointer;
  }

  .movable-issue:hover {
    transform: scale(1.1, 1.1);
    box-shadow: 5px 5px 10px #888888;
  }
</style>

<script>
  export default {
    props: {
      isAdmin: { type: Boolean, default: false },
      isDecision: { type: Boolean, default: false },
      isInHistoricFridge: { type: Boolean, default: false },
    },
  };
</script>
