<template>
  <div v-if="meetingActions.length">
    <strong>Actions décidées :</strong>
    <ul data-checked="false">
      <li v-for="action in meetingActions" :key="action.id">
        <span>{{ action.title || 'Nouvelle action' }} </span>
        <em style="color: rgb(136, 136, 136)">{{ username(action.user_id) }} {{ dueDate(action.due_date) }}</em>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      meeting: { type: Object, required: true },
    },
    computed: {
      meetingActions() { return this.$store.getters['actions/getByMeeting'](this.meeting.id) || []; },
    },
    methods: {
      username(userId) {
        const userObj = this.meeting.users.find(user => user.id == userId);
        const username = userObj ? `par ${this.$store.getters['users/getUsername'](userObj)}` : '';
        return username;
      },
      dueDate(date) {
        return date ? `pour le ${moment(date).format('ll')}` : '';
      },
    },
  };
</script>
