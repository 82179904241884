<template>
  <v-container class="pb-12">
    <div v-if="meetings" class="display-flex flex-column">
      <template v-if="meetings.length > 2">
        <list-filters
          ref="filters" :meetings="meetings" :filters="filters"
          @update-filtered-meetings="listFiltersResultMeetings = $event">
        </list-filters>
        <v-divider class="my-4"></v-divider>
      </template>
      <v-container class="pa-0 mt-n2 mb-3" style="max-width: calc(100% - 36px)"> <!-- only way to add margin around pagination ? -->
        <v-pagination v-if="filteredMeetings.length > meetingsPerPage" v-model="meetingsCurrentPage" :length="meetingsPaginationLength" circle></v-pagination>
      </v-container>
      <v-fade-transition v-if="isListLoaded" group leave-absolute tag="div" class="row" style="min-height: 350px">
        <v-col key="newMeeting" cols="12" md="6" lg="4" @click="canCreateMeeting && ! isMeetingLoading && newMeeting()">
          <v-card :class="{ pointer: canCreateMeeting }" class="display-flex justify-center align-center pa-4" style="height: 100%">
            <div class="text-center">
              <div v-if="! isMeetingLoading">
                <template v-if="canCreateMeeting">
                  <v-icon size="50" color="accent">fas fa-plus</v-icon>
                  <div class="accent--text text-h6 mt-4">Nouvel espace de réunion</div>
                </template>
                <template v-else>
                  <v-icon size="50" color="grey">fas fa-plus</v-icon>
                  <div class="grey--text text-h6 mt-4">Nouvel espace de réunion</div>
                  <div class="grey--text mt-4 text-caption px-12">Vous ne disposez pas des droits d'organisateur pour créer une nouvelle réunion.</div>
                </template>
              </div>
              <div v-else>
                <v-progress-circular size="70" class="ma-12" indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col v-for="meeting in displayedFilteredMeetings" :key="meeting.id" cols="12" md="6" lg="4">
          <router-link :to="{ name: 'Home', params: { meetingId: meeting.id } }" :event="isMeetingLoading ? '' : 'click'" style="color: inherit; text-decoration: none;">
            <v-card style="height: 100%;">
              <div class="display-flex align-start overflow-hidden">
                <meeting-details :from-meeting="meeting" :corner-size="72" content="categoryCorner"></meeting-details>
                <div class="mt-6 pr-4" style="flex: 1 1 auto;">
                  <meeting-details :from-meeting="meeting" content="title"></meeting-details>
                </div>
                <v-menu v-if="canCreateMeeting" offset-y left>
                  <template #activator="{ on }">
                    <v-btn icon small outlined color="primary" class="meetingmenu" v-on="on" @click.prevent>
                      <v-icon small>fas fa-bars</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="! isMeetingLoading && duplicateMeeting(meeting.id)">
                      <span class="accent--text"><i class="fas fa-plus" style="width: 24px"></i>Réunion suivante</span>
                    </v-list-item>
                    <v-list-item @click="deletingMeeting = meeting">
                      <span class="error--text"><i class="fas fa-trash-alt" style="width: 24px"></i>Supprimer</span>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div class="px-6 py-2" style="font-size: 0.875rem">
                <div class="py-1">
                  <v-divider color="grey" style="margin-left: 32px"></v-divider>
                </div>
                <div class="py-1 display-flex">
                  <div style="width: 32px; flex: 0 0 auto"><v-icon small>fa-calendar-alt</v-icon></div>
                  <meeting-details :from-meeting="meeting" content="date" style="flex: 1 1 100%"></meeting-details>
                </div>
                <div class="py-1 display-flex">
                  <div style="width: 32px; flex: 0 0 auto">
                    <v-icon small>fas {{ (meeting.location_address || '').startsWith('http') ? 'fa-webcam' : 'fa-map-marker-alt' }}</v-icon>
                  </div>
                  <meeting-details :from-meeting="meeting" content="place" style="flex: 1 1 100%; white-space: normal"></meeting-details>
                </div>
                <div class="py-1">
                  <v-divider color="grey" style="margin-left: 32px"></v-divider>
                </div>
                <div class="py-1 display-flex">
                  <div style="width: 32px; flex: 0 0 auto"><v-icon small>fa-users</v-icon></div>
                  <meeting-details
                    :from-meeting="meeting" content="attendees"
                    class="mr-n4" style="flex: 1 1 100%; max-height: 150px; overflow-y: auto">
                  </meeting-details>
                </div>
                <div class="py-1">
                  <v-divider color="grey" style="margin-left: 32px"></v-divider>
                </div>
                <div class="py-1 display-flex">
                  <div style="width: 32px; flex: 0 0 auto"><v-icon small>far fa-list-alt</v-icon></div>
                  <meeting-details
                    :from-meeting="meeting" content="description"
                    class="mr-n4" style="flex: 1 1 100%; max-height: 100px; overflow-y: auto">
                  </meeting-details>
                </div>
              </div>
            </v-card>
          </router-link>
        </v-col>
      </v-fade-transition>
    </div>
    <v-row v-else style="justify-content: center">
      <v-progress-circular size="70" class="ma-12" indeterminate color="primary"></v-progress-circular>
    </v-row>
    <v-snackbar v-model="isDeleting" :timeout="-1" bottom>
      <b>Supprimer la réunion {{ deletingMeeting && deletingMeeting.title }} ?<br>Cette action ne peut pas être annulée.</b>
      <v-btn class="error--text" text @click.native="deleteMeeting()">Supprimer</v-btn>
      <v-btn text @click.native="isDeleting = false">Annuler</v-btn>
    </v-snackbar>
  </v-container>
</template>

<style>
  .meetingmenu {
    position:absolute;
    top: 8px;
    right: 8px;
  }
</style>

<script>
  import { mapState } from 'vuex';
  import Meeting from '@/models/Meeting';
  import StepsSrv from '@/services/StepsSrv';
  import MeetingDetails from '@/components/MeetingDetails/MeetingDetails';
  import ListFilters from './ListFilters.vue';

  export default {
    components: {
      MeetingDetails,
      ListFilters,
    },
    data() {
      const filters = {
        category: '', status: '', title: '', sort: 'status',
      };
      _.extend(filters, window.safeParseJSON(window.sessionStorageWrapper.getItem('option.meetingListFilters')) || null);

      return {
        meetings: null,
        listFiltersResultMeetings: null,
        filters,
        meetingsPerPage: 20,
        meetingsCurrentPage: 1,
        isListLoaded: false,
        isMeetingLoading: false,
        deletingMeeting: null,
      };
    },
    computed: {
      filteredMeetings() {
        return this.listFiltersResultMeetings || this.meetings || [];
      },
      displayedFilteredMeetings() {
        return this.filteredMeetings.slice(this.meetingsPerPage * (this.meetingsCurrentPage - 1), this.meetingsPerPage * this.meetingsCurrentPage);
      },
      meetingsPaginationLength() {
        return Math.ceil(this.filteredMeetings.length / this.meetingsPerPage);
      },
      isDeleting: {
        get() {
          return !! this.deletingMeeting;
        },
        set(val) {
          if (val == false) this.deletingMeeting = null;
        },
      },
      canCreateMeeting() {
        if (this.accessRight.isOrganizer && this.accessRight.isPremium) return true;
        const trialEnd = this.user.company && this.user.company.config && this.user.company.config.meeting_trial_end;
        return trialEnd && moment().isBefore(trialEnd);
      },
      ...mapState('users', ['user', 'accessRight']),
    },
    watch: {
      filters: {
        handler() {
          if (this.filters) window.sessionStorageWrapper.setItem('option.meetingListFilters', JSON.stringify(this.filters));
        },
        deep: true,
      },
      meetingsPaginationLength() {
        if (this.meetingsCurrentPage > this.meetingsPaginationLength) this.meetingsCurrentPage = 1;
      },
    },
    created() {
      Meeting.getList().then((result) => {
        this.meetings = result?.data?.meetings || [];
        if (this.meetings.length == 2) this.meetings.sort((a, b) => (a.date > b.date ? -1 : 1)); // list-filters not displayed
        setTimeout(() => { this.isListLoaded = true; }); // wait for list-filters computation
      }).catch((error) => {
        if (error.status == 401) {
          return;
        }
        const errorMessage = error && error.message || "Erreur : Votre liste de réunions est inaccessible.";
        this.$store.dispatch('openErrorModal', { message: errorMessage, canClose: false });
      });
    },
    methods: {
      createMeetingAndGo(newMeeting) {
        return newMeeting.create(true).then((response) => {
          if (! response || ! response.data || ! response.data.meeting) throw new Error("Une erreur a eu lieu pendant la création de la réunion.");
          this.$router.push({ name: 'Settings', params: { meetingId: response.data.meeting.id } });
          this.isMeetingLoading = false;
        });
      },
      newMeeting() {
        this.isMeetingLoading = true;
        this.$store.state.users.userPromise.then((currentUser) => {
          const userMeeting = {
            id: currentUser.id,
            firstname: currentUser.firstname,
            lastname: currentUser.lastname,
            avatar: currentUser.avatar,
            expected_attendance: 'required',
            attendance: 'yes',
            access_right: 'admin',
          };
          const roundDate = moment().seconds(0).minutes(Math.ceil(moment().minutes() / 30) * 30);
          const newMeeting = new Meeting({
            date: roundDate.format(),
            duration: 3600,
            category: "réunion d'équipe",
            privacy: 'private',
            users: [userMeeting],
            status: 'draft',
            steps: StepsSrv.getTemplateSteps(StepsSrv.operationsTemplate),
          });
          newMeeting.description = StepsSrv.stepsToDescription(newMeeting.steps);
          return this.createMeetingAndGo(newMeeting);
        }).catch((error) => {
          this.$store.dispatch('openErrorModal', error.message);
          this.isMeetingLoading = false;
        });
      },
      duplicateMeeting(meetingId) {
        this.isMeetingLoading = true;
        Meeting.load(meetingId).then((response) => {
          const originalMeetingData = response && response.data && response.data.meeting;
          if (! originalMeetingData) throw new Error('Unable to load original meeting');
          const newMeeting = new Meeting(_.pick(originalMeetingData, ['title', 'duration', 'location', 'location_address', 'category', 'privacy', 'description']));
          newMeeting.users = (originalMeetingData.users || []).map(user => _.pick(user, ['id', 'expected_attendance', 'access_right']));
          newMeeting.steps = (originalMeetingData.steps || []).map(user => _.omit(user, ['status', 'time_spent', 'users_time']));
          let date;
          if (originalMeetingData.date) {
            date = moment(originalMeetingData.date).add(Math.ceil(moment().diff(originalMeetingData.date, 'weeks', true)), 'weeks').format();
          } else {
            date = moment().seconds(0).minutes(Math.ceil(moment().minutes() / 30) * 30).format();
          }
          _.extend(newMeeting, {
            date,
            status: 'draft',
          });
          return this.createMeetingAndGo(newMeeting);
        }).catch((error) => {
          this.$store.dispatch('openErrorModal', error.message);
          this.isMeetingLoading = false;
        });
      },
      deleteMeeting() {
        if (this.isMeetingLoading || ! this.deletingMeeting) return;
        const meeting = new Meeting(this.deletingMeeting);
        this.isDeleting = false;
        this.isMeetingLoading = true;
        meeting.delete(true).then(() => {
          const meetingIndex = this.meetings.findIndex(item => item.id == meeting.id);
          this.meetings.splice(meetingIndex, 1);
          this.isMeetingLoading = false;
        }).catch((error) => {
          this.$store.dispatch('openErrorModal', error.message);
          this.isMeetingLoading = false;
        });
      },
    },
  };
</script>
