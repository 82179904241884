import 'core-js/stable';
import 'regenerator-runtime/runtime';

import '@/libs/global-lodash.js';
import '@/libs/global-moment.js';

import * as Sentry from '@sentry/browser';
import Vue from 'vue';
import store from '@/store';
import vuetify from '@/vuetify';
import router from '@/router';
import '@/libs/fontawesome-pro-5.15.1-web/css/all.css';
import App from '@/App.vue';

import '@/assets/css/app.css';
import '@/assets/css/meteo.css';
import '@/js/commonfunctions.js';
import '@/momentconfig.js';
import '@/directives.js';
import '@/vuecomponents.js';
import '@/services/apiSrv.js';

Vue.prototype.$filters = require('@/filters.js').default;

moment.locale('fr');

/* SENTRY */
if (window.location.hostname != 'localhost') {
  Sentry.init({
    dsn: 'https://2c9486a55e364608b74df88e4fcf6f0b@sentry.io/1305978',
    integrations: [new Sentry.Integrations.Vue({ Vue })],
  });
}

const app = new Vue({
  el: '#app',
  vuetify,
  store,
  router,
  i18n: store.state.lang.i18n,
  components: { App },
  data() {
    return {
    };
  },
  template: '<App/>',
});

window.app = app;
