<template>
  <div v-if="meeting.steps && meeting.steps.length">
    <strong>Déroulé de la réunion :</strong>
    <ul>
      <li v-for="step in meeting.steps" :key="step.id">
        <span>{{ step.title }} </span>
        <em v-if="stepTotalTime" style="color: rgb(136, 136, 136)">{{ stepTotalTime(step) }}</em>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      meeting: { type: Object, required: true },
    },
    methods: {
      stepTotalTime(step) {
        if (! step.timePerAttendee) return window.secToMin(step.time_spent);
        const totalTime = Object.values(step.users_time || {}).reduce((acc, item) => acc + (item || 0), 0);
        return window.secToMin(totalTime);
      },
    },
  };
</script>
