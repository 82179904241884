<template>
  <modal-with-background :is-open="isOpen" title="Historique des Arbitrages" persistent @modal-close="$emit('modal-close')">
    <div v-for="(item, index) in historicDecisions" :key="index" class="pa-2 pointer my-2" @click="switchExpandHistory(index)">
      <div class="text-h6">
        <i :class="expandHistory[index] ? 'fa-chevron-down' : 'fa-chevron-right'" class="fa mr-2"></i>
        {{ item.decision }}
      </div>
      <v-scroll-y-transition hide-on-leave>
        <div v-show="expandHistory[index]" class="text-subtitle-1 secondary--text text--lighten-3">en réponse au point d'attention : {{ item.title || "Aucun" }}</div>
      </v-scroll-y-transition>
    </div>
  </modal-with-background>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      isOpen: { required: true, type: Boolean },
    },
    data() {
      return {
        expandHistory: {},
      };
    },
    computed: {
      ...mapState('issues', ['historicDecisions']),
    },
    methods: {
      switchExpandHistory(index) {
        if (! this.expandHistory[index]) {
          this.$set(this.expandHistory, index, true);
        } else {
          this.expandHistory[index] = false;
        }
      },
    },
  };
</script>
