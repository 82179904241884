<template>
  <modal-with-background :is-open="openModal && !! updatingIssue" :max-width="Number(300)" persistent @modal-close="$emit('cancel')">
    <div class="display-flex flex-column">
      <div v-if="issue" class="white pa-4">
        <v-form ref="form" v-model="isFormValid">
          <v-text-field v-model="issue.title" :disabled="isInHistoricFridge" hide-details label="Point d'attention"></v-text-field>
          <v-select v-model="group" :items="sortChoices" hide-details append-icon="fas fa-caret-down" label="Tri" item-text="name" item-value="group" class="mt-4"></v-select>
          <v-text-field v-if="group == 'decisions'" v-model="issue.decision" :rules="decisionsRule" label="Decision*" class="mt-4"></v-text-field>
        </v-form>
      </div>
      <v-btn :disabled="! isFormValid || blurButton" block rounded color="accent" style="flex: 1 1 auto" @click="submit()">Valider</v-btn>
      <v-btn :disabled="isUnsortedHistoricFridge || blurButton" block rounded color="error" class="mt-6" style="flex: 1 1 auto" @click="$emit('delete', updatingIssue)">
        <v-icon dark small class="mr-4">fa fa-trash-alt</v-icon>Supprimer
      </v-btn>
    </div>
  </modal-with-background>
</template>

<script>
  export default {
    props: {
      updatingIssue: { type: Object, required: false, default: null },
      openModal: { type: Boolean, required: true },
      issueGroup: { type: String, required: false, default: null },
    },
    data() {
      return {
        isFormValid: false,
        issue: null,
        group: null,
        blurButton: false,
      };
    },
    computed: {
      decisionsRule() {
        return this.group == 'decisions' ? [d => !! d || 'Vous devez remplir la décision'] : [];
      },
      isInHistoricFridge() {
        return this.issue && this.$store.state.issues.historicFridge.findIndex(item => item.id == this.issue.id) != -1;
      },
      isUnsortedHistoricFridge() {
        return this.isInHistoricFridge && this.issueGroup == 'fridge';
      },
      sortChoices() {
        const choices = [
          { name: "Action", group: "actions" },
          { name: "Arbitrage", group: "decisions" },
          { name: "Frigo", group: "fridge" },
        ];
        if (! this.isInHistoricFridge) {
          choices.push({ name: "Non trié", group: null });
        }
        return choices;
      },
    },
    watch: {
      openModal(val) {
        if (val) {
          this.issue = { ...this.updatingIssue };
          this.group = this.issueGroup;
          this.blurButton = false;
        }
      },
    },
    methods: {
      submit() {
        if (this.$refs.form.validate()) {
          this.$emit('valid', { issue: this.issue, groupIssue: this.group });
          this.blurButton = true;
        }
      },
    },
  };
</script>
