<template>
  <i v-if="link.type == 'link'" class="link-icon fa fa-external-link"></i>
  <img v-else-if="link.icon" :src="getIconImage(link)" class="link-icon">
  <span v-else-if="link.mime" :class="$filters.mimeIcon(link.mime)" class="link-icon"></span>
  <span v-else class="link-icon fas fa-paperclip"></span>
</template>

<style>
  .link-icon {
    width: 1.25em;
    text-align: center;
    margin-right: 2px;
  }
</style>

<script>
  // const fileIcons = {};
  // fileIcons['img/onedrive_icon.png'] = require('@/img/onedrive_icon.png');

  export default {
    props: {
      link: { type: Object, required: true },
    },
    methods: {
      getIconImage(link) {
        if (! link.icon) return "";
        return link.icon;
        // return fileIcons[link.icon] || link.icon;
      },
    },
  };
</script>
