<template>
  <table>
    <thead>
      <tr>
        <th></th>
        <th v-for="step in preparationSteps" :key="step.module_name" class="px-4 text-center">{{ step.title }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="userPreparation in usersPreparations" :key="userPreparation.user.id">
        <td><avatar-tooltiped :user="userPreparation.user"></avatar-tooltiped></td>
        <td v-for="step in preparationSteps" :key="step.module_name" class="text-center">
          <v-icon v-if="userPreparation.steps[step.module_name] == 1">fas fa-check</v-icon>
          <v-icon v-else-if="! userPreparation.steps[step.module_name]">fas fa-times</v-icon>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    computed: {
      preparationSteps() {
        return this.meeting.getValidPreparationSteps();
      },
      usersPreparations() {
        // return isPrepared for each user and each step like [{ user, steps: { name: isPrepared }]
        return this.meeting.users.map(user => ({
          user,
          steps: this.preparationSteps.reduce((acc, step) => {
            acc[step.module_name] = this.isPrepared(step, user);
            return acc;
          }, {}),
        })).sort((userA, userB) => {
          const preparedStepsA = Object.values(userA.steps).filter(item => item == 1);
          const preparedStepsB = Object.values(userB.steps).filter(item => item == 1);
          if (preparedStepsA.length != preparedStepsB.length) return preparedStepsA.length > preparedStepsB.length ? -1 : 1;

          const lastStepDate = (steps, user) => steps.reduce((acc, step) => {
            const module = this.meeting.modules.find(moduleItem => moduleItem.module_name == step.module_name && moduleItem.user_id == user.id);
            return ! module || acc > module.first_validated_at ? acc : module.first_validated_at;
          }, this.meeting.created_at);
          return lastStepDate(preparedStepsA, userA) < lastStepDate(preparedStepsB, userB) ? -1 : 1;
        });
      },
      ...mapState('current', ['meeting', 'meetingUser']),
    },
    methods: {
      isPrepared(step, user) {
        // 1: validated, 0: not validated, -1: not required
        const userPreparationSteps = this.meeting.getUserPreparationSteps(user);
        if (! userPreparationSteps.includes(step)) return -1;
        const module = this.meeting.modules.find(item => item.module_name == step.module_name && item.user_id == user.id);
        return module?.validated_at ? 1 : 0;
      },
    },
  };
</script>
