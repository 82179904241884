import Vue from 'vue';
import Vuex from 'vuex';
import current from './current';
import actions from './actions';
import issues from './issues';
import ui from './ui';
import lang from './lang';
import users from './users';
import companyinvites from './companyinvites';

Vue.use(Vuex);

const defaultError = { backToList: false, backToHome: false, message: null, canClose: true };
export default new Vuex.Store({
  state: {
    error: defaultError,
  },
  modules: {
    current,
    actions,
    issues,
    ui,
    lang,
    users,
    companyinvites,
  },
  actions: {
    loadMeeting({ dispatch }, meetingId) {
      return dispatch('current/load', meetingId).then((meeting) => {
        dispatch('issues/initMeetingIssues', meeting);
      });
    },
    openErrorModal({ state }, error) {
      if (typeof error == 'string') {
        state.error.message = error;
        return;
      }
      if (! Object.keys(error).length) console.log("Empty error opened in store");
      Object.assign(state.error, defaultError, error);
    },
  },
});
