<template>
  <div>
    <div class="text-center">
      <v-btn :to="{ name: 'Settings' }" color="accent" rounded small class="my-2" @click="$emit('close-drawer')">
        <i class="fa fa-cog mr-2"></i>Accéder à tous les paramètres
      </v-btn>
    </div>
    <div class="display-flex">
      <settings-details condensed></settings-details>
    </div>
    <v-divider class="my-4"></v-divider>
    <div class="display-flex">
      <settings-attendees condensed></settings-attendees>
    </div>
  </div>
</template>

<script>
  import SettingsDetails from '@/components/Settings/SettingsDetails';
  import SettingsAttendees from '@/components/Settings/SettingsAttendees';

  export default {
    components: {
      SettingsDetails,
      SettingsAttendees,
    },
  };
</script>
