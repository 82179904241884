<template>
  <div>
    <p>{{ $t('LOGIN.RESET_PASSWORD_DESC') }}</p>
    <form @submit.prevent="submitResetPassword">
      <v-text-field v-model="password" :label="$t('GLOBAL.PASSWORD')" type="password" hide-details prepend-icon="fas fa-lock" required autofocus>
      </v-text-field>
      <v-text-field v-model="passwordConfirmation" :label="$t('LOGIN.PASSWORD_CONFIRM')" type="password" prepend-icon=" " required>
      </v-text-field>
      <input v-model="token" type="hidden">
      <v-alert :value="!! success" type="success" color="successgreen" class="mb-2">{{ success }}</v-alert>
      <v-alert :value="!! error" type="error" class="mb-2">{{ error }}</v-alert>
      <v-btn :disabled="isSubmittingReset" :loading="isSubmittingReset" color="accent" rounded large block type="submit">
        {{ $t('LOGIN.CHANGE_PASSWORD') }}
      </v-btn>
    </form>
    <div class="mt-6">
      <v-btn text block class="ma-0" @click.prevent="$emit('navigate-section', 'register')">{{ $t('LOGIN.CREATE_ACCOUNT') }}</v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        password: "",
        passwordConfirmation: "",
        error: "",
        success: "",
        isSubmittingReset: false,
      };
    },
    computed: {
      token() {
        return this.$route.query.resettoken;
      },
    },
    methods: {
      submitResetPassword() {
        this.error = "";
        this.success = "";
        if (! this.token) {
          this.error = this.$t('LOGIN.ERROR_MISSING_TOKEN');
          return;
        }
        if (this.password !== this.passwordConfirmation) {
          this.error = this.$t("LOGIN.ERROR_PASSWORD_MATCH");
          return;
        }

        this.isSubmittingReset = true;
        window.apiSrv.call('password/reset', 'store', { token: this.token, password: this.password, password_confirmation: this.passwordConfirmation }).then((response) => {
          this.success = response && response.data && response.data.message;
          this.password = "";
          this.passwordConfirmation = "";
          setTimeout(() => { window.location.href = ""; }, 2000);
        }).catch((error) => {
          this.error = error.message;
          this.isSubmittingReset = false;
        });
      },
    },
  };
</script>
