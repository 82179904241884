<template>
  <v-container :fluid="fluid" class="py-8">
    <v-slide-y-transition mode="out-in">
      <div class="display-flex">
        <v-card
          v-for="(card, cardIndex) in otherCards.before" :key="card.id"
          :style="{ 'margin-top': 16 + 8 * (otherCards.before.length - cardIndex) + 'px', 'margin-bottom': 16 + 8 * (otherCards.before.length - cardIndex) + 'px', 'z-index': cardIndex }"
          :class="`elevation-${cardIndex * 2 + 1}`" class="export-hidden"
          style="flex: 0 0 68px; margin-right: -16px;">
          <div :style="{ 'padding-top': 8 + 8 * cardIndex + 'px', 'padding-bottom': 8 + 8 * cardIndex + 'px' }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn :to="card.destination" :color="`secondary lighten-${otherCards.before.length - cardIndex + 1}`" icon outlined class="mx-2" v-on="on">
                  <v-icon small>{{ card.icon }}</v-icon>
                </v-btn>
              </template>
              {{ card.title }}
            </v-tooltip>
          </div>
          <v-divider></v-divider>
        </v-card>
        <v-card id="main-card" class="elevation-8" style="flex: 1 1 100%; z-index: 5; min-height: calc(100vh - 144px)">
          <div id="main-card-header" class="display-flex align-end overflow-hidden">
            <meeting-details v-if="meeting.category" content="categoryCorner"></meeting-details>
            <div v-else class="pl-5"></div>
            <div style="flex: 1 1 auto;">
              <meeting-details content="title"></meeting-details>
            </div>
            <div class="mr-4" style="flex: 0 0 0; margin-left: auto">
              <template v-if="meeting.status == 'animation'">
                <v-btn v-if="currentPage != 'Animation'" :to="{ name: 'Animation' }" color="accent" rounded right block class="my-2">Rejoindre l'espace de réunion</v-btn>
                <v-btn v-else-if="isMeetingAdmin" :to="{ name: 'Minutes' }" color="secondary" outlined rounded right block class="my-2">Compléter le compte rendu</v-btn>
              </template>
              <v-btn v-if="meeting.status == 'minutes' && isMeetingAdmin && currentPage != 'Minutes'" :to="{ name: 'Minutes' }" color="accent" rounded right block class="my-2">
                Compléter le compte rendu
              </v-btn>
              <v-btn
                v-if="meeting.status == 'preparation' && currentPage != 'Preparation'" :to="{ name: 'Preparation' }"
                :color="! meetingIsToday ? 'accent' : 'secondary'" :outlined="meetingIsToday" rounded right block class="my-2">
                Préparer ma réunion
              </v-btn>
              <v-btn
                v-if="meeting.status == 'preparation' && isMeetingAdmin" :color="meetingIsToday ? 'accent' : 'secondary'" :outlined="! meetingIsToday"
                rounded right block class="my-2"
                @click="startAnimation()">
                Démarrer la réunion
              </v-btn>
              <publish-button v-if="meeting.status == 'draft'" right block class="my-2"></publish-button>
              <slot name="actions"></slot>
            </div>
          </div>
          <preparation-teaser v-if="meeting.status == 'preparation' && currentPage != 'Preparation'"></preparation-teaser>
          <v-container v-if="! isLoading" :class="slotClass || 'pa-5'" fluid>
            <slot name="default"></slot>
          </v-container>
          <v-progress-circular v-if="isLoading" size="70" class="ma-12" indeterminate color="primary"></v-progress-circular>
        </v-card>
        <v-card
          v-for="(card, cardIndex) in otherCards.after" :key="card.id"
          :style="{ 'margin-top': 16 + 8 * (cardIndex + 1) + 'px', 'margin-bottom': 16 + 8 * (cardIndex + 1) + 'px', 'z-index': otherCards.after.length - cardIndex }"
          :class="`elevation-${(otherCards.after.length - cardIndex) * 2 + 1}`" class="export-hidden"
          style="flex: 0 0 68px; margin-left: -16px; padding-left: 16px">
          <div :style="{ 'padding-top': 8 + 8 * (otherCards.after.length - cardIndex) + 'px', 'padding-bottom': 8 + 8 * (otherCards.after.length - cardIndex) + 'px' }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn :to="card.destination" :color="`secondary lighten-${cardIndex + 2}`" icon outlined class="mx-2" v-on="on">
                  <v-icon small>{{ card.icon }}</v-icon>
                </v-btn>
              </template>
              {{ card.title }}
            </v-tooltip>
          </div>
          <v-divider></v-divider>
        </v-card>
      </div>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import Meeting from '@/models/Meeting';
  import MeetingDetails from '@/components/MeetingDetails/MeetingDetails';
  import PublishButton from '@/components/Settings/partials/PublishButton';
  import PreparationTeaser from '@/components/Preparation/partials/PreparationTeaser';

  export default {
    components: {
      MeetingDetails,
      PublishButton,
      PreparationTeaser,
    },
    props: {
      isLoading: { type: Boolean, default: false },
      fluid: { type: Boolean, default: false },
      slotClass: { type: String, default: '' },
    },
    computed: {
      meetingIsToday() {
        return moment().isSame(this.meeting.date, 'day');
      },
      currentPage() {
        return this.$route.name;
      },
      otherCards() {
        const cards = [
          { id: 'home', title: "Détails pratiques", icon: 'fas fa-info', destination: { name: 'Home' } },
          { id: 'preparation', title: "Préparation", icon: 'fas fa-clipboard-check', destination: { name: 'Preparation' } },
          { id: 'animation', title: "Animation", icon: 'fas fa-chalkboard-teacher', destination: { name: 'Animation' } },
          { id: 'minutes', title: "Compte rendu", icon: 'fas fa-file-signature', destination: { name: 'Minutes' } },
        ];
        const currentPageIndex = cards.findIndex(item => item.destination.name == this.currentPage);
        return { before: cards.slice(0, currentPageIndex), after: cards.slice(currentPageIndex + 1) };
      },
      ...mapState('current', ['meeting']),
      ...mapGetters('current', ['isMeetingAdmin']),
    },
    methods: {
      close() {
        const func = this.modalClose || (() => { this.$router.push({ name: 'Home' }); });
        func();
      },
      startAnimation() {
        this.$store.dispatch('ui/msgbox/open', {
          title: "Démarrer la phase d'Animation de réunion ?",
          body: "Les participants ne pourront plus modifier leur préparation.",
          buttons: { ok: "GLOBAL.OK", cancel: "GLOBAL.CANCEL" },
        }).then(() => {
          this.meeting.status = 'animation';
          this.meeting.saveFields('status', true).then((response) => {
            if (response && response.data && response.data.meeting) this.$store.commit('current/setMeeting', new Meeting(response.data.meeting));
            this.$router.push({ name: 'Animation' });
          });
        }).catch(() => {});
      },
    },
  };
</script>
