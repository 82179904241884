<template>
  <v-card style="height: 100%;position: relative">
    <div class="issues-card-title pa-2 white--text error">Points d'attention</div>
    <draggable v-model="issues" v-bind="dragOptions" :clone="clone" style="position: relative; margin-bottom: 65px; cursor: move" class="px-4">
      <div v-for="issue in issues" :key="issue.id" class="issueItem" @click="clickIssue(issue)">
        <issue-item> {{ issue.title }} </issue-item>
      </div>
    </draggable>
    <v-form v-if="isAdmin" style="position: absolute; left:10px; right:10px; bottom:10px;" @submit.prevent="createIssue">
      <v-text-field
        v-model="newIssueTitle" hide-details label="Nouveau point d'attention" color="primary darken-1" style="width:100%" append-icon="add_box"
        @click:append="createIssue">
      </v-text-field>
    </v-form>
  </v-card>
</template>

<script>
  import draggable from 'vuedraggable';
  import IssueItem from './AnimationIssueItem';

  export default {
    components: { draggable, IssueItem },
    model: {
      prop: 'unsortedIssues',
    },
    props: {
      unsortedIssues: { required: true, type: Array },
      moduleName: { required: true, type: String },
      isAdmin: { required: true, type: Boolean },
    },
    data() {
      return {
        dragOptions: {
          group: 'issues',
          draggable: ".issueItem",
        },
        newIssueTitle: "",
      };
    },
    computed: {
      issues: {
        get() {
          return window.deepcopy(this.unsortedIssues);
        },
        set(val) {
          this.$emit("update", val);
        },
      },
    },
    created() {
      this.dragOptions.disabled = ! this.isAdmin;
    },
    methods: {
      createIssue() {
        if (! this.newIssueTitle) return;
        const issue = {
          title: this.newIssueTitle,
          id: window.uuid(),
        };
        this.$emit('create-issue', issue);
        this.newIssueTitle = '';
      },
      clone(original) {
        this.$emit('clone-issue', original);
      },
      clickIssue(issue) {
        if (this.isAdmin) {
          this.$emit('click-issue', issue);
        }
      },
    },
  };
</script>
