<template>
  <div>
    <h3>
      <span>{{ step.title }} </span>
      <em v-if="step.time_spent" style="color: rgb(136, 136, 136);">{{ secToMin(step.time_spent) }}</em>
    </h3>
    <template v-for="moduleItem in modules">
      <p v-if="moduleItem.content && moduleItem.content.text" :key="moduleItem.id" v-html="moduleItem.content.text"></p>
    </template>
  </div>
</template>

<script>
  import meetingClosureModuleMixin from './meetingClosureModuleMixin';

  export default {
    mixins: [meetingClosureModuleMixin],
  };
</script>
