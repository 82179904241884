<template>
  <section>
    <div class="text-center">
      <div class="avatar-cropper" style="display: inline-block; position: relative">
        <croppa
          v-model="croppa" :placeholder-font-size="16" :zoom-speed="7" :file-size-limit="20971520" :quality="1"
          placeholder="Sélectionnez une image" accept="image/*" prevent-white-space>
        </croppa>
        <div style="position: absolute; bottom: 0; right: -8px">
          <v-icon :class="chosenFile ? 'pointer' : 'grey--text'" @click="zoomIn()">fa fa-lg fa-search-plus</v-icon>
          <v-icon :class="chosenFile ? 'pointer' : 'grey--text'" class="ml-1" @click="zoomOut()">fa fa-lg fa-search-minus</v-icon>
        </div>
      </div>
    </div>
    <div class="display-flex mt-6">
      <v-btn rounded outlined @click="close">Annuler</v-btn>
      <v-spacer></v-spacer>
      <v-btn :disabled="! chosenFile" rounded color="accent" @click="uploadAvatar"><v-icon small class="mr-2">fas fa-upload</v-icon>Envoyer</v-btn>
    </div>
  </section>
</template>

<style>
  .avatar-cropper canvas, .avatar-cropper > * {
    border-radius: 50%;
  }
</style>

<script>
  import Croppa from 'vue-croppa';
  import apiSrv from '@/services/apiSrv';
  import 'vue-croppa/dist/vue-croppa.css';

  export default {
    components: {
      Croppa: Croppa.component,
    },
    data() {
      return {
        croppa: {},
      };
    },
    computed: {
      user() {
        return this.$store.state.users.user;
      },
      chosenFile() {
        return this.croppa.getChosenFile && this.croppa.getChosenFile();
      },
    },
    methods: {
      zoomIn() {
        if (! this.chosenFile) return;
        this.croppa.zoomIn();
      },
      zoomOut() {
        if (! this.chosenFile) return;
        this.croppa.zoomOut();
      },
      uploadAvatar() {
        if (! this.chosenFile) return;
        this.croppa.generateBlob((blob) => {
          if (! blob) return;
          blob.name = `${this.chosenFile.name.replace(/\..{3,4}$/, '')}.png`;
          apiSrv.uploadResource(blob).then((response) => {
            const id = response && response.data && response.data.file && response.data.file.id;
            if (! id) return Promise.reject(new Error('invalid upload'));
            return apiSrv.call('users', 'update', { id: this.user.id, avatar: response.data.file.url }).then(() => {
              this.user.avatar = response.data.file.url;
            });
          });
          this.close();
        }, 'image/png');
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>
