<template>
  <div>
    <h3>{{ step.title }} </h3>
    <template v-for="(userKpis, index) in sortedModuleDatas">
      <template v-if="(userKpis.content && userKpis.content.data && userKpis.content.data.length) || (step.users_time && step.users_time[userKpis.user_id])">
        <!-- Avatar & Username -->
        <strong :key="index" style="display: flex; align-items: center;">
          <img :src="userKpis.user_avatar" :alt="userKpis.user_name" class="v-avatar ma-2" style="height: 25px; width: 25px;">
          <span>{{ userKpis.user_name }} </span>
          <em v-if="step.users_time && step.users_time[userKpis.user_id]" style="color: rgb(136, 136, 136);">{{ secToMin(step.users_time[userKpis.user_id]) }}</em>
        </strong>
        <!-- Kpis -->
        <template v-if="userKpis.content && userKpis.content.data && userKpis.content.data.length">
          <template v-for="kpi in userKpis.content.data">
            <ul v-if="kpi.mood || kpi.vlaue || kpi.url">
              <li :key="kpi.id" style="display: flex; align-items: center">
                <span v-if="kpi.mood" :class="`mood-icon ${kpi.mood}`" style="vertical-align: middle;"></span>
                <span v-if="kpi.type == 'text'"> {{ kpi.value }}</span>
                <img v-else-if="kpi.url" :src="kpi.url" style="max-width: 100px; max-height: 100px;">
              </li>
            </ul>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
  import meetingClosureModuleMixin from './meetingClosureModuleMixin';

  export default {
    mixins: [meetingClosureModuleMixin],
  };
</script>
