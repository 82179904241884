<template>
  <div class="file-upload" @dragenter="mouseOverDropbox = true" @dragleave="mouseOverDropbox = false" @drop="mouseOverDropbox = false">
    <vue-upload-component
      ref="upload" v-model="files" :drop="true" :post-action="uploadFileUrl" :headers="tokenHeaders"
      :multiple="multiple" :accept="accept" :thread="5" :size="20 * 1024 * 1024" :input-id="inputId"
      :class="{ 'on-dragover': mouseOverDropbox && $refs.upload && $refs.upload.dropActive, 'file-upload-full-height': fullHeight }"
      class="filedropbox"
      :style="{ 'pointer-events': $refs.upload && $refs.upload.dropActive ? 'none' : 'all' }" style="width: 100%"
      @input-file="inputFile">
      <slot name="content">
        <div>
          <div style="padding: 0 12px">
            <v-row v-for="file in files" :key="file.id" class="my-2" justify="center" align="center">
              <v-icon class="mr-2"> fas fa-upload </v-icon>
              <span>{{ file.name }}</span>
              <v-progress-circular :value="file.progress * 1 < 100 ? file.progress * 1 : 100" class="ml-2" color="accent"></v-progress-circular>
            </v-row>
          </div>
          <h3 class="ma-0 secondary--text px-1">
            <i class="fas fa-plus-circle"></i>
            <slot><span class="ml-2">Faites glisser ou cliquez pour ajouter une image</span></slot>
          </h3>
        </div>
        <i v-show="files.length" class="fa fa-lg fa-trash" style="position: absolute; bottom: 20px; right: 10px; opacity: .6" @click.stop.prevent="$refs.upload.clear()"></i>
      </slot>
    </vue-upload-component>
  </div>
</template>

<style lang="scss">
  .file-upload {
    .filedropbox {
      background: #f9f9f9;
      border: 3px dashed #ddd;
      text-align: center;
      padding: 20px 0;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .file-upload-full-height {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .on-dragover {
      border-color: #ff9b1d;
    }
  }
</style>

<script>
  import VueUploadComponent from 'vue-upload-component';

  export default {
    components: {
      VueUploadComponent,
    },
    props: {
      inputId: { type: String, default: null },
      imageOnly: { type: Boolean, default: false },
      wordOnly: { type: Boolean, default: false },
      multiple: { type: Boolean, default: true },
      fullHeight: { type: Boolean, default: false },
    },
    data() {
      return {
        files: [],
        mouseOverDropbox: false,
        uploadFileUrl: `${window.apiSrv.host}v1/files`,
        tokenHeaders: { 'X-Auth-Token': localStorage.getItem("api_token") },
      };
    },
    computed: {
      accept() {
        if (this.imageOnly) return 'image/*';
        if (this.wordOnly) return '.docx';
        return undefined;
      },
    },
    methods: {
      inputFile(newFile, oldFile) {
        if (newFile && ! oldFile) {
          // Add file
          if (this.imageOnly && ! newFile.type.startsWith('image/')) {
            this.$refs.upload.update(newFile, { error: 'typeimage' });
            return;
          }
        }
        // Automatic upload
        if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
          if (newFile) this.$refs.upload.update(newFile, { name: newFile.name.replace(/'/g, "’") });
          if (! this.$refs.upload.active) this.$refs.upload.active = true;
        }
        // File upload is success
        if (newFile && newFile.success && (! oldFile || ! oldFile.success)) {
          this.$emit('uploaded', newFile);
          this.$refs.upload.remove(newFile);
        }
      },
      errorMessage(file) {
        if (! file.error) return "";
        if (file.error == 'size') return "Le fichier dépasse la limite de taille.";
        if (file.error == 'typeimage') return "Format image attendu.";
        if (file.response) return file.response.message || file.response.status;
        return file.error;
      },
    },
  };
</script>
