<template>
  <v-container class="pa-0">
    <div class="text-caption font-italic pa-2">Ces notes sont <b>personnelles</b> et ne sont pas visibles pour les autres participants.</div>
    <v-tabs v-model="tabIndex" left slider-color="primary" class="mx-2" @change="changeIndex($event)">
      <v-tab v-for="(note, index) in meetingUser.notes" :key="index" :href="`#note-${index}`" class="no-padding">
        <v-text-field v-if="tabIndex == `note-${index}`" v-model="note.title" hide-details class="ma-0 pa-0" @input="saveNotes(note)"></v-text-field>
        <span v-else class="px-1" style="text-transform: none">{{ note.title }}</span>
      </v-tab>
      <v-tab href="#plus">
        +
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabIndex">
      <v-tab-item v-for="(note, index) in meetingUser.notes" :key="index" :value="`note-${index}`">
        <text-editor v-model="note.content" placeholder="Notes" class="mx-2" @input="saveNotes(note)"></text-editor>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<style>
  .v-tab.no-padding > a {
    padding: 0;
  }
</style>

<script>
  import _ from 'lodash';
  import TextEditor from '@/components/Reusables/TextEditor';

  export default {
    components: { TextEditor },
    data() {
      return {
        tabIndex: "note-0",
      };
    },
    computed: {
      meeting() { return this.$store.state.current.meeting; },
      meetingUser() { return this.$store.state.current.meetingUser; },
    },
    created() {
      this.meetingUser.notes = this.meetingUser.notes || [];
      this.meetingUser.notes = this.meetingUser.notes.map((item) => {
        const ret = item.content ? item : Object.assign(item, { content: "" });
        return ret;
      });
      this.meetingUser.notes[0] = this.meetingUser.notes[0] || { content: "", title: "Mes notes" };
    },
    methods: {
      saveNotes: _.debounce(function () {
        this.$nextTick(() => this.meeting.saveUser(this.meetingUser, 'update'));
      }, 700),
      addNote() {
        this.meetingUser.notes.push({ content: "", title: "Mes notes" });
        this.tabIndex = `note-${this.meetingUser.notes.length - 1}`;
      },
      changeIndex(index) {
        if (index == 'plus') {
          this.addNote();
        }
      },
    },
  };
</script>
