<template>
  <v-fade-transition leave-absolute>
    <v-card v-if="! hidden" class="mt-4 pa-4 elevation-12 text-center" style="border-radius: 0; width: 100%">
      <v-btn color="grey" text icon class="my-4" style="position: absolute; top: -16px; right: 0" @click="hidden = true"><v-icon>fas fa-times-circle</v-icon></v-btn>

      <template v-if="meetingUserIsFullyPrepared">
        <div><b>Votre préparation est terminée.</b></div>
      </template>
      <template v-else>
        <v-icon>fa-exclamation-circle</v-icon>
        <div><b>Votre préparation est importante pour le bon déroulé de la réunion !</b></div>
        <div class="my-4"><v-btn :to="{ name: 'Preparation' }" color="accent" rounded @click="hidden = true">Préparer ma réunion</v-btn></div>
      </template>

      <div v-if="preparatedUsers.length">
        <div><avatar-tooltiped v-for="user in preparatedUsers" :key="user.id" :user="user"></avatar-tooltiped></div>
        <div v-if="preparatedUsers.length > 1">{{ preparatedUsers.length }} participants ont déjà préparé la réunion</div>
        <div v-else-if="! meetingUserIsFullyPrepared">1 participant a déjà préparé la réunion</div>
        <v-btn color="grey" text small @click="openPreparationStatusModal = true">Voir les détails</v-btn>
      </div>

      <modal-with-background :is-open="openPreparationStatusModal" :max-width="1000" title="Préparation des participants" @modal-close="openPreparationStatusModal = false">
        <users-preparation-table></users-preparation-table>
      </modal-with-background>
    </v-card>
  </v-fade-transition>
</template>

<script>
  import { mapState } from 'vuex';
  import UsersPreparationTable from './UsersPreparationTable';

  export default {
    components: {
      UsersPreparationTable,
    },
    data() {
      return {
        hidden: false,
        openPreparationStatusModal: false,
      };
    },
    computed: {
      meetingUserIsFullyPrepared() {
        const userPreparationSteps = this.meeting.getUserPreparationSteps(this.meetingUser);
        return ! userPreparationSteps.some((step) => {
          const module = this.meeting.modules.find(item => item.module_name == step.module_name && item.user_id == this.meetingUser.id);
          return ! module?.validated_at;
        });
      },
      preparatedUsers() {
        return this.meeting.users.filter((user) => {
          if (user.id == this.meetingUser.id) return this.meetingUserIsFullyPrepared; // current user is prepared if fully prepared
          return this.userPreparedSteps(user).length > 0; // other users are prepared if at least one step is validated
        });
      },
      ...mapState('current', ['meeting', 'meetingUser']),
    },
    methods: {
      userPreparedSteps(user) {
        const userPreparationSteps = this.meeting.getUserPreparationSteps(user);
        return userPreparationSteps.filter((step) => {
          const module = this.meeting.modules.find(item => item.module_name == step.module_name && item.user_id == user.id);
          return module?.validated_at;
        });
      },
    },
  };
</script>
