<template>
  <v-container class="pa-0">
    <v-row>
      <!-- NEW TOPIC -->
      <v-col cols="12">
        <v-card class="grey lighten-3 elevation-0 pa-2">
          <v-card-text>
            <v-form @submit.prevent="sendTopicIdea">
              <!-- TITLE & DURATION -->
              <div class="display-flex">
                <v-text-field v-model="newTopic" label="Proposer un sujet de discussion" style="max-width: 400px"></v-text-field>
                <div class="mx-2 mx-lg-4"></div>
                <v-text-field v-model="duration" label="Durée (min)" style="max-width: 80px" type="number" min="0" hide-details>
                </v-text-field>
              </div>
              <div class="display-flex align-center">
                <!-- FILE DROP ZONE-->
                <file-upload input-id="file-free-topics" style="max-width: 400px" @uploaded="fileUploaded">
                  Ajouter un fichier
                </file-upload>
                <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
                <!-- ADD LINK FORM-->
                <form class="display-flex align-center" @submit.prevent="addLink()">
                  <v-text-field id="attachLink" v-model="linkurl" label="Ajouter un lien :" placeholder="http://..."></v-text-field>
                  <v-btn :disabled="! linkurl" color="accent" small rounded class="ml-1" type="submit">Ajouter</v-btn>
                </form>
              </div>

              <template v-if="links.length">
                <div v-for="(link, linkIndex) in links" :key="link.id" class="link-item display-flex align-center pb-2">
                  <link-icon :link="link"></link-icon>
                  <a :href="link.url" :style="{ 'vertical-align': link.icon ? 'middle' : '' }" class="text-ellipsis" style="margin-left: 4px;" target="_blank" rel="noopener noreferrer">
                    {{ link.name || link.url }}
                  </a>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn icon small class="ml-4" color="error" v-on="on" @click="removeLink(linkIndex)">
                        <v-icon small>fas fa-times</v-icon>
                      </v-btn>
                    </template>
                    <span>Supprimer la pièce jointe</span>
                  </v-tooltip>
                </div>
              </template>
            </v-form>
            <v-btn :disabled="! newTopic" rounded class="mt-2" @click="sendTopicIdea">Ajouter</v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- TOPICS LIST -->
      <v-col v-if="usersTopics.length" cols="12">
        <v-card class="grey lighten-3 elevation-0 pa-2">
          <v-card-text>
            <ul>
              <li v-for="(topic, index) in usersTopics" class="mb-4">
                <!-- TOPIC ITEM EDITING -->
                <template v-if="editingTopic && editingTopic.id == topic.id">
                  <div class="display-flex align-center">
                    <v-text-field v-model="editingTopic.title" label="Sujet de discussion" style="max-width: 380px"></v-text-field>
                    <div class="mx-2 mx-lg-4"></div>
                    <v-text-field
                      v-model="editingTopic.duration" label="Durée (min)" style="max-width: 80px"
                      type="number" min="0">
                    </v-text-field>
                    <v-btn
                      title="Sauvegarder le sujet" icon small outlined color="success" class="ml-4"
                      @click="saveTopic(editingTopic)">
                      <v-icon small>fas fa-check</v-icon>
                    </v-btn>
                    <v-btn
                      title="Annuler" icon small outlined color="secondary" class="mx-4"
                      @click="editingTopic = null">
                      <v-icon small>fas fa-times</v-icon>
                    </v-btn>
                    <v-btn
                      small outlined color="error" class="ml-auto"
                      @click="deleteTopic(index)">
                      <v-icon small class="mr-1">fas fa-trash-alt</v-icon>Supprimer le sujet
                    </v-btn>
                  </div>
                  <div class="display-flex align-center">
                    <!-- FILE DROP ZONE-->
                    <file-upload :input-id="`${editingTopic.id}-file-free-topics`" style="max-width: 380px" @uploaded="fileUploaded($event, editingTopic)">
                      Ajouter un fichier
                    </file-upload>
                    <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
                    <!-- ADD LINK FORM-->
                    <form class="display-flex align-center" @submit.prevent="addLink(editingTopic)">
                      <v-text-field id="attachLink" v-model="linkurl" label="Ajouter un lien :" placeholder="http://..."></v-text-field>
                      <v-btn :disabled="! linkurl" color="accent" small rounded class="ml-1" type="submit">Ajouter</v-btn>
                    </form>
                  </div>
                  <!-- LINKS -->
                  <div v-if="editingTopic.links && editingTopic.links.length" class="my-2 ml-6">
                    <div v-for="(link, linkIndex) in editingTopic.links" :key="linkIndex" class="link-item display-flex align-center">
                      <link-icon :link="link"></link-icon>
                      <a :href="link.url" :style="{ 'vertical-align': link.icon ? 'middle' : '' }" class="text-ellipsis" style="margin-left: 4px;" target="_blank" rel="noopener noreferrer">
                        {{ link.name || link.url }}
                      </a>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn icon small class="ml-4" color="error" v-on="on" @click="removeLink(linkIndex, editingTopic)">
                            <v-icon small>fas fa-times</v-icon>
                          </v-btn>
                        </template>
                        <span>Supprimer la pièce jointe</span>
                      </v-tooltip>
                    </div>
                  </div>
                </template>
                <!-- TOPIC ITEM -->
                <template v-else>
                  <span>{{ topic.title }}</span>
                  <span v-if="topic.duration" style="font-style: italic;">({{ topic.duration }} min)</span>
                  <avatar-tooltiped v-if="topic.user_id != meetingUser.id" :user="getUser(topic.user_id)" :size="avatarSize"></avatar-tooltiped>
                  <v-btn
                    v-if="(topic.user_id == meetingUser.id || isMeetingAdmin) && ! editingTopic" title="Modifier le sujet" icon small outlined class="ml-4" color="secondary"
                    @click="editTopic(topic)">
                    <v-icon small>fas fa-edit</v-icon>
                  </v-btn>
                  <!-- LINKS -->
                  <div v-if="topic.links && topic.links.length" class="my-2 ml-6">
                    <div v-for="(link, linkIndex) in topic.links" :key="linkIndex" class="link-item display-flex align-center">
                      <link-icon :link="link"></link-icon>
                      <a :href="link.url" :style="{ 'vertical-align': link.icon ? 'middle' : '' }" class="text-ellipsis" style="margin-left: 4px;" target="_blank" rel="noopener noreferrer">
                        {{ link.name || link.url }}
                      </a>
                    </div>
                  </div>
                </template>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
  .link-menu {
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s;
  }
  .link-item:hover .link-menu {
    visibility: visible;
    opacity: 1;
  }
</style>

<script>
  import { mapState, mapGetters } from 'vuex';
  import FileUpload from '@/components/Reusables/FileUpload';
  import LinkIcon from '@/components/Preparation/partials/LinkIcon';

  function setUsersTopics(modules, moduleName) {
    const topicModules = modules.filter(moduleItem => (moduleItem.module_name == moduleName));
    const topics = topicModules.reduce((prevMerge, moduleItem) => {
      const topicsItem = window.deepcopy(moduleItem.content || []).map((topic) => {
        topic.user_id = moduleItem.user_id;
        return topic;
      });
      return prevMerge.concat(topicsItem);
    }, []);
    return topics;
  }

  export default {
    components: {
      FileUpload,
      LinkIcon,
    },
    props: {
      moduleName: { required: true, type: String },
    },
    data() {
      return {
        newTopic: '',
        duration: 5,
        links: [],
        linkurl: '',
        myUsername: '',
        module: {},
        usersTopics: {},
        editingTopic: null,
        avatarSize: 25,
      };
    },
    computed: {
      ...mapState('current', ['meeting', 'meetingUser']),
      ...mapGetters('current', ['isMeetingAdmin']),
    },
    created() {
      this.module = this.meeting.getUserModule(this.moduleName, this.meetingUser, []);
      this.myUsername = this.$store.getters['users/getUsername'](this.meetingUser);
      this.usersTopics = setUsersTopics(this.meeting.modules, this.moduleName);
    },
    methods: {
      addLink(topic) {
        if (! this.linkurl) return;
        const links = topic?.links || this.links;
        let name = this.linkurl.replace(/\/+$/, ''); // strip ending slashes
        name = name.substr(name.lastIndexOf('/') + 1);
        links.push({ type: 'link', url: this.linkurl, name });
        this.linkurl = "";
      },
      removeLink(index, topic) {
        const links = topic?.links || this.links;
        if (index > -1) {
          links.splice(index, 1);
        }
      },
      fileUploaded(newFile, topic) {
        if (! (newFile && newFile.response && newFile.response.file)) return;
        const { file } = newFile.response;
        const links = topic?.links || this.links;
        links.push({ type: 'file', url: file.url, name: file.display_filename, mime: file.mime });
      },
      saveModule: _.debounce(function () { this.meeting.sendModule(this.module); }, 700),
      sendTopicIdea() {
        if (! this.newTopic) return;
        const topic = {
          id: window.uuid(),
          title: this.newTopic,
          duration: this.duration,
          links: this.links,
        };
        this.module.content.push(topic);
        const topicUser = this.meeting.users.find(user => user.id == this.module.user_id) || {};
        const userDatas = {
          user_name: this.$store.getters['users/getUsername'](topicUser),
          user_avatar: this.meetingUser.avatar,
          user_id: this.meetingUser.id,
        };
        this.usersTopics.push({ ...topic, ...userDatas });
        this.newTopic = "";
        this.links = [];
        this.saveModule();
      },
      deleteTopic(index) {
        const topic = this.usersTopics[index];
        const indexInModule = this.module.content.findIndex(topicItem => topic.id == topicItem.id);
        this.usersTopics.splice(index, 1);
        this.module.content.splice(indexInModule, 1);
        this.saveModule();
      },
      getUser(userId) {
        return this.meeting.users.find(userItem => userItem.id == userId);
      },
      editTopic(topic) {
        this.editingTopic = window.deepcopy(topic);
      },
      saveTopic({ id, title, duration, links } = {}) {
        let topic = this.module.content.find(topicItem => topicItem.id == id);
        if (! topic) {
          if (! this.isMeetingAdmin) return;
          for (let i = this.meeting.modules.length - 1; i >= 0; i--) {
            const item = this.meeting.modules[i];
            if (item.module_name == this.moduleName) {
              const result = item.content.find(topicItem => topicItem.id == id);
              if (result) {
                topic = result;
                break;
              }
            }
          }
        }
        if (! topic) return;
        _.extend(topic, { title, duration, links });
        this.saveModule();
        const userTopic = this.usersTopics.find(item => item.id == id);
        _.extend(userTopic, { title, duration, links });
        this.editingTopic = null;
      },
    },
  };
</script>
