export default {
  props: {
    meeting: { type: Object, required: true },
    step: { type: Object, required: true },
  },
  computed: {
    modules() {
      return this.meeting.modules.filter(item => item.module_name == this.step.module_name);
    },
    sortedModuleDatas() {
      return this.meeting.getStepAttendees(this.step).map((user) => {
        const module = this.modules.find(moduleItem => moduleItem.user_id == user.id);
        if (module) {
          return {
            content: module.content?.length ? [...module.content] : module.content,
            user_id: user.id,
            user_avatar: user.avatar,
            user_name: this.$store.getters['users/getUsername'](user),
            validated_date: module.first_validated_at || moment().format(),
          };
        }
        return {
          user_id: user.id,
          user_avatar: user.avatar,
          user_name: this.$store.getters['users/getUsername'](user),
          validated_date: moment().format(),
        };
      }).sort((moduleA, moduleB) => moduleA.validated_date > moduleB.validated_date);
    },
    secToMin() { return window.secToMin; },
  },
};
