<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-avatar :size="size" class="ma-2" color="grey lighten-2" :style="avatarStyle" v-on="on">
        <img :src="avatar" :alt="username" :class="borderColor" class="avatar-tooltiped-img">
      </v-avatar>
    </template>
    <slot>{{ username }}</slot>
  </v-tooltip>
</template>

<style>
  .avatar-tooltiped-img {
    border: solid 3px white;
    background-color: white !important;
  }
</style>

<script>
  export default {
    props: {
      borderColor: { type: String, default: '' },
      size: { type: Number, default: 40 },
      user: { type: Object, required: true },
      avatarStyle: { type: String, default: '' },
    },
    data() {
      return {};
    },
    computed: {
      username() {
        return this.$store.getters['users/getUsername'](this.user);
      },
      avatar() {
        return this.user && this.user.avatar || "https://secure.gravatar.com/avatar/0?d=mm";
      },
    },
  };
</script>
