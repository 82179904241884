import Vue from 'vue';
import VueRouter from 'vue-router';
import apiSrv from '@/services/apiSrv';
import Settings from '@/components/Settings/Settings';
import Preparation from '@/components/Preparation/Preparation';
import Animation from '@/components/Animation/Animation';
import Minutes from '@/components/Minutes/Minutes';
import Home from '@/components/Home/Home';
import List from '@/components/List/List';
import ActionsList from '@/components/Dashboards/ActionsList/ActionsList';
import MinutesDashboard from '@/components/Dashboards/MinutesDashboard/MinutesDashboard';
import Manage from '@/components/Manage/Manage';

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/auth_token/:auth_token',
      beforeEnter(to, from, next) {
        const token = to.params.auth_token;
        if (token) {
          apiSrv.setAuthToken(token);
          window.location.href = "";
        } else {
          next('/');
        }
      },
    },
    { path: '/', redirect: '/list' },
    {
      path: '/meeting/:meetingId/settings',
      name: 'Settings',
      component: Settings,
      props: true,
    },
    {
      path: '/meeting/:meetingId/preparation',
      name: 'Preparation',
      component: Preparation,
      props: true,
    },
    {
      path: '/meeting/:meetingId/animation',
      name: 'Animation',
      component: Animation,
      props: true,
    },
    {
      path: '/meeting/:meetingId/minutes',
      name: 'Minutes',
      component: Minutes,
      props: true,
    },
    {
      path: '/meeting/:meetingId/home',
      name: 'Home',
      component: Home,
      props: true,
    },
    {
      path: '/meeting/:meetingId',
      redirect: { name: 'Home' },
    },
    {
      path: '/list',
      name: 'List',
      component: List,
    },
    {
      path: '/dashboards/actions',
      name: 'ActionsList',
      component: ActionsList,
    },
    {
      path: '/dashboards/minutes',
      name: 'MinutesDashboard',
      component: MinutesDashboard,
    },
    {
      path: '/manage',
      name: 'Manage',
      component: Manage,
    },
    { path: '*', redirect: '/' },
  ],
});

export default router;
