<template>
  <v-container class="pa-0">
    <v-row justify="center" align="start">
      <div class="display-flex align-center" style="flex-grow: 0; height:64px">
        <v-icon size="18" class="handle grab" style="max-width: 10px;">fas fa-grip-vertical</v-icon>
        <v-icon size="18" color="info" class="ml-2" style="cursor: help;" @click="clickHelp">fas fa-question-circle</v-icon>
      </div>
      <v-col cols="4">
        <v-select
          v-model="value.module" :items="Object.values(modulesList)" label="Module" item-text="title" item-value="module" hide-details
          append-icon="fas fa-caret-down" @input="setModule($event)">
        </v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="value.title" label="Titre" hide-details
          @input="update()">
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-if="modulesSettings[value.module] && modulesSettings[value.module].animation"
          v-model="timeInMinutes" :label="value.timePerAttendee ? 'Minutes par personne' : 'Minutes'" :append-outer-icon="value.timePerAttendee ? 'fas fa-users' : null"
          type="number" min="0" hide-details
          @click:append-outer="selectModuleAttendeesOpen = ! selectModuleAttendeesOpen" @input="update()">
        </v-text-field>
        <div v-if="value.timePerAttendee && selectModuleAttendeesOpen">
          <v-select
            v-model="value.attendeesWhiteList" :items="attendees" :item-text="user => (user.firstname + ' ' + user.lastname)"
            :label="value.attendeesWhiteList && value.attendeesWhiteList.length ? 'Participants' : 'Tous les participants'" item-value="id" multiple chips hide-details
            style="width: 300%; float: right"
            @input="update()">
            <v-list-item slot="prepend-item">
              <v-list-item-content>
                <v-list-item-title>Restreindre l'étape aux participants suivants uniquement :</v-list-item-title>
                <v-list-item-subtitle>(ou laisser vide pour que tous y aient accès)</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider slot="prepend-item" class="mt-2"></v-divider>
          </v-select>
        </div>
      </v-col>
      <div class="display-flex align-center" style="height:64px">
        <v-btn fab small color="error" outlined class="mt-2" @click="$emit('delete')"><v-icon size="15">fas fa-trash-alt</v-icon></v-btn>
      </div>
    </v-row>
  </v-container>
</template>

<script>
  import Meeting from '@/models/Meeting';
  import StepsSrv from '@/services/StepsSrv';

  export default {
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      value: { required: true, type: Object },
      temporarySteps: { required: true, type: Array },
    },
    data() {
      return {
        modulesList: StepsSrv.defaultSteps,
        tips: StepsSrv.tips,
        selectModuleAttendeesOpen: this.value.attendeesWhiteList && this.value.attendeesWhiteList.length > 0,
        previousModule: this.value.module,
      };
    },
    computed: {
      meeting() {
        return this.$store.state.current.meeting;
      },
      timeInMinutes: {
        get() {
          return (this.value.time || 0) / 60;
        },
        set(newVal) {
          this.value.time = newVal * 60;
        },
      },
      attendees() {
        return this.meeting.users;
      },
      modulesSettings() {
        return Meeting.getModulesSettings();
      },
    },
    methods: {
      update() {
        this.$emit('change', { ...this.value });
      },
      setModule(moduleType) {
        const module = _.cloneDeep(this.modulesList[moduleType]);
        if (! module) return;
        const regex = /([0-9]+)/g;
        const last = this.temporarySteps.reduce((max, step) => {
          if (! step.module_name || step.module != moduleType) return max;
          let number = step.module_name.match(regex);
          if (number === undefined) return max;
          number = parseInt(number, 10);
          return number > max ? number : max;
        }, 0);
        module.module_name = `${moduleType}${last + 1}`;

        // keep previous custom title
        let { title } = module;
        if (this.value.title && this.previousModule) {
          const oldModuleDefaultTitle = this.modulesList[this.previousModule] && this.modulesList[this.previousModule].title || "";
          if (oldModuleDefaultTitle && this.value.title != oldModuleDefaultTitle) ({ title } = this.value);
        }
        this.previousModule = this.value.module;

        Object.assign(this.value, module, { title });
        this.update();
      },
      clickHelp() {
        this.$emit('help', this.value.module);
      },
    },
  };
</script>
