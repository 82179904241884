<template>
  <v-container class="pa-0">
    <v-row>
      <v-col v-for="selectKey in selectKeys" :key="selectKey" sm="4">
        <v-select v-model="filters[selectKey]" :items="selects[selectKey]" :label="labels[selectKey]" clearable @input="applyFilter"></v-select>
      </v-col>
      <v-col md="2" sm="6">
        <date-field v-model="filters.after" label="Après le" clearable @input="applyFilter"></date-field>
      </v-col>
      <v-col md="2" sm="6">
        <date-field v-model="filters.before" label="Avant le" clearable @input="applyFilter"></date-field>
      </v-col>
      <v-col :class="{ 'justify-end': $vuetify.breakpoint.mdAndUp }" md="8" sm="12" class="display-flex align-center">
        <v-btn :color="filters.mine ? 'accent' : 'white'" small class="ma-2" @click="meFilter">
          Mes actions
        </v-btn>
        <v-btn :color="filters.dueWeek ? 'accent' : 'white'" small class="ma-2" @click="weekDueFilter">
          Echéance avant la fin de semaine
        </v-btn>
        <v-btn :color="filters.dueMonth ? 'accent' : 'white'" small class="ma-2" @click="monthDueFilter">
          Echéance avant la fin du mois
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import apiSrv from '@/services/apiSrv';
  import Meeting from '@/models/Meeting';
  import DateField from '@/components/Reusables/DateField';

  export default {
    components: {
      DateField,
    },
    data() {
      return {
        me: {},
        selects: { user: [], meeting: [], category: [] },
        filters: {
          user: null,
          meeting: null,
          category: null,
          after: null,
          before: null,
        },
        labels: {
          user: "Filtrer par utilisateurs",
          meeting: "Filtrer par réunion",
          category: "Filtrer par suite de réunions",
        },
      };
    },
    computed: {
      filtersFunc() {
        return {
          user: action => (this.filters.user == null || action.user_id == this.filters.user),
          meeting: action => (this.filters.meeting == null || action.meeting_id == this.filters.meeting),
          category: action => (this.filters.category == null || this.filters.category.indexOf(action.meeting_id)),
          after: action => (this.filters.after == null || action.due_date && moment(action.due_date).format() > this.filters.after),
          before: action => (this.filters.before == null || action.due_date && moment(action.due_date).format() < this.filters.before),
        };
      },
      selectKeys() { return Object.keys(this.selects); },
      displayedAfter: {
        get() {
          if (! this.filters.after) return null;
          return moment(this.filters.after).format("LL");
        },
        set() {},
      },
      displayedBefore: {
        get() {
          if (! this.filters.before) return null;
          return moment(this.filters.before).format("LL");
        },
        set() {},
      },
    },
    watch: {},
    created() {
      this.$store.state.users.userPromise.then((currentUser) => {
        this.me = currentUser;
        this.selects.user = this.$store.getters['users/getCompanyUsers'].map(user => ({ text: this.$store.getters['users/getUsername'](user), value: user.id }));
        this.selects.user.unshift({ text: "Tous les utilisateurs", value: null });
      });
      Meeting.getList().then((response) => {
        if (! response || ! response.data || ! response.data.meetings) throw Error("Meeting GET list doesn't receive the datas expected");
        const allMeetings = response.data.meetings;
        this.selects.meeting = allMeetings.map(meeting => ({ text: meeting.title, value: meeting.id }));
        this.selects.meeting.unshift({ text: "Toutes les réunions", value: null });
        apiSrv.call('meetings/categories', 'index', null).then((categoryresponse) => {
          if (! categoryresponse || ! categoryresponse.data || ! categoryresponse.data.categories) throw Error("Categories GET list doesn't receive the datas expected");
          this.selects.category = categoryresponse.data.categories.map(category => ({ text: category, value: allMeetings.filter(meeting => meeting.category == category) }));
          this.selects.category.unshift({ text: "Toutes les suites de réunions", value: null });
        });
      });
    },
    methods: {
      applyFilter() {
        const keys = Object.keys(this.filters).filter(key => this.filters[key] !== null);
        const allFilters = action => keys.reduce((prevRet, key) => (prevRet && this.filtersFunc[key] && this.filtersFunc[key](action)), true);
        this.$emit('update-filter', allFilters);
      },
      meFilter() {
        this.filters.user = this.me.id;
        this.applyFilter();
      },
      monthDueFilter() {
        this.filters.before = moment().endOf('month').format();
        this.applyFilter();
      },
      weekDueFilter() {
        this.filters.before = moment().endOf('week').format();
        this.applyFilter();
      },
    },
  };
</script>
