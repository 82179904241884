function ucfirstFilter(input) {
  return typeof input == 'string' ? input.split(' ').map(ch => ch.charAt(0).toUpperCase() + ch.substring(1)).join(' ') : input;
}

function username(user, format) {
  if (! user) return "";
  const firstname = user.firstname || "";
  const lastname = user.lastname || "";

  if (format == 'short') {
    if (! firstname && ! lastname) return user.username || user.email || "";
    const firstnameInitials = (firstname.match(/(^|\s|-)[A-zÀ-ú]/g) || [firstname.charAt(0)]).map(i => i.replace(/\s|-/, '')).join("").toUpperCase();
    return (firstnameInitials ? (`${firstnameInitials}. `) : "") + ucfirstFilter(lastname.toLowerCase());
  }
  if (user.username) return user.username;
  return (firstname || lastname) ? `${ucfirstFilter(firstname.toLowerCase())} ${lastname.toUpperCase()}` : user.email;
}

function getLang() {
  return 'fr'; // localStorage.getItem('lang') || 'en';
}

function momentFilter(date, format, useDefaultLocale) {
  const momentdate = moment(date);
  if (! useDefaultLocale) momentdate.locale(getLang());
  if (format == 'calendar') {
    return momentdate.calendar();
  }
  if (format == 'time') {
    return momentdate.format('LT');
  }
  if (format == 'mediumDate') {
    return momentdate.format('ll');
  }
  if (format == 'date') {
    return momentdate.format('LL');
  }
  if (format == 'calendarOrDatetime') {
    if (Math.abs(moment().diff(date, 'days')) < 6) return momentdate.calendar();
    format = 'datetime';
  }
  const customformat = (momentdate.localeData().custom ? momentdate.localeData().custom(format) : format) || format || '';
  return momentdate.format(customformat);
}

function mimeIcon(mime) {
  if (! mime) return 'far fa-file';
  if (mime == 'link') return 'far fa-file-import';
  return mime.indexOf('image') === 0 ? 'far fa-image' : mime.indexOf('video') === 0 ? 'fas fa-video' : mime.indexOf('audio') === 0 ? 'fas fa-music' : mime.indexOf('application/pdf') === 0 ? 'far fa-file-pdf' : mime.indexOf('spreadsheet') > -1 ? 'far fa-file-excel' : mime.indexOf('excel') > -1 ? 'far fa-file-excel' : mime.indexOf('word') > -1 ? 'far fa-file-word' : mime.indexOf('zip') > -1 ? 'far fa-file-archive' : 'far fa-file';
}

function uppercase(str) {
  return str && str.toUpperCase();
}

function lowercase(str) {
  return str && str.toLowerCase();
}

function ucfirst(str) {
  return str && ucfirstFilter(str);
}

function lowercaseUcfirst(str) {
  return str && ucfirst(lowercase(str));
}

const numeral = require('numeral');
require('numeral/locales/fr');

function number(nb, format) {
  numeral.locale(getLang());
  return numeral(nb).format(format || '0,0');
}

function truncate(input, length = input.length, suffix = '', preserve = false) {
  if ((typeof input != 'string') || (input.length <= length)) return input;
  return input.substring(0, (preserve) ? ((input.indexOf(' ', length) === -1) ? input.length : input.indexOf(' ', length)) : length) + suffix;
}

export default {
  username,
  moment: momentFilter,
  mimeIcon,
  uppercase,
  lowercase,
  ucfirst,
  lowercaseUcfirst,
  number,
  truncate,
};
