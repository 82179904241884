<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <meeting-details content="title"></meeting-details>
      </v-col>
      <v-col cols="12">
        <v-divider color="grey"></v-divider>
      </v-col>
      <v-col cols="12" class="display-flex">
        <div style="width: 32px; flex: 0 0 auto"><v-icon small>fa-user</v-icon></div>
        <meeting-details content="aboutme" style="flex: 1 1 100%"></meeting-details>
      </v-col>
      <v-col cols="12" class="display-flex">
        <div style="width: 32px; flex: 0 0 auto"><v-icon small>fa-calendar-alt</v-icon></div>
        <meeting-details content="date" style="flex: 1 1 100%"></meeting-details>
      </v-col>
      <v-col cols="12" class="display-flex">
        <div style="width: 32px; flex: 0 0 auto">
          <v-icon small>fas {{ (meeting.location_address || '').startsWith('http') ? 'fa-webcam' : 'fa-map-marker-alt' }}</v-icon>
        </div>
        <meeting-details content="place" style="flex: 1 1 100%"></meeting-details>
      </v-col>
      <v-col cols="12">
        <v-divider color="grey" style="margin-left: 32px"></v-divider>
      </v-col>
      <v-col cols="12" class="display-flex">
        <div style="width: 32px; flex: 0 0 auto"><v-icon small>fa-users</v-icon></div>
        <meeting-details content="attendees" style="flex: 1 1 100%"></meeting-details>
      </v-col>
      <v-col cols="12">
        <v-divider color="grey" style="margin-left: 32px"></v-divider>
      </v-col>
      <v-col cols="12" class="display-flex">
        <div style="width: 32px; flex: 0 0 auto"><v-icon small>far fa-list-alt</v-icon></div>
        <meeting-details content="description" style="flex: 1 1 100%"></meeting-details>
      </v-col>
      <template v-if="isMeetingAdmin && meetingStatusIndex >= 2">
        <v-col cols="12">
          <v-divider color="grey" style="margin-left: 32px"></v-divider>
        </v-col>
        <v-col cols="12" class="display-flex">
          <div style="width: 32px; flex: 0 0 auto"><v-icon small>fas fa-cog</v-icon></div>
          <div style="flex: 1 1 100%">
            La réunion est en phase
            <span class="ml-1 primary--text"><i :class="statusItems[meetingStatusIndex].icon" class="mr-1"></i>{{ statusItems[meetingStatusIndex].text }}</span>
            <div class="mt-2 mb-1">Revenir à une phase précédente :</div>
            <div class="display-flex align-center">
              <v-select v-model="newStatusSelected" :items="previousStatusAvailable" hide-details class="pt-0 mt-0 mr-4" style="flex: 0 1 150px"></v-select>
              <v-btn :disabled="! newStatusSelected" color="accent" rounded small @click="changeStatus">Valider</v-btn>
            </div>
          </div>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import Meeting from '@/models/Meeting';
  import MeetingDetails from '@/components/MeetingDetails/MeetingDetails';

  export default {
    components: { MeetingDetails },
    data() {
      return {
        participation: 0,
        participation_choice: { yes: 'Oui', no: 'Non', maybe: 'Peut-être' },
        statusItems: Meeting.getStatusItems(),
        newStatusSelected: null,
      };
    },
    computed: {
      meetingStatusIndex() {
        return Meeting.statusIndex(this.meeting.status || 'preparation');
      },
      previousStatusAvailable() {
        return this.statusItems.slice(1, this.meetingStatusIndex).reverse();
      },
      ...mapState('current', ['meeting']),
      ...mapGetters('current', ['isMeetingAdmin']),
    },
    methods: {
      changeStatus() {
        if (! this.newStatusSelected || this.newStatusSelected == this.meeting.status) return;
        const newStatus = this.newStatusSelected;
        this.newStatusSelected = null;
        window.apiSrv.call(`meetings/${this.meeting.id}/status`, 'update', { status: newStatus }).then(() => {
          this.meeting.status = newStatus;
          const routeName = { preparation: 'Preparation', animation: 'Animation', minutes: 'Minutes' }[this.meeting.status];
          if (routeName) this.$router.push({ name: routeName });
        }).catch((error) => {
          if (error.message) this.$store.dispatch('ui/msgbox/open', { title: `${this.$t("GLOBAL.ERROR")}`, body: error.message });
        });
      },
    },
  };
</script>
