<template>
  <modal-with-background
    v-if="currentPageId > -1" :is-open="currentPageId > -1" :max-width="1600"
    :title="pages[currentPageId] && pages[currentPageId].title" bg-color="cream-pixel-bg"
    @modal-close="$emit('close')">
    <component :is="'on-boarding-' + pages[currentPageId].component" :module-id="pages[currentPageId].module"></component>
    <template #footer>
      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-btn v-if="currentPageId" text color="secondary" class="px-2 justify-start" style="min-width: 100px" @click="currentPageId = currentPageId - 1">
            <v-icon medium>fas fa-chevron-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="4" class="text-center" style="line-height: 2">
          <v-tooltip v-for="(pageData, index) in pages" :key="index" top>
            <template #activator="{ on }">
              <v-btn color="primary" icon class="mx-1" x-small v-on="on" @click="currentPageId = index">
                <v-icon :color="currentPageId == index ? 'primary' : 'secondary'">fas fa-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ pageData.title }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="4" class="display-flex justify-end">
          <v-btn v-if="currentPageId < pages.length - 1" text color="primary" class="px-2 justify-end" style="min-width: 200px" @click="currentPageId = currentPageId + 1">
            <span>{{ pages[currentPageId + 1].title }}</span>
            <v-icon class="ml-2" medium>fas fa-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal-with-background>
</template>

<style>
  .onboarding-card-title {
    font-size: 20px;
    line-height: normal;
    color: #ff9b1d;
    margin-bottom: 12px;
  }
</style>

<script>
  import OnBoardingPhasesCycle from './OnBoardingPhasesCycle';
  import OnBoardingPhases from './OnBoardingPhases';
  import OnBoardingModules from './OnBoardingModules';
  import OnBoardingMeetingSteps from './OnBoardingMeetingSteps';

  export default {
    components: {
      OnBoardingPhasesCycle,
      OnBoardingPhases,
      OnBoardingModules,
      OnBoardingMeetingSteps,
    },
    props: {
      startPage: { type: Number, default: -1 }, // -1 -> hidden
      config: { type: String, default: 'modules' },
    },
    data() {
      return {
        currentPageId: this.startPage,
      };
    },
    computed: {
      pages() {
        if (this.config == 'phases') {
          return [
            { title: "Les phases de réunion", component: "phases-cycle" },
            { title: "Détail des phases de réunion", component: "phases" },
          ];
        }

        return [
          { title: "Le déroulé de réunion", component: "meeting-steps" },
          { title: "Module : Présentation par l’organisateur", component: "modules", module: "news" },
          { title: "Module : Tour de table des actus", component: "modules", module: "moods" },
          { title: "Module : Sujets libres", component: "modules", module: "topics" },
          { title: "Module : Points d'attention et prise de décision", component: "modules", module: "issues" },
          { title: "Module : Revue des actions en cours", component: "modules", module: "actionsevolution" },
          { title: "Module : Revue des indicateurs", component: "modules", module: "kpis" },
          { title: "Module : Tour de table d’évaluation", component: "modules", module: "reviews" },
        ];
      },
    },
    watch: {
      startPage(newVal) {
        this.currentPageId = newVal;
      },
    },
  };
</script>
