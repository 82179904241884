<template>
  <div>
    <div :style="{ 'max-height': (linkInfo || linkField) ? '100px' : '0px' }" class="py-2" style="width: 100%; position: relative; transition: max-height 0.3s ease-out">
      <span v-if="linkInfo" class="primary--text">Veuillez sélectionner le texte pour lui ajouter un lien.</span>
      <v-form v-if="linkField" style="height: 60px; width: 100%" @submit.prevent="linkSubmit()">
        <v-text-field
          v-model="linkUrl" autofocus label="adresse du lien" persistent-hint hint="Pressez Entrée pour valider le lien"
          append-icon="fa fa-times" @click:append="linkField = false">
        </v-text-field>
      </v-form>
    </div>
    <vue-editor
      ref="editor" :value="value" :editor-options="editorSettings" :placeholder="placeholder"
      @focus="linkField = false" @input="changeInput($event)">
    </vue-editor>
  </div>
</template>

<style>
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]:before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]:before {
    content : "Petit" !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]:before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]:before {
    content : "Très Large" !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before, .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]:before {
    content : "Titre 1" !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before, .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]:before {
    content : "Titre 2" !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before, .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]:before {
    content : "Titre 3" !important;
  }.ql-snow .ql-picker.ql-header .ql-picker-item:before, .ql-snow .ql-picker.ql-header .ql-picker-label:before {
    content : "Paragraphe" !important;
  }
  .ql-snow .ql-picker.ql-header{
    width: 110px !important;
  }
</style>

<script>
  import { VueEditor } from 'vue2-editor';

  export default {
    components: {
      VueEditor,
    },
    model: {
      prop: 'value',
      event: 'input',
    },
    props: {
      value: { type: String, required: true },
      placeholder: { type: String, default: 'Saisissez votre texte ici' },
      listBtns: { type: Boolean, default: true },
      alignBtns: { type: Boolean, default: true },
      colorBtns: { type: Boolean, default: true },
      headersBtns: { type: Boolean, default: true },
      textFormatBtns: { type: Boolean, default: true },
      sizeBtns: { type: Boolean, default: true },
      linksBtns: { type: Boolean, default: true },
      imagesBtns: { type: Boolean, default: true },
    },
    data() {
      return {
        editorSettings: {},
        customToolbar: [],
        linkInfo: false,
        linkField: false,
        linkUrl: '',
        linkSubmit() {},
        firstVal: '',
      };
    },
    created() {
      this.firstVal = this.value;
      const container = [];
      if (this.headersBtns) { container.push([{ header: [1, 2, 3, false] }]); }
      if (this.textFormatBtns) { container.push([{ bold: 'g' }, 'italic', 'underline']); }
      if (this.alignBtns) { container.push([{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }]); }
      if (this.listBtns) { container.push([{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }]); }
      if (this.colorBtns) { container.push([{ color: [] }, { background: [] }]); }
      if (this.linksBtns) { container.push(['link']); }
      if (this.imagesBtns) { container.push(['image']); }
      if (this.sizeBtns) { container.push([{ size: ['small', false, 'large', 'huge'] }]); }
      const vm = this;
      const linktip = (value) => {
        const selection = window.getSelection();
        const checkSelection = selection
          && selection.type == "Range"
          && selection.anchorNode;
        if (! checkSelection) {
          vm.linkInfo = true;
          setTimeout(() => {
            vm.linkInfo = false;
          }, 3000);
        } else if (value) {
          vm.linkField = true;
          const { quill } = vm.$refs.editor;
          vm.linkSubmit = () => {
            quill.format('link', this.linkUrl);
            vm.linkField = false;
            this.linkUrl = "";
          };
        } else {
          this.quill.format('link', false);
        }
      };
      this.editorSettings = {
        modules: {
          toolbar: {
            container, // Selector for toolbar container
            handlers: {
              link: linktip,
            },
          },
        },
      };
    },
    methods: {
      changeInput(val) {
        // to avoid first input signal from vue-editor
        if (val && val != this.firstVal) {
          this.$emit('input', val);
        }
        this.linkField = false;
      },
    },
  };
</script>
