<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="12">
        <v-card class="grey lighten-3 elevation-0 pa-4">
          <v-row>
            <v-col>
              <v-text-field v-model="userKpis.title" label="Titre"> </v-text-field>
            </v-col>
            <!--  <v-flex sm6>
            <v-select :items="monthSelect" v-model="dateKpi" label="Select"></v-select>
            </v-flex> -->
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="grey lighten-3 elevation-0 pa-4">
          <v-row class="pa-4">
            <v-col v-for="(kpi, index) in userKpis.data" :key="index" :class="'col-sm-' + kpi.cols" class="my-2" style="border: white 2px solid; position: relative;">
              <v-row>
                <v-col cols="8">
                  <v-select
                    v-model="kpi.type" :items="typeSelect" item-value="value" item-text="title" hide-details
                    append-icon="fas fa-caret-down" label="Type" @input="saveModule"></v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="kpi.cols" :items="colSelect" item-value="value" item-text="text" hide-details
                    append-icon="fas fa-caret-down" label="Largeur" @change="saveModule"></v-select>
                </v-col>
                <v-col cols="12" class="text-center">
                  <button
                    v-for="(weather, weatherIndex) in weatherList" :key="weatherIndex" type="button"
                    :class="kpi.mood == weather ? 'darken-2 selected' : null" class="mx-2 accent weather-button"
                    @click="kpi.mood = kpi.mood == weather ? null : weather; saveModule()">
                    <div class="white contain-button">
                      <div :class="'mood-icon ' + weather"></div>
                    </div>
                  </button>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-if="kpi.type == 'text'" v-model="kpi.value" class="white ma-1 px-1" rows="4" label="Description" style="width: auto" hide-details @change="saveModule">
                  </v-textarea>
                  <div v-if="kpi.type == 'image'" class="text-center">
                    <img v-if="kpi.url" :src="kpi.url" style="max-width: 100%">
                    <file-upload
                      :input-id="`file-kpi-${index}`" image-only class="py-4"
                      @uploaded="addPicture(kpi, $event.response && $event.response.file)">
                      Faites glisser ou cliquez pour  {{ kpi.url ? 'changer l\'image' : 'ajouter une image' }}
                    </file-upload>
                  </div>
                </v-col>
              </v-row>
              <div class="text-center" style="position: absolute; top: -16px; left:0; width:100%;">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn :disabled="index == 0" small icon color="white" v-on="on" @click="shiftKpi(index, index - 1)">
                      <v-icon class="arrow-icon" color="grey" size="14">fas fa-chevron-left</v-icon>
                    </v-btn>
                  </template>
                  <span>Décaler l'indicateur vers la gauche</span>
                </v-tooltip>
                <v-btn x-small fab color="white" @click="deleteKpi(index)">
                  <v-icon color="error" size="14">fas fa-trash-alt</v-icon>
                </v-btn>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn :disabled="! userKpis.data || userKpis.data.length - 1 == index" small icon color="white" v-on="on" @click="shiftKpi(index, index + 1)">
                      <v-icon class="arrow-icon" color="grey" size="14">fas fa-chevron-right</v-icon>
                    </v-btn>
                  </template>
                  <span>Décaler l'indicateur vers la droite</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-btn color="accent" rounded class="mx-4" @click="addKpi">Ajouter un indicateur</v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .mood-icon {
    width: 40px;
    height: 40px;
  }

  .weather-button {
    outline-style: none;
    transition: border-radius .35s ease-in-out;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    height:60px;
    width: 60px;
    background: white;
    box-sizing: border-box;
  }

  .weather-button .contain-button {
    transition: border-radius .35s ease-in-out;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .weather-button:hover .contain-button, .weather-button.selected .contain-button {
    margin:5px;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    box-sizing: border-box;
  }

  .weather-button:hover, .weather-button.selected{
    border-radius: 50%;
  }

  .v-btn--disabled .v-icon.arrow-icon {
    color: #EEE !important;
  }

</style>

<script>
  import { mapState } from 'vuex';
  import FileUpload from '@/components/Reusables/FileUpload';

  export default {
    components: {
      FileUpload,
    },
    props: {
      moduleName: { required: true, type: String },
    },
    data() {
      return {
        module: {},
        userKpis: {},
        typeSelect: [
          { value: 'text', title: 'Texte' },
          { value: 'image', title: 'Image' },
        ],
        colSelect: [
          { value: '12', text: '100%' },
          { value: '9', text: '75%' },
          { value: '6', text: '50%' },
          { value: '3', text: '25%' },
        ],
        weatherList: ['sun', 'suncloud', 'cloud', 'storm'],
      };
    },
    computed: {
      dateKpi: {
        get() {
          return moment(this.userKpis.version).format("MMMM YYYY");
        },
        set(val) {
          this.userKpis.version = moment(val).format();
          this.saveModule();
        },
      },
      ...mapState('current', ['meeting']),
    },
    created() {
      const { meetingUser, meeting } = this.$store.state.current;
      this.module = meeting.getUserModule(this.moduleName, meetingUser, { title: `Indicateurs de ${this.$store.getters['users/getUsername'](meetingUser)}` });
      this.userKpis = this.module.content;
      if (! this.module.content.data) this.$set(this.module.content, 'data', []);
      if (! this.userKpis.data.length) { return; }
      for (let i = this.userKpis.data.length - 1; i >= 0; i--) {
        this.userKpis.data[i].cols = this.userKpis.data[i].cols == '8' ? '9' : this.userKpis.data[i].cols;
        if (! this.userKpis.data[i].mood) this.$set(this.userKpis.data[i], 'mood', null);
      }
      // let endDate = moment().month(1).year(2016)
      // let date = moment().startOf('month')
      // while (date > endDate) {
      //   this.monthSelect.push(date.format("MMMM YYYY"))
      //   date = date.subtract(1, 'month').startOf('month')
      // }
    },
    methods: {
      addKpi() {
        this.userKpis.data.push({ cols: '6', type: 'text', mood: '' });
        this.saveModule();
      },
      deleteKpi(index) {
        this.userKpis.data.splice(index, 1);
        this.saveModule();
      },
      shiftKpi(index, indexToReach) {
        const tmp = this.userKpis.data && this.userKpis.data[indexToReach];
        if (! tmp) return;
        const copy = { ...this.userKpis };
        copy.data[indexToReach] = copy.data[index];
        copy.data[index] = tmp;
        this.userKpis = copy;
      },
      addPicture(kpi, file) {
        this.$set(kpi, 'url', file.url);
        this.saveModule();
      },
      saveModule: _.debounce(function () { this.meeting.sendModule(this.module); }, 700),
    },
  };
</script>
