<template>
  <v-container class="pa-0">
    <div class="display-flex flex-column">
      <text-editor v-model="meeting.summary" class="ma-2" placeholder="Compte rendu" @input="save()"></text-editor>
      <div class="text-center">
        <v-btn :to="{ name: 'Minutes' }" rounded class="ma-0 white--text accent darken-1" @click="$emit('close-drawer')">Compléter le compte rendu</v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
  import TextEditor from '@/components/Reusables/TextEditor';

  export default {
    components: {
      TextEditor,
    },
    computed: {
      meeting() { return this.$store.state.current.meeting; },
    },
    created() {
      if (! this.meeting.summary) this.$set(this.meeting, 'summary', "");
    },
    methods: {
      save: _.debounce(function () { this.meeting.saveFields('summary'); }, 700),
    },
  };
</script>
