<template>
  <div class="mt-2" style="max-width: 500px">
    <template v-if="! checkoutSuccess">
      <v-simple-table v-if="plan !== 'NEWCARD'" dense class="ml-2">
        <tr>
          <td class="py-1">Abonnement mensuel {{ quantity }} organisateur{{ quantity > 1 ? 's' : '' }}</td>
          <td>{{ numberFormat(quantity * price) }}</td>
        </tr>
        <tr>
          <td class="py-1">TVA 20%</td>
          <td>{{ numberFormat(quantity * price * .2, 2, ",", " ") }}</td>
        </tr>
        <tr>
          <td class="py-1">Total TTC mensuel</td>
          <td>{{ numberFormat(quantity * price * 1.2, 2, ",", " ") }}</td>
        </tr>
        <tr v-if="trialDays">
          <td colspan="2" class="py-1">Premier prélèvement le {{ firstInvoiceDate }} ({{ trialDays }} jours d'essai)</td>
        </tr>
      </v-simple-table>
      <v-card color="grey lighten-5" class="ml-6 mt-4 pa-6 pt-2">
        <div class="display-flex align-center justify-center mt-2">
          <div class="credit-card-button"><img src="./img/card-visa-logo.svg" alt="visa" style="height:30px"></div>
          <div class="credit-card-button"><img src="./img/card-mastercard-logo.svg" alt="mastercard" style="height:30px"></div>
          <div class="credit-card-button"><img src="./img/card-maestro-logo.svg" alt="maestro" style="height:30px"></div>
          <div class="credit-card-button"><img src="./img/card-amex-logo.svg" alt="american express" style="height:30px"></div>
        </div>
        <input type="hidden" name="quantity" :value="quantity">
        <input type="hidden" name="plan" :value="plan">
        <div class="mt-2">
          <div class="mb-1"><label for="number">Numéro de carte</label></div>
          <div id="number" ref="number" class="payform-input"></div>
        </div>
        <div class="mt-2">
          <div class="mb-1"><label for="cardholder-name">Nom du titulaire</label></div>
          <input id="cardholder-name" v-model="cardholderName" type="text" size="20" placeholder="Jean Durand" class="payform-input">
        </div>
        <div class="mt-2">
          <div class="mb-1"><label for="exp">Expire fin</label></div>
          <div id="exp" ref="exp" class="payform-input" style="width: 180px;"></div>
        </div>
        <div class="mt-2">
          <div class="mb-1"><label for="cvc" title="Les 3 derniers chiffres apparaissant sur le panneau signature au verso de votre carte bancaire.">Cryptogramme visuel</label></div>
          <div id="cvc" ref="cvc" class="payform-input" style="width: 65px"></div>
        </div>
        <div v-if="paymentErrors" class="my-2 error--text">{{ paymentErrors }}</div>
        <v-btn v-if="stripe" :loading="loading" color="success" block rounded class="mt-4 text-uppercase" @click="subscribe">
          {{ plan == 'NEWCARD' ? 'Ajouter la carte' : 'Souscrire l\'abonnement' }}
        </v-btn>
      </v-card>
    </template>
    <v-fade-transition>
      <div v-if="checkoutSuccess">
        <p style="font-weight: bold;font-size: 32px;margin: 15px 0;">Merci</p>
        <p>Votre compte Premium est maintenant activé.<br>Pensez à compléter vos coordonnées de facturation.</p>
        <v-btn :href="`https://bubbleplan.net/auth.php?token=${api_token}&redirect=mon-compte%23company`" target="_blank" text color="#0169aa">
          Compléter le compte entreprise
        </v-btn>
      </div>
    </v-fade-transition>
  </div>
</template>

<style scoped>
  .credit-card-button {
    background-color: #fff;
    border-radius: .3125rem;
    -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
    display: inline-flex;
    margin: .1875rem .375rem;
    padding: .375rem .1875rem;
  }

  .payform-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(2.53125rem + 2px);
    padding: .375rem .75rem;
    font-size: .9375rem;
    line-height: 1.9;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #eaeff4;
    border-radius: 4px;
    transition: all .3s ease-out;
    outline: none;
  }

  .payform-input::placeholder {
    color: #ccc !important;
  }
</style>

<script>
  // import { loadStripe } from '@stripe/stripe-js';
  import apiSrv from '@/services/apiSrv';
  import debugSrv from '@/services/debugSrv';

  export default {
    props: {
      plan: { type: String, required: true },
      quantity: { type: Number, required: true },
      coupon: { type: String, default: '' },
    },
    data() {
      return {
        price: this.quantity <= 1 ? 49 : 35,
        stripe: null,
        elements: null,
        number: null,
        cardholderName: "",
        exp: null,
        cvc: null,
        trialDays: 0,
        loading: false,
        paymentErrors: "",
        checkoutSuccess: false,
        stripeKey: debugSrv.debug ? 'pk_test_ZSvv85IquUi9RIVLObsc4lmw' : 'pk_live_WzXkaTeYtwqEh1PUC357fQus',
      };
    },
    computed: {
      firstInvoiceDate() {
        return moment().add(this.trialDays, 'days').format('d/m/Y');
      },
      api_token() {
        return apiSrv.getAuthToken();
      },
    },
    async mounted() {
      const { loadStripe } = await require('@stripe/stripe-js');
      loadStripe(this.stripeKey).then((stripe) => {
        this.stripe = stripe;
        this.elements = stripe.elements();
        const style = { base: { '::placeholder': { color: '#ccc' } } };

        this.number = this.elements.create('cardNumber', { style });
        this.exp = this.elements.create('cardExpiry', { style });
        this.cvc = this.elements.create('cardCvc', { placeholder: '123', style });
        setTimeout(() => {
          this.number.mount(this.$refs.number);
          this.exp.mount(this.$refs.exp);
          this.cvc.mount(this.$refs.cvc);
        });
      });
    },
    destroyed() {
      if (this.checkoutSuccess) window.location.reload();
    },
    methods: {
      numberFormat(nb) {
        if (typeof nb != 'number') return '';
        return nb.toLocaleString('fr-fr', { style: "currency", currency: 'EUR' });
      },
      subscribe() {
        this.loading = true;

        if (! this.cardholderName) {
          this.paymentErrors = "Veuillez entrer le nom du titulaire de la carte";
          this.loading = false;
          return;
        }

        let apiUrl = `buy/plan/${this.plan}/quantity/${this.quantity}`;
        if (this.plan == "NEWCARD") {
          apiUrl = 'buy/cards';
        } else if (! this.quantity || ! ['PERSONAL', 'TEAM', 'MEETING'].includes(this.plan) || (this.plan == 'PERSONAL' && this.quantity != 1) || (this.plan == 'TEAM' && this.quantity < 2)) {
          this.paymentErrors = "Erreur : l'offre selectionnée est invalide.";
          this.loading = false;
          return;
        }

        this.stripe.createToken(this.number, { name: this.cardholderName }).then((response) => {
          if (response.error) {
            this.paymentErrors = response.error.message;
            this.loading = false;
            this.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
          } else {
            const token = response.token.id;
            apiSrv.call(apiUrl, 'store', { token, coupon: this.coupon }).then(({ data } = {}) => {
              let pendingSetupIntent = data && data.subscription && data.subscription.pending_setup_intent;
              if (! pendingSetupIntent) pendingSetupIntent = data && data.subscription && data.subscription.latest_invoice && data.subscription.latest_invoice.payment_intent;
              if (pendingSetupIntent && pendingSetupIntent.status === "requires_action") {
                this.stripe.confirmCardPayment(pendingSetupIntent.client_secret).then((result) => {
                  if (result.error) {
                    this.paymentErrors = `Error : ${result.error.message}`;
                    this.loading = false;
                    this.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
                  } else {
                    // success with 3D secure
                    this.checkoutSuccess = true;
                  }
                });
                return;
              }
              this.checkoutSuccess = true;
            }).catch((data) => {
              this.paymentErrors = data || `Error : ${data.statusText}`;
              this.loading = false;
              this.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
            });
          }
        });
      },
    },
  };
</script>
