<template>
  <div>
    <v-btn :loading="publishing.inprogress" :block="block" :right="right" color="accent" rounded @click="publish()">
      <div class="display-flex align-center"><v-icon size="16" class="mr-2">fa-paper-plane</v-icon>Publier la réunion</div>
    </v-btn>
    <div style="line-height: 1.2"><i>En publiant la réunion vous enverrez les invitations.</i></div>
    <div v-if="publishing.success"><v-icon size="16" class="success--text">fa-check</v-icon> &nbsp;Réunion publiée...</div>
    <div v-if="publishing.error"><v-icon size="16" class="error--text">fa-exclamation-triangle</v-icon> &nbsp;{{ publishing.error }}</div>
  </div>
</template>

<script>
  export default {
    props: {
      block: { type: Boolean, default: false },
      right: { type: Boolean, default: false },
    },
    data() {
      return {
        publishing: { inprogress: false, success: false, error: "" },
      };
    },
    computed: {
    },
    methods: {
      publish() {
        const { meeting } = this.$store.state.current;
        if (this.publishing.inprogress) return Promise.reject();
        if (meeting.status && meeting.status != 'draft') {
          meeting.savingProgress.error = "Cette réunion n'est plus un brouillon. Rafraichissez la page pour éviter cette erreur.";
          return Promise.reject();
        }
        this.publishing = { inprogress: true, success: false, error: "" };
        if (! meeting.title) {
          const { meetingUser } = this.$store.state.current;
          const username = this.$store.getters['users/getUsername'](meetingUser);
          meeting.title = `Réunion de ${username}`;
        }
        return meeting.saveFields({ status: 'preparation' }, true).then(() => {
          meeting.status = 'preparation';
          this.publishing.inprogress = false;
          this.publishing.success = true;
          this.$router.push({ name: 'Home' });
        }).catch((error) => {
          this.publishing.inprogress = false;
          this.publishing.error = error.message;
        });
      },
    },
  };
</script>
