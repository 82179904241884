<template>
  <div v-if="meeting.decisions && meeting.decisions.length">
    <strong>Arbitrages rendus :</strong>
    <ul>
      <li v-for="issue in meeting.decisions" :key="issue.id">
        <span>{{ issue.title }} -> {{ issue.decision }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      meeting: { type: Object, required: true },
    },
  };
</script>
