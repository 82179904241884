<template>
  <div style="position: relative;">
    <svg width="184" height="184" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(92,92)">
        <g transform="rotate(-90)">
          <circle id="secProgress" :style="{ stroke: secondsBaseColor }" r="72" opacity="0.7" />
          <circle id="minProgress" transform="scale(1, -1)" r="82" class="minCircle" />
        </g>
      </g>
    </svg>
    <div class="text-center" style="position: absolute; width: 100%; top: 50px;">
      <div :class="isOverTime ? 'error--text' : 'secondary--text'" class="display-remain-time" style="display: flex">
        <span class="text-right" style="flex: 1 1 100%">{{ stringMinutes }}</span>
        <span class="mx-1">:</span>
        <span class="text-left" style="flex: 1 1 100%">{{ stringSeconds }}</span>
      </div>
      <div class="mt-2"></div>
      <v-btn
        :disabled="disableButtons" :style="{ visibility: isPlaying ? 'hidden' : '', opacity: isPlaying ? 0 : '' }"
        title="Redémarrer le timer" icon class="ma-0"
        @click="stop(); $emit('timer-play');">
        <v-icon color="grey">fas fa-history</v-icon>
      </v-btn>
      <v-btn
        :disabled="disableButtons" :title="isPlaying ? 'Mettre en pause' : 'Démarrer'" icon class="ma-0"
        @click="isPlaying ? pause() : resumeOrPlay()">
        <v-icon color="grey">fas {{ isPlaying ? 'fa-pause' : 'fa-play' }}</v-icon>
      </v-btn>
      <v-btn
        :disabled="disableButtons" icon class="ma-0" title="Valider"
        @click="validate">
        <v-icon color="grey">fas fa-check</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style>
  #secProgress {
    fill: none;
    stroke-width: 4px;
  }

  .minCircle{
    fill: none;
    stroke: #333;
    stroke-width: 5px;
  }

  #minProgress{
    stroke: #9e9e9e;
  }

  .display-remain-time {
    font-family: 'Comfortaa', 'sans-serif';
    font-weight: 800;
    font-size: 35px;
    line-height: 35px;
  }

  #pause:hover { opacity: 0.8; }
</style>

<script>
  export default {
    props: {
      minutes: { required: true, type: Number },
      disableButtons: { type: Boolean, default: false },
    },
    data() {
      return {
        secondsInMin: 60,
        secProgressBar: 0,
        secProgressLength: 0,
        minProgressBar: 0,
        minProgressLength: 0,
        minDashArray: 0,
        minDashSpace: 5,
        minCircles: null,
        startTime: 0,
        previousTime: 0,
        isPlaying: false,
        intervalTimer: null,
        intervalBlink: null,
        stringMinutes: "00",
        stringSeconds: "00",
        isOverTime: false,
        secondsBaseColor: "#ff9b1d",
      };
    },
    watch: {
      isOverTime(value) {
        clearInterval(this.intervalBlink);
        if (value) {
          this.intervalBlink = setInterval(() => {
            this.secondsBaseColor = this.secondsBaseColor == "#FFF" ? "#f82223" : "#FFF";
          }, 500);
        }
      },
      minutes() {
        this.minProgressBar.style.strokeDasharray = `${this.minDashArray} ${this.minDashSpace}`;
        for (let i = this.minCircles.length - 1; i >= 0; i--) {
          this.minCircles[i].style.strokeDasharray = `${this.minDashArray} ${this.minDashSpace}`;
        }
        this.fillCircles();
      },
    },
    mounted() {
      this.minProgressBar = document.getElementById('minProgress');
      this.secProgressBar = document.getElementById('secProgress');
      this.secProgressLength = Math.PI * 2 * this.secProgressBar.getAttribute('r');
      this.minProgressLength = Math.PI * 2 * this.minProgressBar.getAttribute('r');
      this.minCircles = document.getElementsByClassName('minCircle');
      this.secProgressBar.style.strokeDasharray = this.secProgressLength;
      this.secProgressBar.style.transition = "opacity 1s, stroke-dashoffset 1s linear";
      this.fillCircles();
    },
    methods: {
      elapsedTime() {
        return Math.floor((Date.now() - this.startTime) / 1000);
      },
      timeLeft() {
        const timeLeft = (this.minutes * this.secondsInMin) - this.elapsedTime();
        const minutes = Math.floor(Math.abs(timeLeft) / this.secondsInMin) || 0;
        const seconds = Math.abs(Math.round(timeLeft) % this.secondsInMin);
        this.isOverTime = timeLeft < 0;
        return { minutes, seconds };
      },
      updateSeconds() {
        const { seconds } = this.timeLeft();
        const leftSeconds = this.isOverTime ? 0 : seconds;
        this.secProgressBar.style.strokeDashoffset = -this.secProgressLength - (this.secProgressLength * ((leftSeconds / this.secondsInMin) || 1));
      },
      endAMinute() {
        const { minutes } = this.timeLeft();
        const minutesLeft = this.isOverTime ? 0 : minutes;
        let dashArray = "";
        for (let i = this.minutes - minutesLeft; i > 0; i--) {
          dashArray = `${dashArray} 0, ${this.minDashArray + this.minDashSpace},`;
        }
        for (let i = minutesLeft; i > 0; i--) {
          dashArray = `${dashArray} ${this.minDashArray}, ${this.minDashSpace},`;
        }
        this.minProgressBar.style.strokeDasharray = dashArray.slice(0, -1);
      },
      fillCircles() {
        this.minDashArray = this.minProgressLength / this.minutes - this.minDashSpace;
        this.minProgressBar.style.strokeDasharray = `${this.minDashArray} ${this.minDashSpace}`;
        this.secondsBaseColor = "#ff9b1d";
        this.stringMinutes = `${this.minutes < 10 ? '0' : ''}${this.minutes}`;
        this.stringSeconds = '00';
        this.secProgressBar.style.strokeDashoffset = -this.secProgressLength * 2;
      },
      play() {
        if (! this.previousTime) this.fillCircles();
        this.startTime = Date.now() - this.previousTime;
        this.startTimer();
        this.isPlaying = true;
      },
      pause() {
        clearInterval(this.intervalTimer);
        this.previousTime = this.elapsedTime() * 1000;
        this.isPlaying = false;
      },
      resumeOrPlay() {
        if (this.previousTime) { // resume
          this.play();
        } else {
          this.$emit('timer-play');
        }
      },
      validate() {
        this.$emit('timer-validate');
      },
      stop() {
        clearInterval(this.intervalTimer);
        clearInterval(this.intervalBlink);
        this.isOverTime = false;
        this.previousTime = 0;
        this.isPlaying = false;
      },
      reset() {
        this.fillCircles();
      },
      startTimer() {
        this.displayTimeLeft();
        clearInterval(this.intervalTimer);
        this.intervalTimer = setInterval(() => {
          const { seconds: timeLeft } = this.timeLeft();
          if (timeLeft == 0) {
            this.endAMinute();
            const { transition } = this.secProgressBar.style;
            this.secProgressBar.style.transition = `stroke-dashoffset 0s`;
            this.secProgressBar.style.opacity = 0;
            setTimeout(() => {
              this.secProgressBar.style.transition = transition;
              this.secProgressBar.style.opacity = 1;
            }, 30);
          }
          this.displayTimeLeft();
        }, 1000);
      },
      displayTimeLeft() {
        const timeLeft = this.timeLeft();
        this.stringMinutes = `${timeLeft.minutes < 10 ? '0' : ''}${timeLeft.minutes}`;
        this.stringSeconds = `${timeLeft.seconds < 10 ? '0' : ''}${timeLeft.seconds}`;
        this.updateSeconds();
      },
    },
  };
</script>
