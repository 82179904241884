<template>
  <v-snackbar :value="isOpen" :timeout="-1" bottom>
    <template v-if="inprogress">
      <v-progress-circular size="25" indeterminate color="white"></v-progress-circular>
      Sauvegarde en cours...
    </template>
    <template v-else-if="success">
      <v-icon color="success">fa fa-check</v-icon>
      <div v-if="message" class="success--text">{{ message }}</div>
      <span v-else class="ml-2 success--text">Modifications enregistrées</span>
    </template>
    <template v-else-if="errorMessage">
      <span class="error--text pre">Des modifications n'ont pas été enregistrées : {{ errorMessage }}</span>
      <v-btn color="warning" text large @click="retryFailedRequests">Réessayer</v-btn>
      <v-btn color="grey" text large @click="abandonErrors">Abandonner</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  export default {
    computed: {
      loginError() {
        return this.error == 'Not logged in' || this.error == 'Session expired';
      },
      isOpen() {
        return this.inprogress || this.success || this.error && ! this.loginError || this.failedRequests.length;
      },
      errorMessage() {
        return this.failedRequests.length > 1 ? `\n${this.failedRequests.length} erreurs de sauvegarde` : this.error;
      },
      ...mapState('ui/saving', ['inprogress', 'success', 'error', 'failedRequests', 'message']),
    },
    methods: {
      ...mapActions('ui/saving', ['retryFailedRequests', 'abandonErrors']),
    },
  };
</script>
