window.localStorageWrapper = {
  clear() {
    try {
      return localStorage.clear();
    } catch (e) {
      return null;
    }
  },
  getItem(item) {
    try {
      return localStorage.getItem(item);
    } catch (e) {
      return null;
    }
  },
  setItem(item, value) {
    try {
      return localStorage.setItem(item, value);
    } catch (e) {
      return null;
    }
  },
  removeItem(item) {
    try {
      return localStorage.removeItem(item);
    } catch (e) {
      return null;
    }
  },
};

window.sessionStorageWrapper = {
  clear() {
    try {
      return sessionStorage.clear();
    } catch (e) {
      return null;
    }
  },
  getItem(item) {
    try {
      return sessionStorage.getItem(item);
    } catch (e) {
      return null;
    }
  },
  setItem(item, value) {
    try {
      return sessionStorage.setItem(item, value);
    } catch (e) {
      return null;
    }
  },
  removeItem(item) {
    try {
      return sessionStorage.removeItem(item);
    } catch (e) {
      return null;
    }
  },
};

window.safeParseJSON = function (str) {
  let decoded;
  try {
    decoded = JSON.parse(str);
  } catch (e) {}
  return decoded;
};

window.deepcopy = function (obj) {
  return JSON.parse(JSON.stringify(obj));
};

window.uuid = function () {
  return (new Date().getTime()).toString(36) + Math.random().toString(36).slice(-7);
};

window.isValidEmailAddress = function (emailAddress) {
  const pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,10}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
  return pattern.test(emailAddress);
};

window.html2text = function (html) {
  html = String(html).replace(/<br\s*[/]?>/gi, ' ');
  const el = document.createElement("div");
  el.innerHTML = html;
  return el.textContent || el.innerText || html.replace(/&nbsp;/gi, ' ').replace(/<[^>]+>/gm, '');
};

window.str2color = function (str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const c = (hash & 0x00FFFFFF).toString(16).toUpperCase();
  return "00000".substring(0, 6 - c.length) + c;
};

window.secToMin = function (seconds) {
  if (! seconds) return '';
  const min = Math.round(seconds / 60);
  const sec = Math.round(seconds % 60);
  return min ? `${window.fTime(min)}min ${window.fTime(sec)}s` : `${sec}s`;
};

window.fTime = function (num) {
  const fNum = Number(num);
  return Math.abs(fNum) < 10 ? `0${fNum}` : `${fNum}`;
};
