<template>
  <container-template>
    <template v-if="meeting.status == 'minutes' && userMinutesSteps.length">
      <div v-for="step in userMinutesSteps" :key="step.module_name">
        <component :is="'minutes-' + step.module" :module-name="step.module_name" class="mb-6"></component>
      </div>
    </template>
    <template v-if="isMeetingAdmin && ['animation', 'minutes'].includes(meeting.status)">
      <div>
        <v-row justify="space-between" class="pa-2">
          <v-col sm="3">
            <v-select
              ref="selectMeetingSection" :items="selectMeetingSectionItems" label="Ajouter la section" hide-details
              @input="addMeetingSection($event)">
            </v-select>
          </v-col>
          <v-col sm="3">
            <v-select
              ref="selectModuleContent" :items="meeting.steps" item-text="title" return-object label="Ajouter le détail du module" hide-details
              @input="addModuleContent($event)">
            </v-select>
          </v-col>
          <v-col sm="6" class="display-flex align-end justify-end">
            <v-btn color="secondary" rounded outlined @click="setDefaultMinutes">Remplir avec le compte rendu par défault</v-btn>
          </v-col>
        </v-row>

        <!-- Meeting Closure -->
        <meeting-closure ref="meeting-closure" :meeting="meeting" style="display: none;"></meeting-closure>

        <v-row>
          <v-col cols="12">
            <text-editor id="minutesEditor" v-model="meeting.summary" class="ma-2" placeholder="Compte rendu" @input="saveMinutes()"></text-editor>
          </v-col>
          <v-col cols="12" class="text-center">
            <div style="display: inline-block">
              <v-btn color="accent" block rounded @click="closeMeeting">Valider le compte rendu et clore la réunion</v-btn>
              <div><i>En clôturant la réunion vous enverrez le compte rendu aux participants.</i></div>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-snackbar v-model="isRemovingCurrentMinute" :timeout="-1" bottom>
        <b>Votre compte rendu sera effacé pour être remplacé par le compte rendu par défaut</b>
        <v-btn class="warning--text" text @click.native="confirmDefaultMinutes()">Écraser</v-btn>
        <v-btn text @click.native="isRemovingCurrentMinute = false">Annuler</v-btn>
      </v-snackbar>
    </template>
    <div v-else-if="['animation', 'minutes', 'closed'].includes(meeting.status)">
      <div v-if="['animation', 'minutes'].includes(meeting.status)" class="my-6 error--text">
        <i>Le compte rendu n'a pas été validé, et peut encore être modifié par un organisateur de la réunion.</i>
      </div>
      <meeting-details content="summary"></meeting-details>
    </div>
    <div v-else>Le compte rendu sera disponible à partir de la phase d'animation.</div>
  </container-template>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import MeetingDetails from '@/components/MeetingDetails/MeetingDetails';
  import MeetingClosure from '@/components/Closure/MeetingClosure';
  import TextEditor from '@/components/Reusables/TextEditor';
  import ContainerTemplate from '@/components/Reusables/ContainerTemplate';
  import MinutesSummaryvalidation from './MinutesSummaryvalidation';

  export default {
    components: {
      MeetingDetails,
      MeetingClosure,
      TextEditor,
      ContainerTemplate,
      MinutesSummaryvalidation,
    },
    data() {
      return {
        selectMeetingSectionItems: [],
        isRemovingCurrentMinute: false,
      };
    },
    computed: {
      userMinutesSteps() {
        return this.meeting.getUserMinutesSteps(this.meetingUser);
      },
      ...mapState('current', ['meeting', 'meetingUser']),
      ...mapGetters('current', ['isMeetingAdmin']),
    },
    created() {
      if (! this.meeting.summary) this.$set(this.meeting, 'summary', "");
      this.selectMeetingSectionItems = [
        { text: "Titre", value: 'title' },
        { text: "Participants", value: 'users', disabled: ! this.meeting.users?.length },
        { text: "Déroulé", value: 'steps', disabled: ! this.meeting.steps?.length },
        { text: "Arbitrages rendus", value: 'decisions', disabled: ! this.meeting.decisions?.length },
        { text: "Actions décidées", value: 'actions', disabled: ! this.$store.getters['actions/getByMeeting'](this.meeting.id).length },
        { text: "Points d'attention reportés", value: 'fridge', disabled: ! this.meeting.fridge?.length },
      ];
    },
    methods: {
      getSubcomponentContent(subcomponentName) {
        if (this.selectMeetingSectionItems.find(item => item.value == subcomponentName)?.disabled) return '';
        const closureRefs = this.$refs['meeting-closure'].$refs;
        if (['title', 'modules-title'].includes(subcomponentName)) return this.cleanHtml(closureRefs[subcomponentName]?.innerHTML);
        return this.cleanHtml(closureRefs[subcomponentName]?.[0]?.$el?.innerHTML);
      },
      addMeetingSection(sectionName) {
        if (! ['title', 'users', 'steps', 'decisions', 'actions', 'fridge'].includes(sectionName)) return;
        const content = this.getSubcomponentContent(sectionName);
        if (! content) return;
        this.addTextAtCursor(`${content}<br>`);
        this.$refs.selectMeetingSection.focus();
        this.saveMinutes();
      },
      addModuleContent(step) {
        const content = this.getSubcomponentContent(`module-${step.module}`);
        if (! content) return;
        this.addTextAtCursor(`${content}<br>`);
        this.$refs.selectModuleContent.focus();
        this.saveMinutes();
      },
      setDefaultMinutes() {
        const content = window.html2text(this.meeting.summary).trim();
        if (! content) {
          this.confirmDefaultMinutes();
          return;
        }
        this.isRemovingCurrentMinute = true;
      },
      confirmDefaultMinutes() {
        this.meeting.summary = this.defaultMinutes();
        this.saveMinutes();
        this.isRemovingCurrentMinute = false;
      },
      defaultMinutes() {
        const closureRefs = this.$refs['meeting-closure'].$refs;
        return Object.keys(closureRefs).map((subcomponentName) => {
          if (subcomponentName == 'module-summaryvalidation') return ''; // exclude from default minutes
          const content = this.getSubcomponentContent(subcomponentName);
          return content ? `${content}<br>` : '';
        }).join('');
      },
      cleanHtml(html) {
        if (! html) return '';
        return html.replace(/>\s+</g, '><') // remove spaces between tags
          .replace(/<!--(?!>)[\S\s]*?-->/g, '').trim(); // remove HTML comments
      },
      saveMinutes: _.debounce(function () { this.meeting.saveFields('summary'); }, 700),
      closeMeeting() {
        this.meeting.status = 'closed';
        this.meeting.saveFields('status', true);
        this.$router.push({ name: 'Home' });
      },
      addTextAtCursor(htmlText) {
        const editor = document.getElementById('minutesEditor');
        const v = window.getSelection();
        let parent = v.baseNode && v.baseNode.parentElement;
        if (! v.baseNode || ! editor.contains(parent) || editor == parent) {
          this.meeting.summary = `${this.meeting.summary}${htmlText}`;
          return;
        }
        if (parent.classList.contains('ql-editor')) {
          const text = v.baseNode.data || v.baseNode.innerHTML;
          const before = text.substr(0, v.baseOffset);
          const after = text.substr(v.baseOffset);
          let innerHTML = "";
          parent.childNodes.forEach((node) => {
            const nodeText = node.nodeName == "#text" ? node.data : node.outerHTML;
            if (node != v.baseNode) {
              innerHTML = `${innerHTML}${nodeText}`;
              return;
            }
            innerHTML = `${innerHTML}${before}${htmlText}${after}`;
          });
          parent.innerHTML = innerHTML;
          return;
        }
        let counter = 0;
        const getEditorChild = function editorChild(elem) {
          if (elem.parentElement.classList.contains('ql-editor') && counter++ < 20) return elem;
          parent = elem;
          return getEditorChild(elem.parentElement);
        };
        const storeElem = getEditorChild(parent);
        let innerHTML = "";
        storeElem.childNodes.forEach((node) => {
          const text = node.nodeName == "#text" ? node.data : node.outerHTML;
          if (node != parent) {
            innerHTML = `${innerHTML}${text}`;
            return;
          }
          innerHTML = `${innerHTML}${text}${htmlText}`;
        });
        storeElem.innerHTML = innerHTML;
      },
    },
  };
</script>
