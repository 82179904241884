<template>
  <v-container class="pa-0" style="position: relative">
    <v-row>
      <!-- MOOD -->
      <v-col cols="12">
        <div class="pa-2">
          <div class="text-h6 secondary--text display-flex">
            <i class="fas fa-fw fa-lg fa-cloud-sun mr-2"></i>Humeur générale
          </div>
          <v-divider class="my-2"></v-divider>
          <div class="pa-4 text-center">
            <button
              v-for="(weather, index) in weatherList" :key="index" :class="{ 'success selected': userMoods.mood == weather }" type="button" class="mx-2 weather-button"
              @click="userMoods.mood = userMoods.mood == weather ? null : weather; saveModule()">
              <div class="white contain-button">
                <div :class="'mood-icon ' + weather"></div>
              </div>
            </button>
          </div>
        </div>
      </v-col>
      <!-- PAST NEWS -->
      <v-col cols="12">
        <div class="pa-2">
          <div class="text-h6 secondary--text display-flex">
            <v-icon color="secondary" class="mr-2">far fa-newspaper</v-icon>Élements d'actualité à partager
          </div>
          <v-divider class="my-2"></v-divider>
          <div class="pb-4 display-flex justify-center">
            <text-editor v-model="userMoods.past_news" style="flex: 1 1 auto" @change="saveModule"></text-editor>
            <file-upload
              input-id="file-past_news" full-height image-only class="pt-4 pl-2" style="flex: 0 0 90px"
              @uploaded="addFile('past_news', $event.response && $event.response.file)">
              <div class="mt-2"><i class="fas fa-image" style="font-size: 45px; opacity: .3"></i></div>
            </file-upload>
          </div>
          <v-row v-if="userMoods && userMoods.past_news_files && userMoods.past_news_files.length" class="mr-2">
            <v-col v-for="file in userMoods.past_news_files" :key="file.id" cols="3" class="text-center" style="position:relative">
              <div style="max-height:150px; overflow: hidden"><img :src="file.url" :alt="file.display_filename" style="width: 100%;"></div>
              <h4 class="text-center">{{ file.display_filename }}</h4>
              <div style="position:absolute;top:-10px;right:-10px;">
                <v-btn fab small @click="removeFile('past_news', file)"><v-icon large color="error">cancel</v-icon></v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- TOP -->
      <v-col md="6">
        <div class="pa-2">
          <div class="text-h6 success--text display-flex">
            <v-icon color="success" class="mr-2">far fa-thumbs-up</v-icon>Top
          </div>
          <v-divider class="my-2"></v-divider>
          <div class="pb-4 display-flex justify-center">
            <text-editor v-model="userMoods.top" style="flex: 1 1 auto" @change="saveModule"></text-editor>
            <file-upload
              input-id="file-top" full-height image-only class="pt-4 pl-2" style="flex: 0 0 90px"
              @uploaded="addFile('top', $event.response && $event.response.file)">
              <div class="mt-2"><i class="fas fa-image" style="font-size: 45px; opacity: .3"></i></div>
            </file-upload>
          </div>
          <v-row v-if="userMoods && userMoods.top_files && userMoods.top_files.length">
            <v-col v-for="file in userMoods.top_files" :key="file.id" cols="6" class="text-center" style="position:relative">
              <div style="max-height:150px; overflow: hidden"><img :src="file.url" :alt="file.display_filename" style="width: 100%;"></div>
              <h4 class="text-center">{{ file.display_filename }}</h4>
              <div style="position:absolute;top:-10px;right:-10px;">
                <v-btn fab small @click="removeFile('top', file)"><v-icon large color="error">cancel</v-icon></v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- FLOP -->
      <v-col md="6">
        <div class="pa-2">
          <div class="text-h6 error--text display-flex">
            <v-icon color="error" class="mr-2">far fa-thumbs-down</v-icon>Flop
          </div>
          <v-divider class="my-2"></v-divider>
          <div class="pb-4 display-flex justify-center">
            <text-editor v-model="userMoods.flop" style="flex: 1 1 auto" @change="saveModule"></text-editor>
            <file-upload
              input-id="file-flop" full-height image-only class="pt-4 pl-2" style="flex: 0 0 90px"
              @uploaded="addFile('flop', $event.response && $event.response.file)">
              <div class="mt-2"><i class="fas fa-image" style="font-size: 45px; opacity: .3"></i></div>
            </file-upload>
          </div>
          <v-row v-if="userMoods && userMoods.flop_files && userMoods.flop_files.length" class="mr-2">
            <v-col v-for="file in userMoods.flop_files" :key="file.id" cols="6" class="text-center" style="position:relative">
              <div style="max-height:150px; overflow: hidden"><img :src="file.url" :alt="file.display_filename" style="width: 100%;"></div>
              <h4 class="text-center">{{ file.display_filename }}</h4>
              <div style="position:absolute;top:-10px;right:-10px;">
                <v-btn fab small @click="removeFile('flop', file)"><v-icon large color="error">cancel</v-icon></v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- TEAM -->
      <v-col cols="12">
        <div class="pa-2">
          <div class="text-h6 secondary--text display-flex">
            <v-icon color="secondary" class="mr-2">fas fa-user-friends</v-icon>Équipe : Arrivée(s) / Départ(s)
          </div>
          <v-divider class="my-2"></v-divider>
          <div class="pb-4 display-flex justify-center">
            <text-editor v-model="userMoods.team" style="flex: 1 1 auto" @change="saveModule()"></text-editor>
            <file-upload
              input-id="file-team" full-height image-only class="pt-4 pl-2" style="flex: 0 0 90px"
              @uploaded="addFile('team', $event.response && $event.response.file)">
              <div class="mt-2"><i class="fas fa-image" style="font-size: 45px; opacity: .3"></i></div>
            </file-upload>
          </div>
          <v-row v-if="userMoods && userMoods.team_files && userMoods.team_files.length" class="mr-2">
            <v-col v-for="file in userMoods.team_files" :key="file.id" cols="3" class="text-center" style="position: relative;">
              <div style="max-height: 150px; overflow: hidden;"><img :src="file.url" :alt="file.display_filename" style="width: 100%;"></div>
              <h4 class="text-center">{{ file.display_filename }}</h4>
              <div style="position: absolute; top: -10px; right: -10px;">
                <v-btn fab small @click="removeFile('team', file)"><v-icon large color="error">cancel</v-icon></v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .mood-icon {
    width:52px;
    height: 52px;
  }

  .weather-button {
    outline-style: none;
    transition: border-radius .35s ease-in-out;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    height:80px;
    width: 80px;
    background: white;
    box-sizing: border-box;
  }

  .weather-button .contain-button {
    transition: border-radius .35s ease-in-out;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .weather-button:hover .contain-button, .weather-button.selected .contain-button {
    margin:5px;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    box-sizing: border-box;
  }

  .weather-button:hover, .weather-button.selected{
    border-radius: 50%;
  }
</style>

<script>
  import { mapState } from 'vuex';
  import TextEditor from '@/components/Reusables/TextEditor';
  import FileUpload from '@/components/Reusables/FileUpload';

  export default {
    components: {
      TextEditor,
      FileUpload,
    },
    props: {
      moduleName: { required: true, type: String },
    },
    data() {
      return {
        weatherList: ['sun', 'suncloud', 'cloud', 'storm'],
        userMoods: {},
        module: {},
      };
    },
    computed: {
      ...mapState('current', ['meeting']),
    },
    created() {
      const defaultContent = {
        flop: '',
        flop_files: [],
        mood: '',
        past_news: '',
        past_news_files: [],
        team: '',
        team_files: [],
        top: '',
        top_files: [],
      };
      const { meetingUser } = this.$store.state.current;
      this.module = this.meeting.getUserModule(this.moduleName, meetingUser, defaultContent);
      this.userMoods = this.module.content;
    },
    methods: {
      addFile(type, file) {
        if (! this.userMoods[`${type}_files`]) { this.$set(this.userMoods, `${type}_files`, []); }
        this.userMoods[`${type}_files`].push(file);
        this.saveModule();
      },
      removeFile(type, file) {
        const index = this.userMoods[`${type}_files`].indexOf(file);
        if (index > -1) {
          this.userMoods[`${type}_files`].splice(index, 1);
        }
        this.saveModule();
      },
      saveModule: _.debounce(function () { this.meeting.sendModule(this.module); }, 700),
    },
  };
</script>
