import store from '@/store';
import apiSrv from '@/services/apiSrv';

class Meeting {
  constructor(data = {}) {
    if (data.id) this.id = data.id;
    _.assign(this, data);
    if (! this.steps) this.steps = [];
  }

  static getList() {
    return apiSrv.call('meetings', 'index');
  }

  static getAllMinutes() {
    return apiSrv.call('meetings/dashboards/minutes');
  }

  static load(id) {
    return apiSrv.call('meetings', 'show', id);
  }

  static getStatusItems() {
    return [
      { value: 'draft', text: "Brouillon", icon: 'fas fa-info' },
      { value: 'preparation', text: "Préparation", icon: 'fas fa-clipboard-check' },
      { value: 'animation', text: "Animation", icon: 'fas fa-chalkboard-teacher' },
      { value: 'minutes', text: "Compte rendu", icon: 'fas fa-file-signature' },
      { value: 'closed', text: "Terminé", icon: 'fas fa-check' },
    ];
  }

  static statusIndex(status) {
    const order = ['draft', 'preparation', 'animation', 'minutes', 'closed'];
    return order.indexOf(status);
  }

  static getModulesSettings() { // exists in back : \App\Meeting
    return {
      moods: { preparation: 'user', animation: true },
      kpis: { preparation: 'user', animation: true },
      issues: { preparation: 'user', animation: true },
      topics: { preparation: 'user', animation: true },
      news: { preparation: 'admin', animation: true },
      actionsevolution: { preparation: 'user', animation: true },
      reviews: { animation: true },
      summaryvalidation: { minutes: 'user' },
    };
  }

  static getAvailableModulesSet(filterField, value) { // exists in back : \App\Meeting
    // returns Set of available modules for given filterField and value
    // filterField: preparation, animation, minutes, undefined (returns all)
    // value: admin, user, true
    if (! filterField) return new Set(Object.keys(Meeting.getModulesSettings()));
    const availableModules = new Set();
    const modulesSettings = Meeting.getModulesSettings();
    Object.keys(modulesSettings).forEach((key) => {
      const module = modulesSettings[key];
      if (module[filterField] == value) {
        availableModules.add(key);
      } else if (value == 'admin' && module[filterField] == 'user') {
        availableModules.add(key);
      }
    });
    return availableModules;
  }

  static sendRequest(api, action, data, silent = false) {
    const request = apiSrv.call(api, action, data);
    if (silent) {
      return request;
    }
    store.commit('ui/saving/start');
    return request.then((response) => {
      store.commit('ui/saving/success');
      return response;
    }).catch((error) => {
      store.commit('ui/saving/error', { error, request: { api, action, data } });
      return error;
    });
  }

  save(silent = false) {
    const save = this;
    return Meeting.sendRequest('meetings', 'update', save, silent);
  }

  saveFields(fields, silent = false) {
    const save = { id: this.id };
    if (Array.isArray(fields)) {
      fields.forEach((field) => {
        save[field] = this[field];
      });
    } else if (typeof fields == 'object' && fields !== null) {
      Object.keys(fields).forEach((fieldName) => {
        save[fieldName] = fields[fieldName];
      });
    } else {
      save[fields] = this[fields];
    }
    return Meeting.sendRequest('meetings', 'update', save, silent);
  }

  create(silent) {
    return Meeting.sendRequest('meetings', 'store', this, silent);
  }

  delete(silent = false) {
    return Meeting.sendRequest('meetings', 'destroy', this.id, silent);
  }

  requestPublishValidation(data) {
    return apiSrv.call(`meetings/${this.id}/validate/publish/request`, 'store', data);
  }

  sendInvites() {
    return apiSrv.call(`meetings/${this.id}/sendinvites`, 'store');
  }

  sendComment(text) {
    const meeting = this;
    return apiSrv.call('comments', 'store', { meeting_id: this.id, text }).then((response) => {
      const comment = response && response.data && response.data.comment;
      const notif = {
        field_id: comment.id,
        field_type: 'comment',
        message: `a envoyé un message: ${comment.text}`,
        section_id: comment.meeting_id,
        section_type: "meeting",
        target_type: 'exchangeCenter',
      };
      return apiSrv.call('notifications', 'store', notif).then(() => {
        meeting.comments = meeting.comments || [];
        meeting.comments.push(comment);
        return comment;
      });
    });
  }

  addFile(file) {
    this.links.push(file);
    this.saveFields('links');
  }

  saveUser(user, operation) {
    const meetingId = this.id;
    if (! meetingId) {
      return apiSrv.error('missing meeting id');
    }
    let api;
    let url = `meetings/${meetingId}/user`;
    if (operation == 'create') {
      api = 'store';
      url += `/${user.id}`;
    } else if (operation == 'delete') {
      api = 'destroy';
    } else {
      api = 'update';
    }
    return Meeting.sendRequest(url, api, user);
  }

  savePendingUser(user, operation) {
    const meetingId = this.id;
    if (! meetingId) {
      return apiSrv.error('missing meeting id');
    }
    let api;
    let url = `meetings/${meetingId}/pendinguser`;
    if (operation == 'create') {
      api = 'store';
      url += `/${user.email}`;
    } else if (operation == 'delete') {
      api = 'destroy';
    } else {
      api = 'update';
    }
    return Meeting.sendRequest(url, api, _.extend({ id: user.email }, user));
  }

  getValidPreparationSteps() {
    const availableModulesSet = Meeting.getAvailableModulesSet('preparation', 'admin');
    return (this.steps || []).filter(step => availableModulesSet.has(step.module));
  }

  getUserPreparationSteps(meetingUser) {
    const isMeetingAdmin = this.owner_id == meetingUser.id || meetingUser.access_right == "admin";
    const availableModulesSet = Meeting.getAvailableModulesSet('preparation', isMeetingAdmin ? 'admin' : 'user');
    return (this.steps || []).filter((step) => {
      if (step.timePerAttendee && step.attendeesWhiteList?.length) {
        if (! step.attendeesWhiteList.includes(meetingUser.id)) return false;
      }
      return availableModulesSet.has(step.module);
    });
  }

  getUserMinutesSteps(meetingUser) {
    const isMeetingAdmin = this.owner_id == meetingUser.id || meetingUser.access_right == "admin";
    const availableModulesSet = Meeting.getAvailableModulesSet('minutes', isMeetingAdmin ? 'admin' : 'user');
    return (this.steps || []).filter(step => availableModulesSet.has(step.module));
  }

  getStepAttendees(step) {
    return this.users.filter((user) => {
      if (step.attendeesWhiteList?.length) return step.attendeesWhiteList.includes(user.id);
      return true;
    });
  }

  /* MODULES */
  sendModule(module, validated = false, silent = false) {
    const api = `meetings/${this.id}/user/${module.user_id}/module/${module.module_name}`;
    const data = { ...module, validated };
    return Meeting.sendRequest(api, 'store', data, silent);
  }

  emptyModule(moduleName, userId) {
    return {
      content: {},
      user_id: userId,
      meeting_id: this.id,
      module_name: moduleName,
    };
  }

  getUserModule(moduleName, user, defaultValue = []) {
    let module = this.modules.find(moduleItem => moduleItem.module_name == moduleName && moduleItem.user_id == user.id);
    if (! module) {
      module = this.emptyModule(moduleName, user.id);
      module.content = defaultValue;
      // this.sendModule(module, false, true);
      this.modules.push(module);
    }
    if (module.content === null) {
      module.content = [];
    }
    if (typeof module.content == 'object' && ! Array.isArray(module.content)) {
      Object.keys(defaultValue).forEach((key) => {
        if (typeof module.content[key] == 'undefined') module.content[key] = defaultValue[key];
      });
    }
    return module;
  }
}

export default Meeting;
