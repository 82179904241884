<template>
  <v-container id="manage" class="py-6">
    <v-card class="display-flex pa-4 pb-12" style="min-height: 70vh">
      <div>
        <div style="position: sticky; top: 70px;">
          <v-tabs
            v-model="currentTabIndex" vertical slider-color="primary" background-color="white"
            active-class="primary lighten-1 white--text">
            <v-tab class="manage-tab-pill" href="#users">
              <v-icon size="20" class="mr-2" style="color: inherit; transition: none;">fas fa-users</v-icon>
              <span class="hidden-sm-and-down" style="font-size: 16px">{{ $t('MANAGE.COMPANY_USERS') }}</span>
            </v-tab>
            <v-tab v-if="hasMinutesStructure" class="manage-tab-pill" href="#minutes-structure">
              <v-icon size="20" class="mr-2" style="color: inherit; transition: none; width: 24px">fas fa-sitemap</v-icon>
              <span class="hidden-sm-and-down" style="font-size: 16px">Niveau hiérarchique</span>
            </v-tab>
            <v-tab class="manage-tab-pill" href="#configuration">
              <v-icon size="20" class="mr-2" style="color: inherit; transition: none; width: 24px">fas fa-cog</v-icon>
              <span class="hidden-sm-and-down" style="font-size: 16px">{{ $t('MANAGE.CONFIGURATION') }}</span>
            </v-tab>
          </v-tabs>
        </div>
      </div>

      <v-divider vertical class="mx-4 primary" style="border: 1px solid;"></v-divider>

      <v-tabs-items v-model="currentTabIndex" class="flex-grow-1" style="overflow: unset">
        <!-- TAB USERS -->
        <v-tab-item :transition="false" :reverse-transition="false" value="users" eager>
          <manage-users :data-loaded="dataLoaded" @load-company="loadCompany"></manage-users>
        </v-tab-item>

        <!-- TAB MINUTES STUCTURE : EDA SPECIFIC -->
        <v-tab-item v-if="hasMinutesStructure" :transition="false" :reverse-transition="false" value="minutes-structure">
          <manage-minutes-structure></manage-minutes-structure>
        </v-tab-item>

        <!-- TAB CONFIG -->
        <v-tab-item :transition="false" :reverse-transition="false" value="configuration">
          <manage-config></manage-config>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<style lang="scss">
  #manage {
    .manage-tab-pill {
      justify-content: flex-start;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      border-radius: 4px 12px 12px 4px;
      padding: 0 12px;
      min-width: 0;
    }
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';
  import ManageUsers from './ManageUsers';
  import ManageConfig from './ManageConfig/ManageConfig';

  export default {
    components: {
      ManageUsers,
      ManageConfig,
      ManageMinutesStructure: () => import(/* webpackChunkName: "eda" */ '@/client_customs/EdA/ManageMinutesStructure'),
    },
    data() {
      return {
        currentTabIndex: null,
        dataLoaded: { company: null, company_error: "", projects: null, projects_error: "" },
      };
    },
    computed: {
      hasMinutesStructure() {
        return clientConfig.minutesStructure && this.$store.state.users.accessRight.isAdmin;
      },
    },
    watch: {
      $route() {
        this.selectTab();
      },
    },
    created() {
      this.selectTab();
      this.loadCompany();
    },
    methods: {
      selectTab() {
        if (this.$route.query.tab == 'configuration') {
          this.currentTabIndex = this.$route.query.tab;
          this.$router.replace({ query: {} });
        }
      },
      loadCompany() {
        return this.$store.dispatch('users/getMe').then(() => {
          this.organization_users = this.$store.getters['users/getOrganizationUsers'];
          this.dataLoaded.company = true;
        }).catch((error) => {
          this.dataLoaded.company_error = error.message || this.$t("GLOBAL.ERROR");
        });
      },
    },
  };
</script>
