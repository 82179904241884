<template>
  <v-app-bar id="topbar" app clipped-right color="white" dense style="z-index: 1; border-bottom: 5px solid #ff9b1d !important; overflow-x: auto;">
    <v-toolbar-items>
      <v-menu offset-y content-class="ml-n1">
        <template #activator="{ on }">
          <v-btn text class="ml-n2 px-2" color="grey" style="min-width: 0" v-on="on">
            <v-icon small>fas fa-th</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item href="../" exact class="px-2">
            <img src="../../assets/images/bubble-plan-logo-color.png" style="height: 42px; vertical-align: middle;">
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>

    <router-link :to="{ name: 'List' }">
      <img src="../../assets/images/bubble-meeting.png" class="mr-4" style="height: 42px; vertical-align: middle;">
    </router-link>

    <!--    SECTIONS NAVIGATION-->
    <v-toolbar-items class="mr-2">
      <v-btn :to="{ name: 'List' }" text exact class="px-2 text-uppercase">Réunions</v-btn>
      <v-menu offset-y>
        <template #activator="{ on }">
          <v-btn text class="px-2 text-uppercase" v-on="on">
            <span>Tableaux de bord</span>
            <v-icon dark small class="ml-1">fas fa-caret-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item :to="{ name: 'ActionsList' }">Toutes les actions</v-list-item>
          <v-list-item :to="{ name: 'MinutesDashboard' }">Tous les comptes rendus</v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>

    <template v-if="displayMeetingMenu">
      <v-divider vertical></v-divider>
      <v-toolbar-items class="px-2">
        <v-btn :to="{ name: 'Home', meetingId: meeting.id }" active-class="" text class="px-1 mr-0">
          {{ meeting.title || 'Réunion sans titre' }}
        </v-btn>
        <v-menu v-if="meeting.title" offset-y>
          <template #activator="{ on }">
            <v-btn text exact class="px-1" v-on="on">
              <template v-if="currentRouteItem">
                <v-icon class="px-2">fas fa-angle-right</v-icon>
                {{ currentRouteItem.title }}
              </template>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="route in routeMenu" :key="route.id" :to="route.destination" :disabled="route.disabled">
              <v-list-item-title :class="route.muted ? 'grey--text text--lighten-1' : ''">{{ route.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-divider vertical></v-divider>
    </template>

    <!-- TRANSCLUDE BAR -->
    <slot><v-spacer></v-spacer></slot>

    <!-- HOME MANAGE-->
    <template v-if="$route.name == 'List'">
      <v-btn to="/manage" rounded small depressed class="mx-1" style="height: 32px">
        <v-icon color="primary darken-2" small class="mr-2">fa fa-lock</v-icon>
        Droits d'accès
      </v-btn>
    </template>

    <!-- ACCOUNT & HELP-->
    <template>
      <v-menu offset-y nudge-bottom="8">
        <template #activator="{ on }">
          <v-btn rounded small depressed class="mx-2" style="height: 32px; min-width: 0" v-on="on">
            <v-icon color="info" small>fas fa-question-circle</v-icon>
            <span class="hidden-sm-and-down ml-2">Aide</span>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="onBoardingConfig = 'phases'; onBoardingPage = 0">Didacticiel : les phases de réunion</v-list-item>
          <v-list-item @click="onBoardingConfig = 'modules'; onBoardingPage = 0">Didacticiel : les modules</v-list-item>
          <v-divider></v-divider>
          <v-list-item href="mailto:contact@bubblemeeting.net" target="_blank" rel="noopener noreferrer" class="py-2">
            <v-list-item-content>
              <v-list-item-title>Contactez-nous</v-list-item-title>
              <v-list-item-subtitle>contact@bubblemeeting.net</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu v-model="accountDropdownIsOpen" :close-on-content-click="false" offset-y nudge-bottom="4">
        <template #activator="{ on: onMenu }">
          <v-tooltip :disabled="accountDropdownIsOpen" bottom>
            <template #activator="{ on: onTooltip }">
              <v-btn fab small class="my-1" v-on="{ ...onTooltip, ...onMenu }">
                <v-avatar v-if="me.avatar" size="40"><img :src="me.avatar" :alt="me.firstname || 'Mon Compte'"></v-avatar>
                <v-icon v-else color="secondary">fas fa-user</v-icon>
              </v-btn>
            </template>
            <span>{{ me.firstname || 'Mon Compte' }}</span>
          </v-tooltip>
        </template>
        <v-list dense class="pb-0">
          <v-list-item class="py-2">
            <v-list-item-avatar size="48"><img :src="me.avatar" style="max-height: 50px; max-width: 50px; border-radius: 50%;"></v-list-item-avatar>
            <div class="ml-2">
              <div :title="me.email || ''"><strong>{{ me.firstname || '' }} {{ me.lastname || '' }}</strong></div>
              <!-- <div><v-btn :href="`https://bubbleplan.net/auth.php?token=${api_token}&redirect=mon-compte`" target="_blank" text small style="margin: 0 -8px">Gérer mon compte</v-btn></div> -->
            </div>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="accountDropdownIsOpen = false; openAvatarModal = ! openAvatarModal">Changer d'avatar</v-list-item>
          <v-divider></v-divider>
          <v-list-item to="/manage" active-class="" class="py-2" @click.native="accountDropdownIsOpen = false">
            <v-list-item-content>
              <v-list-item-title>Droits d'accès</v-list-item-title>
              <v-list-item-subtitle>{{ me.company && me.company.name || "FREE" }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <!--  MODALS -->
    <modal-with-background :is-open="openAvatarModal" :max-width="300" title="Changer d'avatar" @modal-close="openAvatarModal = false">
      <account-avatar v-if="openAvatarModal" @close="openAvatarModal = false"></account-avatar>
    </modal-with-background>
    <on-boarding :start-page="onBoardingPage" :config="onBoardingConfig" @close="onBoardingPage = -1"></on-boarding>
  </v-app-bar>
</template>

<style>
  #topbar {
    height: auto !important;
  }
</style>

<script>
  import { mapState, mapGetters } from 'vuex';
  import AccountAvatar from '@/components/Account/AccountAvatar';
  import OnBoarding from '@/components/OnBoardingModal/OnBoarding';

  export default {
    components: {
      AccountAvatar,
      OnBoarding,
    },
    props: {
      displayMeetingMenu: { type: Boolean, required: true }, // routeNeedMeeting && isLoaded
    },
    data() {
      return {
        openAvatarModal: false,
        accountDropdownIsOpen: null,
        api_token: window.apiSrv.getAuthToken(),
        onBoardingPage: -1,
        onBoardingConfig: 'phases',
      };
    },
    computed: {
      routeMenu() {
        const menu = [];
        if (! this.meeting) return menu;
        if (this.isMeetingAdmin) {
          menu.push({ id: 'settings', title: 'Paramètres', destination: { name: 'Settings' }, disabled: this.meeting.status == 'closed' });
        }
        menu.push({ id: 'home', title: 'Détails pratiques', destination: { name: 'Home' } });
        menu.push({ id: 'preparation', title: 'Préparation', destination: { name: 'Preparation' }, muted: this.meeting.status != 'preparation' });
        menu.push({ id: 'animation', title: 'Animation de réunion', destination: { name: 'Animation' }, muted: this.meeting.status != 'animation' });
        menu.push({ id: 'minutes', title: 'Compte rendu', destination: { name: 'Minutes' }, muted: ! ['animation', 'minutes', 'closed'].includes(this.meeting.status) });
        return menu;
      },
      currentRouteItem() {
        return this.routeMenu.find(item => item.destination.name == this.$route.name);
      },
      me() {
        return this.$store.state.users.user;
      },
      ...mapState('current', ['meeting']),
      ...mapGetters('current', ['isMeetingAdmin']),
    },
  };
</script>
