<template>
  <div style="position: relative">
    <h1 class="secondary--text pb-8 text-center">{{ step.title }}</h1>
    <actions-columns v-if="meeting.category" :category="meeting.category"></actions-columns>
    <v-card v-else class="pa-12 primary--text" style="height: 100%; font-size: 16px">
      <p class="primary white--text pa-1">
        <v-icon color="white" class="mx-1">fas fa-exclamation-triangle</v-icon>
        Aucune action trouvée car cette réunion ne fait partie d'aucune suite de réunions.<br>
        Pour créer de nouvelles actions attachées à cette réunion, utilisez le module « Points d'attention et prise de décision ».
      </p>
    </v-card>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import ActionsColumns from '@/components/Dashboards/ActionsList/ActionsColumns';

  export default {
    components: {
      ActionsColumns,
    },
    props: {
      step: { required: true, type: Object },
    },
    data() {
      return {};
    },
    computed: {
      ...mapState('current', ['meeting']),
    },
  };
</script>
