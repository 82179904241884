<template>
  <modal-with-background :is-open="openModal" :title="isNewAction ? 'Créer une action' : 'Modifier l\'action'" @modal-close="$emit('cancel')">
    <div class="px-4 pb-4 pt-0">
      <b>Action</b>
      <v-row align="center">
        <v-col class="pt-0" sm="5">
          <v-text-field v-model="actionTitle" hide-details label="Titre" color="primary darken-1"></v-text-field>
        </v-col>
        <v-col class="pt-0" sm="6" offset-sm="1">
          <date-field v-model="actionDueDate" label="Date limite" hide-details color="primary darken-1"></date-field>
        </v-col>
      </v-row>
    </div>
    <div class="px-4 pb-4 pt-0 my-4">
      <v-row class="mt-4">
        <v-col sm="5">
          <div style="display: flex; flex-direction: column;">
            <b>Responsable</b>
            <select-user
              :model-id="actionUserId" :userlist="users" label="Responsable" hide-details clearable
              @input="selectUser($event)" @click:clear="selectUser(null)">
            </select-user>
          </div>
        </v-col>
        <v-col sm="6" offset-sm="1">
          <b>Charge de travail (en jours passés)</b>
          <div class="display-flex align-end mt-1">
            <v-text-field v-model="actionWorkload" hide-details label="Estimée" type="number" color="primary darken-1"></v-text-field>
            <i class="fas fa-lg fa-caret-right mx-6 mb-2"></i>
            <v-text-field v-model="actionWorkloadUsed" hide-details label="Consommée" type="number" color="primary darken-1"></v-text-field>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row align="center" justify="center">
      <v-col v-if="! isNewAction">
        <v-btn fab small color="error" title="Supprimer l'action" class="mx-2" @click="deleteAction"><v-icon small>fas fa-trash-alt</v-icon></v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn color="secondary" rounded outlined class="white--text mx-2" @click="$emit('cancel')">Annuler</v-btn>
        <v-btn color="accent" rounded class="white--text mx-2" @click="saveAction">Valider</v-btn>
      </v-col>
    </v-row>
  </modal-with-background>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DateField from '@/components/Reusables/DateField';

  export default {
    components: {
      DateField,
    },
    props: {
      updatingAction: { type: Object, required: true },
      openModal: { type: Boolean, required: true },
    },
    data() {
      return {
        localAction: this.emptyAction(),
        groupTitle: "",
      };
    },
    computed: {
      actionTitle: {
        get() {
          return this.localAction.title || this.updatingAction.title;
        },
        set(newVal) { this.localAction.title = newVal; },
      },
      actionDueDate: {
        get() {
          const date = ! (this.localAction && this.localAction.due_date) || Object.keys(this.localAction.due_date) == 0 ? this.updatingAction.due_date : this.localAction.due_date;
          const momentDate = moment(date);
          return date && momentDate.isValid() ? momentDate.format("YYYY-MM-DD") : null;
        },
        set(newValue) {
          const newdate = moment(newValue);
          this.localAction.due_date = (newValue && newdate.isValid()) ? newdate.startOf("day").format() : null;
        },
      },
      actionUserId() { return this.localAction.user_id || this.updatingAction.user_id; },
      actionWorkload: {
        get() { return this.localAction.workload || this.updatingAction.workload; },
        set(val) { this.localAction.workload = val; },
      },
      actionWorkloadUsed: {
        get() { return this.localAction.workload_used || this.updatingAction.workload_used; },
        set(val) { this.localAction.workload_used = val; },
      },
      isNewAction() {
        return this.updatingAction && this.updatingAction.new;
      },
      users() {
        const { meeting } = this.$store.state.current;
        return (meeting && ! _.isEmpty(meeting)) ? meeting.users : this.$store.getters['users/getCompanyUsers'];
      },
      ...mapGetters('issues', ['isIssueInGroup']),
    },
    watch: {
      openModal(val) {
        if (! val) this.localAction = this.emptyAction();
      },
    },
    methods: {
      saveAction() {
        if (this.localAction && this.localAction.title == "") { delete this.localAction.title; }
        if (this.localAction && ! this.localAction.due_date) { delete this.localAction.due_date; }
        const action = Object.assign(this.updatingAction, this.localAction);
        this.$emit('save-action', { ...action });
      },
      deleteAction() {
        this.$emit('delete-action', this.updatingAction);
      },
      emptyAction() {
        return {
          due_date: null,
          title: "",
          workload: 0,
          workload_used: 0,
        };
      },
      selectUser(user) {
        if (! user) this.localAction.user_id = null;
        else if (! this.localAction.user_id) this.$set(this.localAction, 'user_id', user.id);
        else this.localAction.user_id = user.id;
      },
    },
  };
</script>
