<template>
  <v-card class="attendees-list pa-4 mb-4">
    <v-row class="mx-0">
      <div
        v-for="user in stepAttendees" :key="user.id"
        :class="{ active: selectedAttendee && user.id == selectedAttendee.id }" class="grey lighten-3 pr-2 ma-1 user-item pointer"
        @click="selectAttendee(user)">
        <avatar-tooltiped :user="user" :size="50"></avatar-tooltiped> {{ $filters.username(user) }}
        <div v-if="! dynamicModule"></div>
        <div v-else-if="selectedAttendee && user.id == selectedAttendee.id" class="over-avatar">
          <v-icon color="primary">fas fa-play</v-icon>
        </div>
        <div v-else-if="! isDone(user)" class="hover-user over-avatar">
          <v-icon color="primary">fas fa-play</v-icon>
        </div>
        <div v-else class="over-avatar">
          <v-icon color="primary">fas fa-check</v-icon>
        </div>
        <div v-if="step.users_time && step.users_time[user.id]" class="display-flex align-center pr-1" style="position: absolute; right: 0; bottom: 0">
          <v-icon v-if="step.users_time[user.id] > step.time" small>fas fa-microphone-slash</v-icon>
          <v-icon v-else small>far fa-thumbs-up</v-icon>
          <span class="mx-1 text-caption">{{ getTimeFormat(step.users_time[user.id]) }}</span>
        </div>
      </div>
      <div v-if="! stepAttendees.length">Aucun participant pour cette étape.</div>
    </v-row>
  </v-card>
</template>

<style>
  .attendees-list .over-avatar {
    position: absolute;
    background-color: rgba(158, 158, 158, .8);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    top: 8px;
    left: 8px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .attendees-list .user-item {
    border: solid 1px;
    background-color: white !important;
    min-width: 100px;
    position: relative;
    border-radius: 10px;
    transition: all .3s;
  }
  .attendees-list .user-item:hover, .attendees-list .user-item.active {
    background-color: #eee !important;
  }

  .attendees-list .hover-user {
    opacity: 0;
    transition: all .3s;
  }
  .attendees-list .user-item:hover .hover-user {
    opacity: 1;
  }
</style>

<script>
  export default {
    props: {
      step: { type: Object, required: true },
      dynamicModule: { type: Boolean, default: true },
      selectedAttendee: { type: Object, default: null },
    },
    data() {
      return {
      };
    },
    computed: {
      meeting() {
        return this.$store.state.current.meeting;
      },
      stepAttendees() {
        return this.meeting.getStepAttendees(this.step);
      },
    },
    methods: {
      selectAttendee(user) {
        this.$emit('select', user);
      },
      isDone(user) {
        if (! this.step.users_time) this.step.users_time = {};
        return !! this.step.users_time[user.id];
      },
      getTimeFormat(time) {
        let minutes = Math.floor(Math.abs(time) / 60) || 0;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        let seconds = Math.abs(Math.round(time) % 60);
        seconds = seconds < 10 ? `0${seconds}` : seconds;
        return `${minutes}:${seconds}`;
      },
    },
  };
</script>
