export default {
  computed: {
    stepAttendees() {
      return this.meeting.getStepAttendees(this.step);
    },
  },
  watch: {
    step: {
      handler(step) {
        if (_.isArray(step.users_time)) step.users_time = {};
        step.users_time = step.users_time || {};
      },
      immediate: true,
    },
  },
  methods: {
    startOfTurn() {
      if (! this.currentAttendee && this.stepAttendees.length) {
        const nextAttendee = this.stepAttendees.find(item => ! this.step.users_time[item.id]);
        if (nextAttendee) this.selectAttendee(nextAttendee);
      }
    },
    endOfTurn(time) {
      if (! this.stepAttendees.find(item => ! this.step.users_time[item.id])) {
        this.$emit('end-of-step');
      }
      const user = this.currentAttendee;
      if (! user) return;
      this.step.users_time[user.id] = time;
      this.currentAttendee = null;
    },
  },
};
