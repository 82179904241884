<template>
  <div v-html="moodsContent"></div>
</template>

<script>
  import meetingClosureModuleMixin from './meetingClosureModuleMixin';

  export default {
    mixins: [meetingClosureModuleMixin],
    computed: {
      moodsContent() {
        let contentText = `<h3>${this.step.title}</h3>`;
        this.sortedModuleDatas.forEach((mood) => {
          const avatar = `<img src="${mood.user_avatar}" alt="${mood.user_name}" class="v-avatar ma-2" style="height: 25px; width: 25px;">`;
          let username = `<span>${mood.user_name}</span>`;
          if (this.step.users_time && this.step.users_time[mood.user_id]) {
            username += `<em class="ml-1" style="color: rgb(136, 136, 136);">${window.secToMin(this.step.users_time[mood.user_id])}</em>`;
          }
          contentText += `<strong style="display: flex; align-items: center;">${avatar}${username}</strong>`;
          let userList = "";
          if (mood.content) {
            if (mood.content.mood) userList += `<h4>Humeur :</h4><p class="mood-icon ${mood.content.mood}" style="vertical-align: middle;"></p>`;
            if (this.hasContent(mood.content.past_news)) userList += `<h4>Actualité :</h4>${mood.content.past_news}`;
            if (this.hasContent(mood.content.top)) userList += `<h4>Top :</h4>${mood.content.top}`;
            if (this.hasContent(mood.content.flop)) userList += `<h4>Flop :</h4>${mood.content.flop}`;
            if (this.hasContent(mood.content.team)) userList += `<h4>Équipe :</h4>${mood.content.team}`;
          }
          contentText += userList;
        });

        return contentText;
      },
    },
    methods: {
      hasContent(html) {
        if (! html) return false;
        return !! window.html2text(html || "").trim();
      },
    },
  };
</script>
