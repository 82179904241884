<template>
  <div>
    <h3>
      <span>{{ step.title }} </span>
      <em v-if="step.time_spent" style="color: rgb(136, 136, 136);">{{ secToMin(step.time_spent) }}</em>
    </h3>
    <template v-for="(userIssues, index) in sortedModuleDatas">
      <template v-if="userIssues.content && userIssues.content.length">
        <!-- Avatar & Username -->
        <span style="display: flex; align-items: center;">
          <img :src="userIssues.user_avatar" :alt="userIssues.user_name" class="v-avatar ma-2" style="height: 25px; width: 25px;">
          <strong>{{ userIssues.user_name }}</strong>
        </span>
        <!-- Issues -->
        <ul :key="index">
          <li v-for="issue in userIssues.content" :key="issue.id">
            <span>{{ issue.title }}</span>
            <span v-if="meetingDecisions[issue.id]"> -> {{ meetingDecisions[issue.id] }} </span>
          </li>
        </ul>
      </template>
    </template>
  </div>
</template>

<script>
  import meetingClosureModuleMixin from './meetingClosureModuleMixin';

  export default {
    mixins: [meetingClosureModuleMixin],
    computed: {
      meetingDecisions() {
        return (this.meeting.decisions || []).reduce((acc, item) => {
          if (! acc[item.id]) acc[item.id] = {};
          acc[item.id] = item.decision;
          return acc;
        }, {});
      },
    },
  };
</script>
