<template>
  <v-container>
    <div class="display-flex flex-column">
      <div>
        <v-card color="grey lighten-3 elevation-0" class="px-4" style="max-width: 400px; min-height: 200px;">
          <v-row v-if="! displayableComments || ! displayableComments.length" style="overflow-y:auto">
            <v-col cols="9">
              <span>Soyez le premier à envoyer un message.</span><br>
            </v-col>
            <v-col cols="12"><v-divider light></v-divider></v-col>
          </v-row>
          <v-row v-for="comment in displayableComments" :key="comment.id" style="overflow-y:auto">
            <v-col class="order-xs-2 order-lg-2" cols="3">
              <v-avatar size="40" class="ma-2" color="grey lighten-2">
                <img v-if="comment.avatar != null" :src="comment.avatar" :alt="comment.username">
                <img v-else src="@/assets/images/anonymousUser.png">
              </v-avatar>
            </v-col>
            <v-col cols="9">
              <span v-html="comment.text"></span><br>
              <div><small>{{ comment.username }} / {{ comment.date }}</small></div>
              <div v-if="comment.error"><small class="red--text">{{ comment.error }}</small><v-btn @click="reSendComment(comment)">Renvoyer</v-btn></div>
              <div v-if="comment.load"><v-progress-circular indeterminate color="primary" size="20"></v-progress-circular></div>
            </v-col>
            <v-col cols="12"><v-divider light></v-divider></v-col>
          </v-row>
        </v-card>
      </div>
      <div>
        <v-form @submit.prevent="sendComment">
          <v-text-field
            v-model="newComment" :label="displayableComments.length == 0 ? 'Envoyer' : 'Répondre'" hide-details append-icon="fas fa-paper-plane" autofocus color="secondary"
            @click:append="sendComment">
          </v-text-field>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    data() {
      return {
        newComment: null,
      };
    },
    computed: {
      displayableComments() {
        return (this.comments || []).map((comment) => {
          const user = this.meeting.users.find(item => item.id === comment.user_id);
          comment.username = user && this.$store.getters['users/getUsername'](user) || "Anonyme";
          comment.avatar = user && user.avatar || null;
          comment.date = moment(comment.created_at).calendar();
          return comment;
        });
      },
      comments: {
        get() { return this.meeting.comments; },
        set(val) { this.meeting.comments = val; },
      },
      ...mapState('current', ['meeting', 'meetingUser']),
    },
    created() {
      this.comments = this.comments.reduce((groupedComments, newComment) => this.groupComment(newComment, groupedComments), []);
    },
    methods: {
      sendComment() {
        if (! this.newComment) return;
        const text = this.newComment;
        this.newComment = null;
        const comment = {
          created_at: moment().format(),
          meeting_id: this.meeting.id,
          updated_at: moment().format(),
          text,
          user_id: this.meetingUser.id,
          load: true,
        };
        const formerComments = this.comments.slice();
        this.comments = this.groupComment(comment, this.comments);
        this.meeting.sendComment(text).then((commentFromApi) => {
          delete comment.load;
          Object.assign(comment, commentFromApi);
          this.comments = this.groupComment(comment, formerComments);
        }).catch((error) => {
          delete comment.load;
          comment.error = `Une erreur a empêché l'envoi de ce message: ${error.message}`;
        });
      },
      groupComment(newComment, groupedComments) {
        const lastComment = groupedComments.length && groupedComments[groupedComments.length - 1];
        const isSameUser = lastComment.user_id == newComment.user_id;
        const moreThanFiveMinDiff = moment(moment(newComment.updated_at) - moment(lastComment.updated_at)).minutes() >= 5;
        if (! lastComment || ! isSameUser || moreThanFiveMinDiff || newComment.error || newComment.load) {
          groupedComments.push(Object.assign(newComment, { text: `<div>${newComment.text}</div>` }));
          return groupedComments;
        }
        lastComment.text = `${lastComment.text} <div>${newComment.text}</div>`;
        lastComment.created_at = newComment.created_at;
        return groupedComments;
      },
      reSendComment(comment) {
        delete comment.error;
        comment.load = true;
        this.meeting.sendComment(comment.text).then((commentFromApi) => {
          delete comment.load;
          Object.assign(comment, commentFromApi);
        }).catch((error) => {
          delete comment.load;
          comment.error = `Une erreur a empêché l'envoi de ce message: ${error.message}`;
        });
      },
    },
  };
</script>
