moment.updateLocale('fr', {
  months: "Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre".split("_"),
  monthsShort: "Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.".split("_"),
  weekdays: "Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi".split("_"),
  weekdaysShort: "Dim._Lun._Mar._Mer._Jeu._Ven._Sam.".split("_"),
  calendar: {
    sameDay: '[Aujourd\'hui à] LT',
    nextDay: '[Demain à] LT',
    nextWeek: 'dddd [à] LT',
    lastDay: '[Hier à] LT',
    lastWeek: 'dddd [dernier à] LT',
    sameElse: 'LL',
  },
  custom(format) {
    return {
      mediumDateNoYear: "D MMM",
      datetime: "LL [à] LT",
    }[format];
  },
});

moment.updateLocale('fr-ca', {
  months: "Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre".split("_"),
  monthsShort: "Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.".split("_"),
  weekdays: "Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi".split("_"),
  weekdaysShort: "Dim._Lun._Mar._Mer._Jeu._Ven._Sam.".split("_"),
  custom(format) {
    return {
      mediumDateNoYear: "MMM D",
      datetime: "LL [à] LT",
    }[format];
  },
});

moment.updateLocale('en', {
  calendar: {
    sameDay: '[Today at] LT',
    nextDay: '[Tomorrow at] LT',
    nextWeek: 'dddd [at] LT',
    lastDay: '[Yesterday at] LT',
    lastWeek: '[Last] dddd [at] LT',
    sameElse: 'LL',
  },
  custom(format) {
    return {
      mediumDateNoYear: "MMM D",
      datetime: "LL [at] LT",
    }[format];
  },
});

moment.updateLocale('en-gb', {
  custom(format) {
    return {
      mediumDateNoYear: "D MMM",
      datetime: "LL [at] LT",
    }[format];
  },
});

moment.fn.isWorkday = function isWorkday() {
  return this.isoWeekday() !== 6 && this.isoWeekday() !== 7;
};

moment.fn.addWorkdays = function addWorkdays(daysArg) {
  const days = parseInt(daysArg, 10);
  const increment = days / Math.abs(days);
  if (! increment) return this;
  this.add(Math.floor(Math.abs(days) / 5) * 7 * increment, 'days');
  let remaining = days % 5;
  while (remaining !== 0) {
    this.add(increment, 'days');
    if (this.isoWeekday() !== 6 && this.isoWeekday() !== 7) remaining -= increment;
  }
  return this;
};

moment.fn.addWithWorkdays = function addWithWorkdays(arg1, arg2, arg3) {
  // moment().addWithWorkdays(Number, String unit, Boolean useWorkdays);
  if (arg3 && typeof arg2 == 'string' && moment.duration(1, arg2).as('days') <= 1) {
    const days = moment.duration(arg1, arg2).as('days');
    this.addWorkdays(days);
    const remaining = arg1 % moment.duration(1, 'day').as(arg2);
    this.add(remaining, arg2);
    while (! this.isWorkday() && arg1) this.add(arg1 < 0 ? -1 : 1, 'day');
    return this;
  }
  return this.add(arg1, arg2);
};

moment.fn.diffWorkdays = function diffWorkdays(m2) {
  // not tested when this < m2
  const days = this.diff(m2, 'days');
  const completeweeks = Math.floor(Math.abs(days) / 7);
  let workdays = completeweeks * 5;
  const mydate = moment(m2).add(completeweeks, 'weeks');
  if (! mydate.isValid()) return workdays;
  while (! this.isBefore(mydate.add(1, 'day'))) {
    if (mydate.isWorkday()) workdays += 1;
  }
  return workdays;
};

moment.fn.diffWithWorkdays = function diffWithWorkdays(arg1, arg2, arg3) {
  // moment().diffWithWorkdays(Number, String unit, Boolean useWorkdays);
  if (arg3 && typeof arg2 == 'string' && this.isAfter(arg1)) {
    const offdays = this.diff(arg1, 'days') - this.diffWorkdays(arg1) + (moment(arg1).isWorkday() ? 0 : 1);
    return this.diff(arg1, arg2) - moment.duration(offdays, 'days').as(arg2);
  }
  return this.diff(arg1, arg2);
};
