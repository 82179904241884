export default {
  getTemplateSteps(stepList) {
    return stepList.map(step => ({ ...this.defaultSteps[step.module], ...step }));
  },

  stepsToDescription(steps) {
    return steps.filter(item => item.module).map((item) => {
      const timing = ` (${item.time / 60} min${item.timePerAttendee ? " chacun" : ""})`;
      return `- ${item.title || ""}${item.time ? timing : ""}`;
    }).join("\n");
  },

  operationsTemplate: [
    { module: 'news', title: "Introduction" },
    { module: 'moods' },
    { module: 'actionsevolution' },
    { module: 'reviews', title: "Tour de table de clôture" },
  ],
  servicesTemplate: [
    { module: 'news', title: "Introduction" },
    { module: 'moods' },
    { module: 'actionsevolution' },
    { module: 'topics' },
    { module: 'issues' },
    { module: 'reviews', title: "Tour de table de clôture" },
  ],
  projectsTemplate: [
    { module: 'news', title: "Introduction" },
    { module: 'actionsevolution' },
    { module: 'kpis' },
    { module: 'topics' },
    { module: 'issues' },
    { module: 'reviews', title: "Tour de table de clôture" },
  ],
  allTemplate: [
    { module: 'news' },
    { module: 'moods' },
    { module: 'actionsevolution' },
    { module: 'kpis' },
    { module: 'topics' },
    { module: 'issues' },
    { module: 'reviews' },
    { module: 'summaryvalidation' },
  ],
  defaultSteps: {
    news: {
      module: 'news',
      module_name: 'news1',
      title: "Présentation par l'organisateur",
      time: 300,
      timePerAttendee: false,
    },
    moods: {
      module: 'moods',
      module_name: 'moods1',
      title: "Tour de table des actus",
      time: 180,
      timePerAttendee: true,
    },
    topics: {
      module: 'topics',
      module_name: 'topics1',
      title: "Sujets libres",
      time: 900,
      timePerAttendee: false,
    },
    issues: {
      module: 'issues',
      module_name: 'issues1',
      title: "Points d'attention et prise de décision",
      time: 900,
      timePerAttendee: false,
    },
    actionsevolution: {
      module: 'actionsevolution',
      module_name: 'actionsevolution1',
      title: "Revue des actions",
      time: 900,
      timePerAttendee: false,
    },
    kpis: {
      module: 'kpis',
      module_name: 'kpis1',
      title: "Revue des indicateurs",
      time: 300,
      timePerAttendee: true,
    },
    reviews: {
      module: 'reviews',
      module_name: 'reviews1',
      title: "Tour de table d'évaluation",
      time: 120,
      timePerAttendee: true,
    },
    summaryvalidation: {
      module: 'summaryvalidation',
      module_name: 'summaryvalidation1',
      title: "Validation du compte rendu",
      time: 0,
      timePerAttendee: false,
    },
  },
  tips: {
    reviews: "Tour de table sans préparation ayant pour but d'évaluer un sujet sur le moment, ça peut être la réunion dans son entiereté par exemple.",
    news: "Slide de texte remplis par l'organisateur.",
    topics: "Chacun peut proposer pendant la période de préparation un sujet qui sera abordé en réunion.",
    issues: "Tous les participants saisissent leurs points d'attention et ceux-ci sont transformés pendant la réunion en action ou en arbitrage",
    kpis: "Vos indicateurs à montrer pendant la réunion. Ceux-ci seront ajoutés par les participants à l'étape de préparation.",
    actionsevolution: "L'évolution de vos actions depuis votre dernière réunion de cette suite de réunions (en cours, en retard ou complétées).",
    moods: "Un tour de table que chacun prépare avant la réunion pour parler de son humeur du moments et de ses actualités.",
  },
};
