<template>
  <div>
    <div ref="title">
      <h1>{{ meeting.title || 'Réunion sans titre' }}</h1>
      <p v-if="meeting.category">de la suite de réunions <strong>{{ meeting.category }}</strong></p>
    </div>
    <div v-for="section in meetingSections" :key="section">
      <component :is="`meeting-closure-${section}`" :ref="section" :meeting="meeting"></component>
    </div>
    <template v-if="meeting.steps && meeting.steps.length">
      <div ref="modules-title"><br><h2>Détails du déroulé</h2></div>
      <template v-for="step in meeting.steps">
        <component :is="`meeting-closure-module-${step.module}`" :ref="`module-${step.module}`" :key="step.id" :meeting="meeting" :step="step"></component>
      </template>
    </template>
  </div>
</template>

<script>
  import MeetingClosureUsers from './MeetingClosureUsers';
  import MeetingClosureSteps from './MeetingClosureSteps';
  import MeetingClosureDecisions from './MeetingClosureDecisions';
  import MeetingClosureFridge from './MeetingClosureFridge';
  import MeetingClosureActions from './MeetingClosureActions';
  import MeetingClosureModuleNews from './MeetingClosureModule/MeetingClosureModuleNews';
  import MeetingClosureModuleMoods from './MeetingClosureModule/MeetingClosureModuleMoods';
  import MeetingClosureModuleActionsevolution from './MeetingClosureModule/MeetingClosureModuleActionsevolution';
  import MeetingClosureModuleTopics from './MeetingClosureModule/MeetingClosureModuleTopics';
  import MeetingClosureModuleIssues from './MeetingClosureModule/MeetingClosureModuleIssues';
  import MeetingClosureModuleReviews from './MeetingClosureModule/MeetingClosureModuleReviews';
  import MeetingClosureModuleKpis from './MeetingClosureModule/MeetingClosureModuleKpis';
  import MeetingClosureModuleSummaryvalidation from './MeetingClosureModule/MeetingClosureModuleSummaryvalidation';

  export default {
    components: {
      MeetingClosureUsers,
      MeetingClosureSteps,
      MeetingClosureDecisions,
      MeetingClosureFridge,
      MeetingClosureActions,
      MeetingClosureModuleNews,
      MeetingClosureModuleMoods,
      MeetingClosureModuleActionsevolution,
      MeetingClosureModuleTopics,
      MeetingClosureModuleIssues,
      MeetingClosureModuleReviews,
      MeetingClosureModuleKpis,
      MeetingClosureModuleSummaryvalidation,
    },
    props: {
      meeting: { type: Object, required: true },
    },
    data() {
      return {
        meetingSections: ['users', 'steps', 'decisions', 'actions', 'fridge'],
      };
    },
  };
</script>
