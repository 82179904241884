import Meeting from '@/models/Meeting';

export default {
  namespaced: true,
  module: {},
  state: {
    meeting: {},
    meetingUser: {},
    meetingLoaded: false,
  },
  getters: {
    isMeetingAdmin: state => (state.meeting && state.meetingUser.id == state.meeting.owner_id || state.meetingUser.access_right == 'admin'),
  },
  mutations: {
    setMeeting(state, meeting) {
      state.meeting = meeting;
    },
    setMeetingUser(state, meetingUser) {
      state.meetingUser = meetingUser;
    },
    resetMeeting(state) {
      state.meeting = {};
      state.meetingLoaded = false;
    },
  },
  actions: {
    load({ state, commit, dispatch, rootState }, meetingId) {
      state.meetingLoaded = false;
      return Meeting.load(meetingId).then((result) => {
        if (! result || ! result.data || ! result.data.meeting) {
          dispatch('openErrorModal', { backToList: true, message: "Cette réunion est indisponible." }, { root: true });
        }
        commit('setMeeting', new Meeting(result.data.meeting));
        commit('actions/setActions', state.meeting.actions, { root: true });
        return rootState.users.userPromise.then((me) => {
          if (state.meeting.users.length) {
            const meetingUser = state.meeting.users.find(item => item.id == me.id) || {};
            commit('setMeetingUser', meetingUser);
            state.meetingLoaded = true;
          } else {
            rootState.users.userPromise.then((currentUser) => {
              const meetingUser = {
                id: currentUser.id,
                firstname: currentUser.firstname,
                lastname: currentUser.lastname,
                avatar: currentUser.avatar,
              };
              commit('setMeetingUser', meetingUser);
              state.meetingLoaded = true;
            });
          }
          return state.meeting;
        });
      }).catch((error) => {
        if (error.status == 401) return Promise.reject(error);
        const { message } = error;
        let errorMessage = typeof message == 'object' && Object.keys(message).length == 0 ? "cette réunion est inaccessible" : message;
        errorMessage = message == "Ressource réunion introuvable." ? "Cette réunion n'existe pas." : message;
        dispatch('openErrorModal', { backToList: true, message: errorMessage }, { root: true });
        return Promise.reject(message);
      });
    },
    save({ state }, fields) {
      state.meeting.saveFields(fields);
    },
  },
};
