import Vue from 'vue';
import accessRight from './users/accessRight';
import groups from './users/groups';

let firstlogin = true;
let resolveUserPromise;
const userPromise = new Promise(((resolve) => {
  resolveUserPromise = resolve;
}));

function reset({ state }) {
  state.user = {};
}

function getMe({ state, dispatch, rootState }, silent) {
  return window.apiSrv.call('users/me', 'index', { product: 'meeting' }, silent).then(async (result) => {
    dispatch('reset');
    const data = result && result.data || {};
    if (data.user) state.user = data.user;
    if (! state.user.firstname) state.user.firstname = rootState.lang.i18n.t('GLOBAL.FIRSTNAME');
    if (! state.user.lastname) state.user.lastname = rootState.lang.i18n.t('GLOBAL.LASTNAME');

    await dispatch('accessRight/load');

    const { user } = state;
    if (firstlogin) {
      resolveUserPromise(user);
    }
    firstlogin = false;
    if (user.company && user.company.isPremiumExpired) window.apiSrv.displaySuspended();
    return user;
  });
}

function getMeOrRetry({ dispatch }, retryDelay = 300) {
  dispatch('getMe', true).catch((error) => {
    if (error.status == 401) return; // unlogued access
    window.setTimeout(() => { dispatch('getMeOrRetry', Math.min(retryDelay * 2, 60000)); }, retryDelay);
  });
}

/** *************************** */
/** ** INIT + PING / REFRESH ** */
/** *************************** */
function loadLocale(localLang = localStorage.getItem('lang')) {
  localStorage.setItem('lang', localLang || 'en');
}

function load({ state, dispatch }) {
  // dispatch('lang/loadLocale', undefined, { root: true });
  loadLocale();
  dispatch('getMeOrRetry');
  setInterval(() => {
    if (! state.user.id) return;
    dispatch('getMe', true).catch(() => {});
  }, 3600000);
}

/** ******************* */
/** ***** GETTERS ****** */
/** ******************* */
function getCompanyUsers(state) {
  const { user } = state;
  if (! user.id) return [];
  return user.company && user.company.users || [_.pick(user, ['id', 'email', 'firstname', 'lastname', 'avatar', 'access_right', 'meeting_right'])];
}

function getOrganizationUsers(state, getters) {
  let organizationUsers;
  if (state.accessRight.hasOrganization && state.user.organization) {
    organizationUsers = state.user.organization.getUsers();
  } else {
    organizationUsers = getters.getCompanyUsers;
  }
  return organizationUsers.sort((a, b) => (Vue.prototype.$filters.username(a) < Vue.prototype.$filters.username(b) ? -1 : 1));
}

function usersById(state, getters) {
  return getters.getOrganizationUsers.reduce((acc, item) => { acc[item.id] = item; return acc; }, {});
}

function getUserById(state, getters) {
  return id => getters.usersById[id] || null;
}

function getOrganizationUsernames(state, getters) {
  return getters.getOrganizationUsers.reduce((acc, user) => {
    acc[user.id] = Vue.prototype.$filters.username(user);
    return acc;
  }, {});
}

function getOrganizationShortUsernames(state, getters) {
  return getters.getOrganizationUsers.reduce((acc, user) => {
    acc[user.id] = Vue.prototype.$filters.username(user, 'short');
    return acc;
  }, {});
}

function getUsername(state, getters) {
  return (userObj, format) => {
    if (! userObj) return "";
    const username = format == 'short' ? getters.getOrganizationShortUsernames[userObj.id] : getters.getOrganizationUsernames[userObj.id];
    return username || Vue.prototype.$filters.username(userObj, format);
  };
}

// Language
function getLang() {
  return localStorage.getItem('lang') || 'en';
}

export default {
  namespaced: true,
  modules: {
    accessRight,
    groups,
  },
  state: {
    user: {},
    userPromise,
  },
  getters: {
    getCompanyUsers,
    getOrganizationUsers,
    getOrganizationUsernames,
    getOrganizationShortUsernames,
    usersById,
    getUserById,
    getUsername,
    getLang,
  },
  mutations: {
  },
  actions: {
    getMe,
    getMeOrRetry,
    load,
    reset,
  },
};
