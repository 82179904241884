<template>
  <v-menu ref="menu" :nudge-right="40" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
    <template #activator="{ on }">
      <v-text-field
        v-model="displayedDate" :label="label" :clearable="clearable" :hide-details="hideDetails" :rules="rules"
        prepend-icon="far fa-calendar-alt" readonly color="primary darken-1"
        v-on="on" @click:clear="$emit('input', null)">
      </v-text-field>
    </template>
    <v-date-picker v-model="dateDay" first-day-of-week="1" color="accent" locale="fr" no-title scrollable @input="input()"></v-date-picker>
  </v-menu>
</template>

<script>
  export default {
    model: {
      prop: 'date',
      event: 'input',
    },
    props: {
      label: { required: true, type: String },
      date: { required: false, type: String, default: null },
      format: { required: false, type: String, default: "dddd DD MMMM YYYY" },
      clearable: { required: false, type: Boolean, default: false },
      hideDetails: { required: false, type: Boolean, default: null },
      rules: { type: Array, default: () => [] },
    },
    data() {
      return {
        updatedDate: this.date,
      };
    },
    computed: {
      momentDate() { return moment(this.updatedDate).isValid() ? moment(this.updatedDate) : moment().startOf('day'); },
      dateDay: {
        get() {
          return this.updatedDate ? this.momentDate.format("YYYY-MM-DD") : null;
        },
        set(newValue) {
          const newdate = moment(newValue);
          this.updatedDate = newdate.startOf('day').format();
        },
      },
      displayedDate: {
        get() {
          return this.dateDay ? moment(this.dateDay).format(this.format) : null;
        },
        set() {},
      },
    },
    watch: {
      date(newVal) {
        if (newVal == null) { this.updatedDate = null; return; }
        this.updatedDate = moment(newVal).startOf('day').format();
      },
    },
    methods: {
      input() {
        this.$refs.menu.isActive = false;
        this.$emit('input', this.updatedDate);
      },
    },
  };
</script>
