import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import store from '@/store';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        primary: "#ff9b1d",
        secondary: "#484848",
        accent: "#43c63e",
        error: "#f82223",
        warning: "#fef145",
        info: "#8a92eb",
        success: "#43c63e",
        grey: {
          base: "#9e9e9e",
          lighten1: "#bdbdbd",
          lighten2: "#e0e0e0",
          lighten3: "#eee",
          lighten4: "#f5f5f5",
          lighten5: "#fafafa",
          darken1: "#757575",
          darken2: "#616161",
          darken3: "#424242",
          darken4: "#212121",
        },

        anchor: '#0169aa',
      },
    },
  },
  lang: {
    t: (key, ...params) => store.state.lang.i18n.t(key, params),
  },
});
