let config = {};
const subdomain = window.location.hostname.replace(/(.*)\.bubblemeeting\.net/i, '$1');
if (['eda'].includes(subdomain)) {
  config = require(/* webpackChunkName: "[request]" */ `./clients/${subdomain}.js`);
  config = config.default || config;
}

// const integration = decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]integration(?:\\=([^&]*))?)?.*$", "i"), "$1"));
// if (integration == 'microsoftteams') {
//   config = angular.merge({}, require(`./integrations/microsoftteams`).default, config);
// }
export default config;
