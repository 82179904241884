<template>
  <div v-if="meeting.fridge && meeting.fridge.length">
    <strong>Points d'attention reportés :</strong>
    <ul>
      <li v-for="issue in meeting.fridge" :key="issue.id">
        <span>{{ issue.title }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      meeting: { type: Object, required: true },
    },
  };
</script>
