import msgbox from './msgbox';
import saving from './saving';

export default {
  namespaced: true,
  modules: {
    msgbox,
    saving,
  },
  state: {
    displayLoginWindow: false,
  },
  mutations: {
    setLoginWindow(state, status) {
      state.displayLoginWindow = status;
    },
  },
};
