import apiSrv from '@/services/apiSrv';

export default {
  namespaced: true,
  modules: {},
  state: {
    all: [],
  },
  getters: {
    /* eslint arrow-body-style: 0 */
    getByCategory: state => (category, userIdFilter = null) => {
      return state.all.filter(action => (! userIdFilter || action.user_id == userIdFilter) && action.meeting && action.meeting.category == category);
    },
    getByMeeting: state => (meetingId) => {
      if (meetingId) return state.all.filter(action => action.meeting_id == meetingId);
      return state.all.slice();
    },
  },
  mutations: {
    setActions(actions, newActions) {
      actions.all = newActions;
    },
    create(actions, newAction) {
      actions.all.push(newAction);
    },
    updateAction(actions, action) {
      const index = actions.all.findIndex(item => item.id == action.id);
      if (index != -1) actions.all[index] = action;
    },
    delete(actions, action) {
      const index = actions.all.findIndex(item => item.id == action.id);
      if (index != -1) actions.all.splice(index, 1);
    },
  },
  actions: {
    loadAll({ commit }) {
      return Promise.all([apiSrv.call('meetings/actions', 'index'), apiSrv.call('meetings/actions/completed', 'index')]).then((responses) => {
        let newActions = [];
        responses.forEach((response) => {
          if (! response || ! response.data || ! response.data.actions) throw Error("the GET actions didn't receive the expected datas.");
          newActions = newActions.concat(response.data.actions);
        });
        commit('setActions', newActions);
      });
    },
    create({ commit }, newAction) {
      return apiSrv.call('meetings/actions', 'store', newAction).then((response) => {
        if (! response || ! response.data || ! response.data.action) throw new Error("Issue in POST Actions request");
        const savedAction = Object.assign(response.data.action, { issue: newAction.issue });
        commit('create', savedAction);
        return savedAction;
      });
    },
    update({ commit }, action) {
      return apiSrv.call('meetings/actions', 'update', action).then((response) => {
        if (! response || ! response.data || ! response.data.action) throw new Error("Issue in PUT Actions request");
        commit('updateAction', response.data.action);
        return response.data.action;
      });
    },
    delete({ commit }, action) {
      return apiSrv.call('meetings/actions', 'destroy', { id: action.id }).then(() => {
        commit('delete', action);
      });
    },
  },
};
