<template>
  <v-container v-if="isMeetingAdmin">
    <v-stepper v-model="stepIndex" vertical non-linear class="elevation-0">
      <div v-for="(step, index) in steps" :key="index">
        <v-stepper-step :step="index + 1" :complete="stepIndex > index + 1" editable edit-icon="fa fa-check" @click="clickStep(index + 1)">
          {{ step.title }}
          <small>{{ step.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content :step="index + 1">
          <component :is="'settings-' + step.id" class="mb-6" @stepends="stepIndex = index + 2"></component>
        </v-stepper-content>
      </div>
    </v-stepper>
    <v-col cols="12" md="8" offset-md="2" class="mt-6">
      <publish-button v-if="meeting.status == 'draft'" block></publish-button>
      <v-btn v-else :disabled="isSaving" :to="{ name: 'Home' }" color="accent" block rounded>Revenir à l'accueil</v-btn>
    </v-col>
  </v-container>
  <v-container v-else class="white pa-12">
    <p>Cette section est réservée aux organisateurs.</p>
    <v-btn :to="{ name: 'Home' }" color="accent" block rounded>Revenir à l'accueil</v-btn>
  </v-container>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import SettingsDetails from './SettingsDetails';
  import SettingsAttendees from './SettingsAttendees';
  import SettingsMeetingSteps from './SettingsMeetingSteps';
  import SettingsInvitations from './SettingsInvitations';
  import PublishButton from './partials/PublishButton';

  export default {
    components: {
      SettingsDetails,
      SettingsAttendees,
      SettingsMeetingSteps,
      SettingsInvitations,
      PublishButton,
    },
    beforeRouteLeave(to, from, next) {
      const isEmpty = ! this.meeting.title && this.meeting.status == "draft";
      const justCreated = this.meeting.created_at == this.meeting.updated_at;
      if (! isEmpty || ! justCreated) {
        next();
        return;
      }
      setTimeout(() => {
        this.meeting.delete(true).then(() => { next(); }).catch(() => { next(); });
      }, 1000);
    },
    data() {
      return {
        stepIndex: 1,
        steps: [
          { id: 'details', title: "Informations générales", subtitle: "Titre, projet, date, emplacement, ..." },
          { id: 'attendees', title: "Participants" },
          { id: 'meeting-steps', title: "Déroulé et Ordre du jour" },
          { id: 'invitations', title: "Invitations" },
        ],
        isSaving: false,
      };
    },
    computed: {
      ...mapState('current', ['meeting']),
      ...mapGetters('current', ['isMeetingAdmin']),
    },
    created() {
      let i;
      for (i = 0; i < this.steps.length; i++) {
        if (! this.steps[i].complete) break;
      }
      this.stepIndex = Math.min(i + 1, this.steps.length);
    },
    methods: {
      validateStep(step, index) {
        step.complete = true;
        this.stepIndex = index + 2;
      },
      clickStep(index) {
        if (this.stepIndex !== null && index == this.stepIndex) this.$nextTick(() => { this.stepIndex = -1; });
      },
    },
  };
</script>
