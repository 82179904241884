<template>
  <div v-if="companyLoaded">
    <b class="primary--text text-uppercase">{{ $t('MANAGE.CURRENT_SUBSCRIPTIONS') }}</b>
    <div class="ml-6 display-flex align-center">
      <div>
        <div>{{ $t('MANAGE.SUBSCRIPTIONS') }} {{ $t('ACR.ORGANIZER') }} : <strong :class="{ 'error--text': maxOrganizersExceeded }">{{ organizers_count }}/{{ company.maxorganizers }}</strong></div>
        <div>{{ $t('MANAGE.SUBSCRIPTIONS') }} {{ $t('ACR.PARTICIPANT') }} : <strong :class="{ 'error--text': maxParticipantsExceeded }">{{ participants_count }}/{{ max_participants }}</strong></div>
      </div>
      <v-btn fab :small="! maxOrganizersExceeded" class="ml-4" @click.stop="openPremiumModal = true">
        <v-icon :size="maxOrganizersExceeded ? 28 : 20" color="accent">fa fa-cart-plus</v-icon>
      </v-btn>
    </div>
    <template v-if="canInviteUsers">
      <div class="ml-6 mt-6"><b class="primary--text">{{ $t('MANAGE.INVITE_USER') }} :</b></div>
      <div class="ml-6 pt-1 display-flex align-center">
        <v-text-field
          v-model="invitetojoin.email" :label="$t('GLOBAL.EMAIL')" :disabled="maxParticipantsReached" :success-messages="invitetojoin.message" :error-messages="invitetojoin.error"
          type="email" autocomplete="none" style="max-width: 250px">
        </v-text-field>
        <v-select v-model="invitetojoin.meeting_right" :label="$t('MANAGE.ACCESS_RIGHT')" :disabled="maxParticipantsReached"
                  :items="[{ value: '', text: $t('ACR.PARTICIPANT') },
                           { value: 'organizer', text: $t('ACR.ORGANIZER'), disabled: maxOrganizersReached }]"
                  class="ml-4" style="max-width: 200px">
        </v-select>
        <v-btn
          :disabled="! invitetojoin.email || invitetojoin.sending" :loading="invitetojoin.sending" color="accent" rounded large class="ml-4"
          @click="inviteUser()">
          {{ $t('MANAGE.SEND_INVITE') }}
        </v-btn>
      </div>
    </template>
    <div v-if="pending_invites.length" class="ml-6 mt-2">
      <b class="primary--text pointer" @click="show_pending_invites = ! show_pending_invites">
        <i :class="show_pending_invites ? 'fa-caret-down' : 'fa-caret-right'" class="fas" style="min-width: 1em"></i>
        {{ $tc('MANAGE.INVITES_PENDING', pending_invites.length, { count: pending_invites.length }) }}
      </b>
      <v-fade-transition hide-on-leave>
        <table v-show="show_pending_invites">
          <transition-group name="list-fade" tag="tbody">
            <tr v-for="invite in pending_invites" :key="invite.id" class="list-fade-item">
              <td>{{ invite.email }}</td>
              <td class="px-6">{{ { '': $t('ACR.PARTICIPANT'), organizer: $t('ACR.ORGANIZER') }[invite.meeting_right] }}</td>
              <td>{{ $filters.moment(invite.created_at, 'calendar') }}</td>
              <td v-if="userIsAdmin" class="pl-6 pr-2">
                <v-btn small icon class="ma-0" @click="removePendingInvite(invite)"><v-icon color="error" small>fas fa-trash-alt</v-icon></v-btn>
              </td>
              <td v-if="userIsAdmin && invite.created_at < today">
                <v-btn :disabled="invitetojoin.sending" small icon class="ma-0" @click="resendPendingInvite(invite)"><v-icon color="accent" small>fas fa-paper-plane</v-icon></v-btn>
              </td>
            </tr>
          </transition-group>
        </table>
      </v-fade-transition>
    </div>
    <v-divider class="my-6"></v-divider>

    <!--  MODALS -->
    <modal-with-background
      :is-open="openPremiumModal" :max-width="900" persistent title="Gérer votre abonnement Premium" content-class="align-self-start"
      @modal-close="openPremiumModal = false">
      <premium-window v-if="openPremiumModal" @close="openPremiumModal = false"></premium-window>
    </modal-with-background>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import PremiumWindow from '@/components/Premium/PremiumWindow';

  export default {
    components: {
      PremiumWindow,
    },
    data() {
      return {
        companyLoaded: null,
        company: {},
        invitetojoin: { email: "", access_right: 'consult', meeting_right: '', sending: null, message: "", error: "" },
        show_pending_invites: false,
        today: moment().format('YYYY-MM-DD'),
        openPremiumModal: false,
      };
    },
    computed: {
      organizers_count() {
        return this.company_users.filter(item => item.meeting_right == 'organizer').length;
      },
      maxOrganizersExceeded() {
        return this.organizers_count > this.company.maxorganizers;
      },
      maxOrganizersReached() {
        return this.organizers_count >= this.company.maxorganizers;
      },
      participants_count() {
        return this.company_users.length - this.organizers_count;
      },
      max_participants() {
        return this.company.maxusers + this.company.maxconsults - this.company.maxorganizers;
      },
      maxParticipantsReached() {
        return (this.participants_count >= this.max_participants) && (this.company_users.length >= (this.company.maxorganizers + this.max_participants));
      },
      maxParticipantsExceeded() {
        return (this.participants_count > this.max_participants) && (this.company_users.length > (this.company.maxorganizers + this.max_participants));
      },
      canInviteUsers() {
        return this.userIsAdmin || ! this.user.company;
      },
      company_users() { return this.$store.getters['users/getCompanyUsers']; },
      userIsAdmin() { return this.accessRight.isAdmin; },
      ...mapState('companyinvites', ['pending_invites']),
      ...mapState('users', ['user', 'accessRight']),
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        if (! this.user.company) {
          this.company = { maxusers: 1, maxconsults: 2, maxorganizers: 0 };
        } else {
          this.company = this.user.company;
          if (this.userIsAdmin) this.loadPendingInvites();
        }
        this.companyLoaded = true;
      });
    },
    methods: {
      inviteUser() {
        if (! this.invitetojoin.email) return;
        this.invitetojoin.message = "";
        this.invitetojoin.error = "";
        if (! window.isValidEmailAddress(this.invitetojoin.email)) {
          this.invitetojoin.error = this.$t('MANAGE.INVALID_EMAIL');
          return;
        }
        this.invitetojoin.sending = true;
        this.sendInvite({ email: this.invitetojoin.email, access_right: this.invitetojoin.access_right, meeting_right: this.invitetojoin.meeting_right }).then((response) => {
          this.invitetojoin.message = response?.data?.message || this.$t('MANAGE.INVITATION_SENT');
          this.invitetojoin.email = "";
          this.invitetojoin.sending = false;
        }).catch((error) => {
          this.invitetojoin.error = error.message || this.$t('MANAGE.INVITATION_NOT_SENT');
          this.invitetojoin.sending = false;
        });
      },
      removePendingInvite(invite) {
        this.deletePendingInvite(invite).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Invitation was not deleted", body: message || "" });
        });
      },
      resendPendingInvite(invite) {
        this.invitetojoin.email = invite.email;
        this.invitetojoin.access_right = invite.access_right;
        this.invitetojoin.meeting_right = invite.meeting_right;
        this.inviteUser();
      },
      ...mapActions('companyinvites', ['loadPendingInvites', 'deletePendingInvite', 'sendInvite']),
    },
  };
</script>
