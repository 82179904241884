<template>
  <div class="mx-auto" style="max-width: 860px; min-height: 200px">
    <img src="@/assets/images/schema-cycle-meeting.jpg" style="max-width:100%">
  </div>
</template>

<script>
  export default {
  };
</script>
