<template>
  <div class="manage-users mx-3">
    <div v-if="! dataLoaded.company && ! dataLoaded.company_error" class="py-12 text-center">
      <v-progress-circular :size="70" indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-if="! dataLoaded.company && dataLoaded.company_error">{{ dataLoaded.company_error }}</div>
    <div v-show="dataLoaded.company">
      <manage-subscriptions v-if="dataLoaded.company"></manage-subscriptions>
      <div>
        <b class="primary--text text-uppercase">{{ $t('MANAGE.ACCOUNT_USERS') }}</b>
        <v-data-table :headers="usersTableHeaders" :items="sortedCompanyUsers" class="ml-6">
          <template #item="{ item }">
            <tr>
              <td class="text-center">
                <template v-if="userIsAdmin">
                  <v-chip
                    v-if="! maxOrganizersReached || item.meeting_right == 'organizer'"
                    :color="item.meeting_right == 'organizer' ? 'primary' : 'secondary'" :outlined="item.meeting_right != 'organizer'"
                    class="ma-0 chip-pointer white--text" small style="justify-content: center;"
                    @click="toggleOrganizer(item)">
                    Organisateur
                  </v-chip>
                </template>
                <template v-else>
                  <v-chip
                    v-if="item.meeting_right == 'organizer'" color="primary"
                    class="ma-0 white--text" small style="justify-content: center;">
                    Organisateur
                  </v-chip>
                </template>
              </td>
              <td class="pa-0">
                <img :src="item.avatar" style="height:2em; border-radius: 50%; vertical-align: middle">
              </td>
              <td>{{ $filters.lowercaseUcfirst(item.firstname) }}</td>
              <td>{{ $filters.lowercaseUcfirst(item.lastname) }}</td>
              <td>{{ item.email }}</td>
              <td class="text-center">
                <div v-if="! ['user', 'consult'].includes(item.access_right)">
                  {{ $t('ACR.' + $filters.uppercase(item.access_right)) }}
                </div>
              </td>
              <td v-show="userIsAdmin && company_users.length > 1" class="text-center">
                <i :title="$t('MANAGE.REMOVE_USER')" class="fa fa-fw fa-ban access-right-icon" @click="removeUser(item)"></i>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .manage-users {
    .chip-pointer .v-chip__content {
      cursor: pointer;
      user-select: none;
    }
  }
</style>

<script>
  import apiSrv from '@/services/apiSrv';
  import ManageSubscriptions from './ManageSubscriptions';
  import mixin from './mixin';

  export default {
    components: {
      ManageSubscriptions,
    },
    mixins: [mixin],
    props: {
      dataLoaded: { type: Object, default: () => {} },
    },
    data() {
      return {
        access_rights_disabled: {},
        meeting_rights_disabled: {},
      };
    },
    computed: {
      usersTableHeaders() {
        const usersTableHeaders = [
          { text: '', value: 'organizer', sortable: false },
          { text: '', value: 'avatar', sortable: false },
          { text: this.$t('MANAGE.FIRSTNAME'), value: 'firstname' },
          { text: this.$t('MANAGE.LASTNAME'), value: 'lastname' },
          { text: this.$t('MANAGE.EMAIL'), value: 'email' },
          { text: this.$t('MANAGE.ROLE'), value: 'role', align: 'center' },
        ];
        if (this.userIsAdmin && this.company_users.length > 1) usersTableHeaders.push({ text: this.$t('MANAGE.REMOVE'), value: 'role', align: 'center', class: 'pa-0' });
        return usersTableHeaders.map(item => ({ ...item, text: item.text.toUpperCase() }));
      },
      sortedCompanyUsers() {
        return _.sortBy(this.company_users, 'firstname');
      },
      organizers_count() {
        return this.company_users.filter(item => item.meeting_right == 'organizer').length;
      },
      maxOrganizersReached() {
        return this.organizers_count >= (this.$store.state.users.user.company && this.$store.state.users.user.company.maxorganizers || 0);
      },
      company_users() { return this.$store.getters['users/getCompanyUsers']; },
      userIsAdmin() { return this.$store.state.users.accessRight.isAdmin; },
    },
    methods: {
      toggleOrganizer(user) {
        this.$set(this.meeting_rights_disabled, user.id, true);
        const oldValue = user.meeting_right;
        const newRight = oldValue == 'organizer' ? '' : 'organizer';
        user.meeting_right = newRight;
        apiSrv.call('users', 'update', { id: user.id, meeting_right: newRight }).then(() => {
          this.$set(this.meeting_rights_disabled, user.id, false);
          if (user.id == this.$store.state.users.user.id) window.location.reload();
        }).catch((error) => {
          user.meeting_right = oldValue;
          this.$set(this.meeting_rights_disabled, user.id, false);
          if (error.message) this.$store.dispatch('ui/msgbox/open', { title: `${this.$t("GLOBAL.ERROR")} : Le statut de l'utilisateur n'a pas été modifié`, body: error.message });
        });
      },
      removeUser(user) {
        let confimMessage;
        if (user.id == this.$store.state.users.user.id) {
          confimMessage = `<i class="fa fa-2x fa-exclamation-triangle red--text"></i> &nbsp;${this.$t("MANAGE.LOSING_SPECIAL_RIGHTS")}`;
        } else {
          confimMessage = this.$t("MANAGE.USER_WILL_BE_REMOVED_FROM_COMPANY");
        }
        this.$store.dispatch('ui/msgbox/open', {
          title: this.$t("MANAGE.REMOVE_USER_FROM_COMPANY", { username: user.email }),
          body: confimMessage,
          buttons: { ok: "GLOBAL.OK", cancel: "GLOBAL.CANCEL" },
        }).then(() => {
          this.dataLoaded.company = false;
          apiSrv.call('companies/users', 'destroy', user.id).then(() => {
            if (user.id == this.$store.state.users.user.id) {
              window.location.reload();
              return;
            }
            this.$emit('load-company');
            if (this.$refs.manageGroups) this.$refs.manageGroups.openedGroup = null;
          }).catch((error) => {
            if (error.message) this.$store.dispatch('ui/msgbox/open', { title: `${this.$t("GLOBAL.ERROR")} : ${this.$t("MANAGE.USER_NOT_REMOVED")}`, body: error.message });
            this.dataLoaded.company = true;
          });
        }).catch(() => {});
      },
    },
  };
</script>
