<template>
  <v-container class="pa-0">
    <v-card color="grey lighten-3 elevation-0">
      <v-card-text>
        <v-row>
          <v-col :class="condensed ? 'col-12' : 'col-md-4'" class="py-0">
            <!-- <div v-if="! condensed" class="mt-2"><b>Utilisateurs du compte</b></div> -->
            <select-user :userlist="usersList" label="Ajouter un participant" empty-after-select @input="addUser($event)"></select-user>
            <template v-if="! condensed">
              <v-btn small outlined block rounded color="secondary" class="mt-2" @click="isOpenedManageSubscriptions = true">Inviter des utilisateurs dans votre compte</v-btn>
              <!-- Externals are pending_invites without invite to join. Goal is to invite to this meeting only an existing or new user.
              Missing parts :
              1. send invite e-mail when meeting is published with an access token (missing in database meeting_pending_users)
              2. handle the connection with token : readonly + invite to join meeting banner; user must be connected (or create account) before accepting to join
              <v-spacer class="mt-12"></v-spacer>
              <b>Externes</b>
              <div class="display-flex align-center">
                <v-text-field v-model="inviteexternal.email" :label="$t('GLOBAL.EMAIL')" :success-messages="inviteexternal.message" :error-messages="inviteexternal.error"
                              type="email" autocomplete="none" style="max-width: 250px">
                </v-text-field>
                <v-btn :disabled="! inviteexternal.email" color="accent" rounded large class="ml-4" @click="addExternal()">Ajouter</v-btn>
              </div> -->
            </template>
          </v-col>
          <v-col cols="12" :class="condensed ? '' : 'col-md-8'" class="pt-0">
            <v-list class="pb-0" two-line style="background: none">
              <div v-for="user in sortedUsers" :key="user.id">
                <v-list-item class="pa-1">
                  <v-list-item-avatar>
                    <img v-if="user.avatar" :src="user.avatar">
                    <v-icon v-else>fas fa-user</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $filters.username(user) }}<i v-if="! user.id" class="text-caption"> (invitation en cours)</i>
                    </v-list-item-title>
                    <v-row align="center" class="ma-0" style="height: 30px">
                      <v-chip v-if="user.id == meeting.owner_id" class="ma-0" small color="primary" style="justify-content: center;">Organisateur</v-chip>
                      <v-chip
                        v-else-if="isOrganizer(user)" :color="accessRightColor(user)"
                        :style="{ visibility: user.id ? null : 'hidden', opacity: user.access_right === 'admin' ? '' : '.5' }"
                        class="ma-0 chip-pointer" small style="justify-content: center;"
                        @click="changeAdmin(user)">
                        Organisateur
                      </v-chip>
                      <div v-if="! condensed" class="ml-4">
                        <v-btn-toggle v-model="user.expected_attendance" @change="changeAttendance(user)">
                          <v-btn v-for="(str, value) in attendanceStrings" :key="str" :value="value" small>
                            {{ str }}
                          </v-btn>
                        </v-btn-toggle>
                      </div>
                    </v-row>
                  </v-list-item-content>
                  <v-list-item-action v-if="user.id != meeting.owner_id">
                    <v-btn icon color="error lighten-1" small outlined @click="removeUser(user)">
                      <v-icon small>fas fa-times</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-btn v-if="! condensed" color="accent" rounded class="mt-2" @click="$emit('stepends')">Étape suivante</v-btn>
    <modal-with-background :is-open="isOpenedManageSubscriptions" @modal-close="isOpenedManageSubscriptions = false">
      <manage-subscriptions class="pt-5"></manage-subscriptions>
    </modal-with-background>
  </v-container>
</template>

<style>
  .chip-pointer .v-chip__content {
    cursor: pointer;
    user-select: none;
  }
</style>

<script>
  import { mapState } from 'vuex';
  import apiSrv from '@/services/apiSrv';
  import ManageSubscriptions from '@/components/Manage/ManageSubscriptions';

  export default {
    components: {
      ManageSubscriptions,
    },
    props: {
      condensed: { required: false, type: Boolean, default: false },
    },
    data() {
      return {
        attendanceStrings: { required: "Obligatoire", optional: "Facultative", no: "Liste de diffusion" },
        isOpenedManageSubscriptions: false,
        pendingUsers: [],
        inviteexternal: { email: "", message: "", error: "" },
      };
    },
    computed: {
      companyUsers() {
        return this.$store.getters['users/getCompanyUsers'];
      },
      usersList() {
        const companyUsersList = this.companyUsers.filter(user => ! this.meeting.users.find(item => item.id == user.id));
        let pendingUsersList = this.pending_invites.filter(invite => ! this.pendingUsers.find(item => item.email == invite.email));
        pendingUsersList = pendingUsersList.map(invite => ({ email: invite.email, pending_user: true })).sort((userA, userB) => (userA.email < userB.email ? -1 : 1));
        return companyUsersList.concat(pendingUsersList);
      },
      sortedUsers() {
        const sortedMeetingUsers = this.meeting.users.slice().sort((userA, userB) => {
          const isBOwner = userB.id == this.meeting.owner_id;
          const isAOwner = userA.id == this.meeting.owner_id;
          const isBAdmin = userB.access_right == 'admin';
          const isAAdmin = userA.access_right == 'admin';
          const compareNames = (`${userA.firstname} ${userA.lastname}`[0].toLowerCase()) < (`${userB.firstname} ${userB.lastname}`[0].toLowerCase);
          const isAFirst = (! isBOwner && (isAOwner || (! isBAdmin && (isAAdmin || compareNames))));
          return isAFirst ? -1 : 1;
        });
        const sortedPendingUsers = this.pendingUsers.slice().sort((userA, userB) => (userA.email < userB.email ? -1 : 1));
        return sortedMeetingUsers.concat(sortedPendingUsers);
      },
      ...mapState('companyinvites', ['pending_invites']),
      ...mapState('current', ['meeting']),
    },
    created() {
      if (! this.meeting.users) this.$set(this.meeting, 'users', []);

      apiSrv.call(`meetings/${this.meeting.id}/pendingusers`, 'index').then((response) => {
        this.pendingUsers = response && response.data && response.data.pending_users || [];
        this.$store.commit('companyinvites/setPendingInvites', response && response.data && response.data.company_pending_invites || []);
      }).catch(() => {});
    },
    methods: {
      saveUser(user, operation) {
        if (user.id) {
          this.meeting.saveUser(user, operation);
        } else {
          this.meeting.savePendingUser(user, operation);
        }
      },
      addUser(newUser) {
        const meetingUser = _.extend({}, newUser, { access_right: 'consult', expected_attendance: 'required' });
        if (meetingUser.id) {
          this.meeting.users.push(meetingUser);
        } else {
          this.pendingUsers.push(meetingUser);
        }
        this.saveUser(meetingUser, 'create');
      },
      addExternal() {
        if (! this.inviteexternal.email) return;
        this.inviteexternal.message = "";
        this.inviteexternal.error = "";
        if (! window.isValidEmailAddress(this.inviteexternal.email)) {
          this.inviteexternal.error = this.$t('MANAGE.INVALID_EMAIL');
          return;
        }
        this.addUser({ email: this.inviteexternal.email, pending_user: true });
        this.inviteexternal.message = `${this.inviteexternal.email} ajouté`;
        this.inviteexternal.email = "";
      },
      changeAdmin(user) {
        user.access_right = user.access_right == "admin" ? "consult" : "admin";
        this.saveUser(user, 'update');
      },
      changeAttendance(user) {
        this.saveUser(user, 'update');
      },
      removeUser(user) {
        const usersArray = user.id ? this.meeting.users : this.pendingUsers;
        const index = usersArray.indexOf(user);
        if (index < 0) return;
        usersArray.splice(index, 1);
        this.saveUser(user, 'delete');
      },
      accessRightColor(user) {
        return user.access_right === 'admin' ? 'success' : 'grey lighten-1';
      },
      isOrganizer(user) {
        const companyUser = this.$store.getters['users/getUserById'](user.id);
        return companyUser && companyUser.meeting_right == 'organizer' || false;
      },
    },
  };
</script>
