<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <file-upload input-id="file-sidedrawer" class="pt-4 pb-2" @uploaded="addFile($event.response && $event.response.file)">
          Faites glisser ou cliquez pour ajouter un document.
        </file-upload>
        <v-form @submit="addLink">
          <v-text-field ref="linkField" v-model="link" :error-messages="linkError" label="Ou collez un lien ici" append-icon="fas fa-check-circle" @click:append="addLink"></v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="file in files" :key="file.id" cols="12" sm="6">
        <a :href="file.url" target="_blank" rel="noopener noreferrer" style="color: inherit; text-decoration: none">
          <v-card color="grey lighten-3" class="pt-2 text-center" style="word-wrap: break-word; height: 100%;">
            <v-icon style="font-size: 50px">{{ $filters.mimeIcon(file.mime) }}</v-icon>
            <v-card-text class="pb-0 accent--text"><b>{{ file.display_filename }}</b></v-card-text>
            <v-card-text class="pt-0"><small>{{ fileDateDisplay(file.updated_at) }}</small></v-card-text>
          </v-card>
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import FileUpload from '@/components/Reusables/FileUpload';

  export default {
    components: { FileUpload },
    data() {
      return {
        link: "",
        linkError: "",
      };
    },
    computed: {
      files: {
        get() { return this.meeting.links; },
        set(val) { this.meeting.links = val; },
      },
      meeting() { return this.$store.state.current.meeting; },
    },
    created() {
      this.files = this.files || [];
    },
    methods: {
      fileDateDisplay(date) {
        return moment(date).format("DD/MM/YY");
      },
      addFile(fileObject) {
        this.meeting.addFile(fileObject);
      },
      addLink() {
        const test = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(this.link);
        if (! test) {
          this.linkError = "Cette url n'est pas valide, avez vous bien mis le http(s) devant ?";
          return;
        }
        const splitted = this.link.split('/');
        let fileInfos = splitted[splitted.length - 1];
        fileInfos = fileInfos.split('.');
        const name = fileInfos[fileInfos.length - 2];
        const obj = {
          created_at: moment().format(),
          display_filename: name,
          url: this.link,
          mime: "link",
        };
        this.meeting.addFile(obj);
      },
    },
  };
</script>
