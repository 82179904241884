<template>
  <div style="position: relative">
    <h1 class="secondary--text pb-8 text-center">{{ step.title }}</h1>
    <v-row>
      <v-col sm="4">
        <issues-card
          :unsorted-issues="unsortedIssues" :module-name="step.module_name" :is-admin="isMeetingAdmin || false"
          @create-issue="createIssue" @clone-issue="cloneIssue" @click-issue="openIssueModal">
        </issues-card>
      </v-col>
      <v-col sm="8">
        <v-row class="align-center">
          <v-col cols="2">
            <img style="width: 100%" src="@/assets/images/issues/drag_path.png">
          </v-col>
          <v-col cols="10" class="ml-0 pt-0 pl-0">
            <animation-issues-actions-card ref="actionCard" :drop-options="dropOptions" :moving-issue="updatingIssue" @open-action-modal="openActionModal">
            </animation-issues-actions-card>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="2">
            <img style="width: 100%" src="@/assets/images/issues/drag_path.png">
          </v-col>
          <v-col cols="10" class="ml-0 pl-0">
            <v-card class="pb-2" style="height: 100%">
              <div class="issues-card-title pa-2 white--text success">
                Arbitrages
                <div class="pointer" style="font-size: 12px; position: absolute; right: 5px; top: 10px" @click="isOpenHistoricDecisions = true">
                  Historique
                </div>
              </div>
              <draggable v-bind="dropOptions" class="white pb-2" style="min-height: 5em; position: relative;" @add.stop="onAddInDecisions($event)">
                <div v-if="! (decisions && decisions.length)" class="issueItem" style="height:1px;width:auto;"></div>
                <div v-for="issue in decisions" :key="issue.id" class="issueItem" @click="openIssueModal(issue, 'decisions')">
                  <issue-item :is-admin="isMeetingAdmin" is-decision>{{ issue.decision }}</issue-item>
                </div>
              </draggable>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="2">
            <img style="width: 100%" src="@/assets/images/issues/drag_path.png">
          </v-col>
          <v-col cols="10" class="ml-0 pb-0 pl-0">
            <v-card class="pb-2" style="height: 100%">
              <div class="issues-card-title pa-2 white--text secondary">Frigo</div>
              <draggable v-bind="dropOptions" class="white pb-2" style="min-height: 5em" @add.stop="onAddInFridge($event)">
                <div v-if="! (fridgeVisibleIssues && fridgeVisibleIssues.length)" class="issueItem " style="height:1px;width:auto;"></div>
                <div v-for="issue in fridgeVisibleIssues" :key="issue.id" class="issueItem" @click="openIssueModal(issue, 'fridge')">
                  <issue-item :is-admin="isMeetingAdmin" :is-in-historic-fridge="historicIssuesIds[issue.id]">{{ issue.title }}</issue-item>
                </div>
              </draggable>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar v-model="isDeletingGroup" :timeout="-1" bottom>
      <b>Supprimer le groupe «{{ deletingGroup && deletingGroup.title }}» et toutes les actions qu'il contient ?<br>Cette suppression ne peut pas être annulée.</b>
      <v-btn class="error--text" text @click.native="confirmDeletingGroup()">Supprimer</v-btn>
      <v-btn text @click.native="isDeletingGroup = false">Annuler</v-btn>
    </v-snackbar>
    <open-action
      :open-modal="isOpenActionModal" :updating-action="updatingAction"
      @cancel="cancelActionModal" @save-action="saveActionModal" @delete-action="deleteAction">
    </open-action>
    <open-issue
      :issue-group="dropedIssueGroup" :open-modal="isOpenSortModal" :updating-issue="updatingIssue"
      @cancel="closeModal" @valid="validateIssueModal" @delete="removeIssue">
    </open-issue>
    <animation-issues-decisions-historic-dialog :is-open="isOpenHistoricDecisions" @modal-close="isOpenHistoricDecisions = false"></animation-issues-decisions-historic-dialog>
  </div>
</template>

<style>
  .issues-card-title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
</style>

<script>
  import { mapState, mapGetters } from 'vuex';
  import draggable from 'vuedraggable';
  import AnimationIssuesActionsCard from './AnimationIssuesActionsCard';
  import IssuesCard from './AnimationIssuesCard';
  import OpenAction from './OpenAction';
  import OpenIssue from './OpenIssue';
  import AnimationIssuesDecisionsHistoricDialog from './AnimationIssuesDecisionsHistoricDialog';
  import IssueItem from './AnimationIssueItem';

  export default {
    components: {
      draggable,
      OpenAction,
      OpenIssue,
      AnimationIssuesActionsCard,
      IssuesCard,
      IssueItem,
      AnimationIssuesDecisionsHistoricDialog,
    },
    props: {
      step: { required: true, type: Object },
    },
    data() {
      return {
        dropOptions: {
          group: {
            name: 'category',
            put: 'issues',
          },
          sort: false,
          draggable: ".issueItem",
          disabled: null,
        },
        isOpenActionModal: false,
        isOpenSortModal: false,
        isOpenHistoricDecisions: false,
        dropedIssueGroup: "",
        updatingIssue: {},
        updatingAction: {},
        deletingGroup: null,
      };
    },
    computed: {
      isDeletingGroup: {
        get() {
          return !! this.deletingGroup;
        },
        set(val) {
          if (val == false) {
            this.deletingGroup = null;
          }
        },
      },
      historicIssuesIds() {
        return this.$store.state.issues.historicFridge.reduce((prevHistoric, item) => {
          prevHistoric[item.id] = true;
          return prevHistoric;
        }, {});
      },
      ...mapState('current', ['meeting', 'meetingUser']),
      ...mapGetters('current', ['isMeetingAdmin']),
      ...mapState('issues', ['decisions']),
      ...mapGetters('issues', ['unsortedIssues', 'fridgeVisibleIssues']),
    },
    watch: {
      step() {
        this.setCurrentUserModule();
      },
    },
    created() {
      this.setCurrentUserModule();
      this.dropOptions.disabled = ! this.isMeetingAdmin;
    },
    methods: {
      createIssue(issue) {
        this.$store.dispatch('issues/add', issue);
      },
      cloneIssue(original) {
        this.updatingIssue = original;
      },
      openIssueModal(issue, group) {
        if (! this.isMeetingAdmin) return;
        this.updatingIssue = issue;
        this.isOpenSortModal = true;
        this.dropedIssueGroup = group;
      },
      dropIssueInAction(issue) {
        const newAction = {
          new: true,
          title: issue.title,
          due_date: null,
          issue,
          meeting_id: this.meeting.id,
        };
        this.openActionModal(newAction);
      },
      validateIssueModal(obj) {
        const { issue, groupIssue: group } = obj;
        if (group == "actions") {
          this.dropIssueInAction(issue);
        } else {
          this.$store.dispatch('issues/updateIssue', { issue, group });
        }
        this.closeModal();
      },
      removeIssue(issue) {
        this.$store.dispatch('issues/removeFromGroup', { issue });
        this.closeModal();
      },
      closeModal() {
        this.dropedIssueGroup = null;
        this.isOpenSortModal = false;
      },
      openActionModal(action) {
        if (! this.isMeetingAdmin) return;
        if (! action) {
          this.dropIssueInAction(this.updatingIssue);
          return;
        }
        this.updatingAction = action;
        this.isOpenActionModal = true;
      },
      cancelActionModal() {
        this.isOpenActionModal = false;
      },
      saveActionModal(action) {
        const card = this.$refs.actionCard;
        this.isOpenActionModal = false;
        return card.changeAction(action).then(() => {
          this.$store.dispatch('issues/changeGroup', { issue: action.issue, newGroup: 'actions' });
        });
      },
      deleteAction(action) {
        this.$store.dispatch('issues/removeFromGroup', { issue: action.issue, totalDelete: false });
        this.$refs.actionCard.deleteAction(action);
        this.isOpenActionModal = false;
      },
      onAddInFridge() {
        this.dropedIssueGroup = "fridge";
        this.isOpenSortModal = true;
      },
      onAddInDecisions() {
        this.dropedIssueGroup = "decisions";
        this.isOpenSortModal = true;
      },
      setCurrentUserModule() {
        const module = this.meeting.getUserModule(this.step.module_name, this.meetingUser, []);
        this.$store.state.issues.currentUserModule = module;
        if (! module.id) {
          this.meeting.sendModule(module, false, true).then((response) => {
            if (! response || ! response.data || ! response.data.meeting_module_user) return;
            module.id = response.data.meeting_module_user.id;
            if (! this.$store.state.issues.modulesContent[module.id]) {
              this.$set(this.$store.state.issues.modulesContent, module.id, module.content);
            }
          });
        }
      },
    },
  };
</script>
