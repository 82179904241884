<template>
  <v-card class="pb-2" style="height: 100%">
    <div class="issues-card-title pa-2 white--text secondary">Actions</div>
    <draggable v-model="actionIssues" v-bind="dropOptions" style="min-height:5em" @add.stop="openModal()">
      <div class="issueItem">
        <v-container v-for="(action, index) in actions" :key="index" class="mx-0 my-0 pa-2">
          <v-row align="center" class="my-0 mx-1">
            <v-col sm="6" cols="12">
              <v-row align="center">
                <img style="height:30px;width:30px;border-radius: 50%;" class="mr-2 pa-1 warning" src="@/assets/images/issues/issue.png">
                <h3 style="display: inline-block;cursor: pointer" @click="openModal(action)">{{ action.title }}</h3>
              </v-row>
            </v-col>
            <v-col sm="6" cols="12" class="pa-0">
              <v-chip v-if="action.completed_at" style="float: right" small class="py-1" color="success" text-color="white">
                Terminé
                <v-icon size="13" class="pr-2" right>fas fa-check-circle</v-icon>
              </v-chip>
              <v-chip v-else style="float: right" small class="py-1" color="accent" text-color="white">
                En cours
                <v-icon size="13" class="pr-2" right>fas fa-hourglass</v-icon>
              </v-chip>
            </v-col>
            <v-col v-if="action.priority == -1"><v-icon>far fa-arrow-alt-circle-down</v-icon></v-col>
            <v-col v-if="action.priority == 1"><v-icon color="error">fas fa-exclamation</v-icon></v-col>
            <v-col sm="7" cols="12">
              {{ action.description }}
            </v-col>
            <v-col class="py-2">
              <div style="float:right">
                <span v-if="action.user_id">
                  <avatar-tooltiped :user="getUser(action.user_id) || { username: action.user_name }">
                  </avatar-tooltiped>
                </span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </draggable>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex';
  import draggable from 'vuedraggable';

  export default {
    components: {
      draggable,
    },
    props: {
      dropOptions: { required: true, type: Object },
      movingIssue: { required: true, type: Object },
    },
    data() {
      return {
        title: "",
        actionIssues: [],
      };
    },
    computed: {
      issues: {
        get() {
          return window.deepcopy(this.actionIssues);
        },
        set(val) {
          this.$emit('update', val);
        },
      },
      actions() {
        const meetingActions = this.$store.getters['actions/getByMeeting'](this.meeting.id);
        return meetingActions.filter(action => action.issue);
      },
      ...mapState('current', ['meeting']),
    },
    methods: {
      getUser(userId) {
        const user = this.meeting.users.find(meetingUser => meetingUser.id == userId);
        return user;
      },
      getIssueModule(issue) {
        return this.stepModules.find(module => module.content && module.content.find(issueItem => issueItem.id == issue.id));
      },
      changeAction(action) {
        const storeAction = action.new ? 'actions/create' : 'actions/update';
        return this.$store.dispatch(storeAction, action).catch((error) => {
          this.$store.dispatch('openErrorModal', `L'action "${action.title}" n'a pas pu être ${action.new ? 'créée' : 'mise à jour'} : ${error.message}`);
          return Promise.reject(error);
        });
      },
      deleteAction(action) {
        return this.$store.dispatch('actions/delete', action).catch((error) => {
          this.$store.dispatch('openErrorModal', `L'action "${action.title}" n'a pas pu être supprimée : ${error.message}`);
          return Promise.reject(error);
        });
      },
      openModal(action) {
        this.$emit('open-action-modal', action);
      },
    },
  };
</script>
