<template>
  <v-container class="pa-0">
    <div class="display-flex flex-column">
      <div>
        <v-card color="grey lighten-3 pa-4 elevation-0">
          <div class="display-flex flex-wrap">
            <div>
              <small style="vertical-align: top;">Charger un déroulé type :</small>
              <div>
                <v-btn color="secondary" outlined dark style="flex-shrink: 1" rounded small @click="resetSteps('operations')">
                  <v-icon small class="mr-2">fas fa-undo</v-icon>Réunion Opérations
                </v-btn>
                <v-btn color="secondary" outlined dark class="mx-2" style="flex-shrink: 1" rounded small @click="resetSteps('projects')">
                  <v-icon small class="mr-2">fas fa-undo</v-icon>Réunion Projet
                </v-btn>
                <v-btn color="secondary" outlined dark style="flex-shrink: 1" rounded small @click="resetSteps('services')">
                  <v-icon small class="mr-2">fas fa-undo</v-icon>Réunion de Service
                </v-btn>
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-btn color="info" rounded outlined style="flex-shrink: 1; cursor: help" @click="onBoardingPage = 0">
              <v-icon left>fas fa-question-circle</v-icon><span>Didacticiel : Les Modules</span>
            </v-btn>
          </div>
          <v-divider class="mt-4 mb-2"></v-divider>
          <draggable :list="stepsChanged" handle=".handle" @update="didChange">
            <div v-for="(step, index) in stepsChanged" :key="index" class="my-1">
              <settings-module-list-item
                v-model="stepsChanged[index]" :temporary-steps="stepsChanged"
                @change="didChange" @delete="deleteStep(step)" @help="help($event)">
              </settings-module-list-item>
            </div>
          </draggable>
          <div class="display-flex align-center">
            <v-btn color="success" rounded class="ma-2" @click="addStep()"><v-icon left>fas fa-plus</v-icon>Ajouter une étape</v-btn>
            <v-spacer></v-spacer>
            <div :class="{ 'error--text': stepsTotalTime > meeting.duration / 60 }" class="mr-12">Durée du déroulé : {{ stepsTotalTime }} min</div>
            <div class="mr-12">Durée de la réunion : {{ meeting.duration / 60 }} min</div>
          </div>
        </v-card>
      </div>
      <v-slide-y-transition>
        <v-btn v-if="changeToSave" color="accent" rounded class="mt-2 mb-6 mx-6" @click.native="saveMeeting">Enregistrer</v-btn>
      </v-slide-y-transition>
      <div class="mt-4">
        <v-card color="grey lighten-3 pa-4 elevation-0">
          <v-btn color="accent" rounded outlined @click="stepsToDescription()"><v-icon left>fas fa-long-arrow-alt-down</v-icon>Créer l'ordre du jour à partir du déroulé</v-btn>
          <v-card-text>
            <v-textarea v-model="descriptionChanged" label="Ordre du jour" color="primary darken-1" @change="didChange">
            </v-textarea>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <v-btn v-if="changeToSave" color="accent" rounded class="mt-2" @click.native="saveMeeting(); stepEnds()">Enregistrer</v-btn>
    <v-btn v-else color="accent" rounded class="mt-2" @click.native="stepEnds">Étape suivante</v-btn>
    <on-boarding ref="onBoardingModal" :start-page="onBoardingPage" config="modules" @close="onBoardingPage = -1"></on-boarding>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';
  import draggable from 'vuedraggable';
  import Meeting from '@/models/Meeting';
  import StepsSrv from '@/services/StepsSrv';
  import OnBoarding from '@/components/OnBoardingModal/OnBoarding';
  import SettingsModuleListItem from './partials/SettingsModuleListItem';

  export default {
    components: {
      draggable,
      OnBoarding,
      SettingsModuleListItem,
    },
    data() {
      return {
        stepsChanged: [],
        descriptionChanged: "",
        changeToSave: false,
        onBoardingPage: -1,
      };
    },
    computed: {
      stepsTotalTime() {
        return this.stepsChanged.reduce((a, item) => a + ((item.timePerAttendee ? this.meeting.getStepAttendees(item).length * item.time : item.time) || 0) / 60, 0);
      },
      ...mapState('current', ['meeting']),
    },
    watch: {
      descriptionChanged(newVal) {
        if (newVal != this.meeting.description) {
          this.didChange();
        }
      },
    },
    created() {
      if (! this.meeting.steps) {
        this.$set(this.meeting, 'steps', StepsSrv.getTemplateSteps(StepsSrv.allTemplate));
      }
      this.stepsChanged = window.deepcopy(this.meeting.steps);
      this.descriptionChanged = this.meeting.description;
    },
    methods: {
      didChange() {
        this.changeToSave = true;
      },
      addStep() {
        this.stepsChanged.push({ module: null, title: null, time: null });
        this.didChange();
      },
      resetSteps(templateName) {
        const template = StepsSrv[`${templateName}Template`];
        if (! template) return;
        this.stepsChanged = StepsSrv.getTemplateSteps(template);
        this.didChange();
      },
      deleteStep(step) {
        const index = this.stepsChanged.indexOf(step);
        if (index != -1) this.stepsChanged.splice(index, 1);
        this.didChange();
      },
      stepsToDescription() {
        this.descriptionChanged = StepsSrv.stepsToDescription(this.stepsChanged);
      },
      saveMeeting() {
        const availableModulesSet = Meeting.getAvailableModulesSet();
        this.meeting.description = this.descriptionChanged;
        this.meeting.steps = window.deepcopy(this.stepsChanged.filter(step => availableModulesSet.has(step.module)));
        this.meeting.saveFields(['steps', 'description']);
        this.changeToSave = false;
      },
      stepEnds() {
        this.$emit('stepends');
      },
      help(module) {
        let index = this.$refs.onBoardingModal.pages.findIndex(page => page.module == module);
        index = index >= 0 ? index : 0;
        this.onBoardingPage = index;
      },
    },
  };
</script>
