<template>
  <v-container class="pa-0">
    <v-row :no-gutters="condensed">
      <v-col :class="condensed ? 'col-12 my-2' : 'col-md-6'">
        <v-card color="grey lighten-3 elevation-0" style="height: 100%">
          <v-card-text>
            <v-text-field
              v-model="changedDetails.title" :placeholder="placeholderTitle" label="Objet de la réunion" hint="Ex : Comité de suivi d'Avril"
              color="primary darken-1" prepend-icon="fas fa-pencil-alt">
            </v-text-field>
            <v-combobox
              v-model="changedDetails.category" :items="availableCategories" :label="'Suite de réunions'" :placeholder="'Selectionnez dans la liste ou entrez une nouvelle'"
              :hint="condensed ? null : categoriesHint" persistent-hint clearable return-object color="primary darken-1" prepend-icon="fas fa-object-group"
              @update="writeCategory($event)" @click:clear="clearCategory = true">
            </v-combobox>
            <!-- EdA specific -->
            <v-autocomplete v-if="flatStructure" :value="changedDetails.custom_fields && changedDetails.custom_fields.structureLevel || ''"
                            :items="flatStructure" :item-text="structure => `${'——————'.slice(0, structure.depth)}${structure.name}`" item-value="id"
                            label="Niveau hiérarchique" color="primary darken-1" prepend-icon="fas fa-sitemap" clearable :class="{ 'mt-2': ! condensed }"
                            @input="changedDetails.custom_fields ? changedDetails.custom_fields.structureLevel = $event : changedDetails.custom_fields = { structureLevel: $event }">
            </v-autocomplete>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :class="condensed ? 'col-12 my-2' : 'col-md-6'">
        <v-card color="grey lighten-3 elevation-0" style="height: 100%">
          <v-card-text>
            <v-text-field
              id="locationAdressInput" v-model="changedDetails.location_address"
              label="Adresse ou lien visioconférence" hint="Recherchez une adresse ou saisissez un lien ou un texte"
              color="primary darken-1" :prepend-icon="(changedDetails.location_address || '').startsWith('http') ? 'fas fa-webcam' : 'fas fa-map-marker-alt'"
              @input.once="loadGoogleAdress">
            </v-text-field>
            <v-textarea
              v-model="changedDetails.location" label="Précisions de rendez-vous" rows="3" hide-details
              color="primary darken-1" prepend-icon="fas fa-map-marked-alt" class="mb-1">
            </v-textarea>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :class="condensed ? 'my-2' : ''" cols="12">
        <v-card color="grey lighten-3 elevation-0">
          <v-card-text>
            <v-text-field
              v-model="displayedDate" :rules="[d => ! isDatePast(changedDetails.date) || 'La date est dépassée']"
              label="Date de la réunion" prepend-icon="far fa-calendar-alt" readonly color="primary darken-1">
            </v-text-field>
            <div class="display-flex flex-wrap ml-4">
              <div class="mb-2" style="flex: 1 0 50%">
                <v-date-picker v-model="dateDay" color="accent" first-day-of-week="1" locale="fr" no-title class="elevation-0"></v-date-picker>
              </div>
              <div class="mb-2" style="flex: 1 0 50%">
                <v-card class="pl-6 py-4 pr-12 elevation-0" style="height: 100%">
                  <div class="display-flex"><v-icon class="mr-2" size="16">far fa-clock</v-icon><b>Début</b></div>
                  <div class="display-flex align-center">
                    <v-text-field v-model="startHours" type="number" min="0" max="23" label="HH" color="primary darken-1"></v-text-field>
                    <div class="px-4">:</div>
                    <v-text-field v-model="startMinutes" type="number" min="0" max="59" step="15" label="MM" color="primary darken-1"></v-text-field>
                  </div>
                  <v-spacer class="my-4"></v-spacer>
                  <div class="display-flex"><v-icon class="mr-2" size="16" style="width: 16px">far fa-hourglass</v-icon><b>Durée</b></div>
                  <div class="display-flex align-center">
                    <v-text-field v-model="durationHours" type="number" min="0" max="23" label="HH" color="primary darken-1"></v-text-field>
                    <div class="px-4">:</div>
                    <v-text-field v-model="durationMinutes" type="number" min="0" max="59" step="15" label="MM" color="primary darken-1"></v-text-field>
                  </div>
                </v-card>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-btn color="accent" rounded @click.native="saveMeeting">Enregistrer</v-btn>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';
  import apiSrv from '@/services/apiSrv';
  import clientConfig from '@/client_customs/config';

  export default {
    props: {
      condensed: { required: false, type: Boolean, default: false },
    },
    data() {
      return {
        availableCategories: [],
        clearCategory: false,
        changedDetails: {
          title: null,
          date: null,
          duration: null,
          owner_id: null,
          category: null,
          location_address: null,
          location: null,
          custom_fields: null,
        },
        flatStructure: clientConfig.flatStructure,
        categoriesHint: `Regroupez plusieurs réunions qui se suivent au sein d'une suite de réunions : réunions de suivi d'un projet, réunions d'équipe récurrentes, ...
        Les actions et les décisions seront repris dans les futures réunions de cette suite.`,
      };
    },
    computed: {
      placeholderTitle() {
        const { meetingUser } = this.$store.state.current;
        return this.meeting.owner_id == meetingUser.id ? `Réunion de ${this.$store.getters['users/getUsername'](meetingUser)}` : 'Réunion';
      },
      dateDay: {
        get() {
          const { date } = this.changedDetails;
          return date && moment(date).isValid() ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
        },
        set(newValue) {
          const meetingDate = moment(this.changedDetails.date).isValid() ? moment(this.changedDetails.date) : moment();
          const newdate = moment(newValue);
          if (! newdate.isValid()) return;
          this.changedDetails.date = meetingDate.year(newdate.year()).month(newdate.month()).date(newdate.date()).format();
        },
      },
      startHours: {
        get() {
          const { date } = this.changedDetails;
          return date ? window.fTime(moment(date || '').hours()) : window.fTime(moment().hours());
        },
        set(newValue) {
          const { date } = this.changedDetails;
          this.changedDetails.date = moment(date).hours(newValue).format();
        },
      },
      startMinutes: {
        get() {
          const { date } = this.changedDetails;
          return date ? window.fTime(moment(date || '').minutes()) : window.fTime(moment().minutes());
        },
        set(newValue) {
          const { date } = this.changedDetails;
          this.changedDetails.date = moment(date).minutes(newValue).format();
        },
      },
      durationHours: {
        get() {
          const { duration } = this.changedDetails;
          return window.fTime(Math.floor(duration / 3600));
        },
        set(newValue) {
          this.changedDetails.duration = newValue * 3600 + this.durationMinutes * 60;
        },
      },
      durationMinutes: {
        get() {
          const { duration } = this.changedDetails;
          return window.fTime(Math.round(duration / 60) % 60);
        },
        set(newValue) {
          this.changedDetails.duration = this.durationHours * 3600 + newValue * 60;
        },
      },
      displayedDate() {
        const date = this.changedDetails.date ? moment(this.changedDetails.date) : moment();
        const endDate = moment(date).add(this.changedDetails.duration, 'seconds');
        const hours = this.changedDetails.duration ? `[de] ${date.format("HH:mm")} [à] ${endDate.format("HH:mm")}` : date.format("[[à]] HH:mm");
        return date.format(`dddd DD MMMM YYYY ${hours}`);
      },
      ...mapState('current', ['meeting']),
    },
    watch: {
      'changedDetails.location_address': function (newVal) {
        if (newVal && newVal.startsWith('meet.google.com/')) this.changedDetails.location_address = `https://${this.changedDetails.location_address}`;
      },
    },
    created() {
      this.resetChangedDetails();
      if (! this.meeting.duration) this.$set(this.meeting, 'duration', 3600);
      apiSrv.call('meetings/categories', 'index', null).then((response) => {
        const categories = response && response.data && response.data.categories;
        this.availableCategories = categories;
      });
    },
    methods: {
      saveMeeting() {
        Object.keys(this.changedDetails).forEach((key) => {
          this.meeting[key] = this.changedDetails[key];
        });
        if (this.clearCategory) this.meeting.category = null;
        this.meeting.title = this.meeting.title || this.placeholderTitle;
        this.$emit('stepends');
        this.meeting.saveFields(['title', 'category', 'duration', 'owner_id', 'date', 'location_address', 'location', 'custom_fields']);
        this.resetChangedDetails();
      },
      resetChangedDetails() {
        Object.assign(this.changedDetails, {
          title: this.meeting.title,
          date: this.meeting.date,
          duration: this.meeting.duration,
          owner_id: this.meeting.owner_id,
          category: this.meeting.category,
          location_address: this.meeting.location_address,
          location: this.meeting.location,
          custom_fields: this.meeting.custom_fields,
        });
      },
      writeCategory(string) {
        this.changedDetails.category = string;
      },
      isDatePast(date) {
        return moment(date).isBefore();
      },
      async loadGoogleAdress() {
        if (! window.google || ! window.google.maps) {
          await new Promise((resolve) => {
            const script = document.createElement('script');
            script.onload = () => {
              resolve();
            };
            script.async = true;
            script.src = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDILEYNutomMXh8mOQiGPpfQNh_65Q_NPU';
            document.head.appendChild(script);
          });
        }
        if (! window.google || ! window.google.maps) return;

        const input = document.getElementById('locationAdressInput');
        if (! input) return;
        const options = {
          bounds: new window.google.maps.LatLngBounds(new window.google.maps.LatLng(43.153691, -1.264649), new window.google.maps.LatLng(49.451767, 6.370557)),
          types: ['geocode'],
        };
        const autocomplete = new window.google.maps.places.Autocomplete(input, options);
        input.placeholder = "";
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          this.$nextTick(() => {
            this.changedDetails.location_address = place.formatted_address;
          });
        });
      },
    },
  };
</script>
