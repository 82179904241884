<template>
  <div style="position: relative">
    <h1 class="secondary--text pb-8 text-center">{{ step.title }}</h1>
    <attendees-list :step="step" :dynamic-module="dynamicModule" :selected-attendee="currentAttendee" @select="selectAttendee"></attendees-list>

    <div v-if="currentReview" class="mt-4 pa-4">
      <h2>Qu'en pensez-vous {{ $filters.username(currentAttendee) }} ?</h2>
      <div class="display-flex justify-start my-4">
        <v-btn :color="currentReview.mark == 4 ? 'primary' : 'grey'" large class="mx-1" @click="setReviewMark(4)">
          <v-icon class="my-2 mr-1">far fa-grin-beam</v-icon>
        </v-btn>
        <v-btn :color="currentReview.mark == 3 ? 'primary' : 'grey'" large class="mx-1" @click="setReviewMark(3)">
          <v-icon class="my-2 mr-1">far fa-smile</v-icon>
        </v-btn>
        <v-btn :color="currentReview.mark == 2 ? 'primary' : 'grey'" large class="mx-1" @click="setReviewMark(2)">
          <v-icon class="my-2 mr-1">far fa-meh</v-icon>
        </v-btn>
        <v-btn :color="currentReview.mark == 1 ? 'primary' : 'grey'" large class="mx-1" @click="setReviewMark(1)">
          <v-icon class="my-2 mr-1">far fa-frown</v-icon>
        </v-btn>
      </div>
      <v-textarea
        v-model="reviewText" :disabled="! dynamicModule" filled hide-details single-line placeholder="Saisissez votre texte ici"
        @change="saveModule">
      </v-textarea>
    </div>
    <v-card v-else class="pa-6 pb-12" style="opacity: 0.3">
      <span class="text-h6">Sélectionnez un participant</span>
    </v-card>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import AttendeesList from './partials/AttendeesList';
  import TimePerAttendeeMixin from './partials/TimePerAttendeeMixin';

  export default {
    components: { AttendeesList },
    mixins: [TimePerAttendeeMixin],
    props: {
      step: { required: true, type: Object },
      dynamicModule: { required: false, type: Boolean, default: true },
    },
    data() {
      return {
        module: {},
        reviews: {},
        currentReview: null,
        currentAttendee: null,
      };
    },
    computed: {
      ...mapState('current', ['meeting']),
      reviewText: {
        get() { return this.currentReview && this.currentReview.text || ""; },
        set(newVal) {
          if (this.currentReview) {
            this.currentReview.text = newVal;
          }
        },
      },
    },
    created() {
      this.step.users_time = this.step.users_time || {};
      const { meetingUser } = this.$store.state.current;
      this.module = this.meeting.getUserModule(this.step.module_name, meetingUser, []);
      this.reviews = this.meeting.users.reduce((prevReviews, user) => {
        const review = this.module.content && this.module.content[user.id] || { text: "", mark: null };
        prevReviews[user.id] = review;
        return prevReviews;
      }, {});
      this.module.content = this.reviews;
    },
    methods: {
      selectAttendee(user) {
        if (this.dynamicModule) {
          if (this.currentAttendee) {
            if (this.currentAttendee.id == user.id) {
              this.currentReview = null;
              this.currentAttendee = null;
              this.$emit('stop-turn');
              return;
            }
            this.$emit('stop-turn');
          }
          this.$emit('begin-turn');
        }
        this.currentReview = this.reviews[user.id];
        this.currentAttendee = user;
      },
      saveModule() {
        if (this.dynamicModule) this.meeting.sendModule(this.module);
      },
      setReviewMark(mark) {
        if (! this.dynamicModule) return;
        this.currentReview.mark = mark;
        this.saveModule();
      },
    },
  };
</script>
