<template>
  <div style="position: relative">
    <h1 class="secondary--text pb-8 text-center">{{ step.title }}</h1>
    <v-slide-y-transition>
      <v-card>
        <v-simple-table v-if="infoUser == null">
          <thead>
            <tr>
              <th style="width: 1px"></th>
              <th class="text-h5">Intervenant</th>
              <th class="text-h5 px-1">Humeur</th>
              <th class="text-h5 px-1">Actualité</th>
              <th class="text-h5 px-1">Top</th>
              <th class="text-h5 px-1">Flop</th>
              <th class="text-h5 px-1">Equipe</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="userMood in sortedMoods" :key="userMood.user_id" :class="isSelected(userMood)">
              <td class="pr-0">
                <v-hover>
                  <template #default="{ hover: hoverButton }">
                    <v-btn v-if="getStatus(userMood.user_id) == 'done'" :disabled="! dynamicModule" color="white" fab small @click="chooseUserTalking(userMood)">
                      <v-icon color="primary" size="18">fas fa-check</v-icon>
                    </v-btn>
                    <v-btn v-else-if="getStatus(userMood.user_id) == 'play'" :disabled="! dynamicModule" color="primary" fab small @click="validateUserTalking(userMood)">
                      <v-icon color="white" size="20">fas fa-play</v-icon>
                    </v-btn>
                    <v-btn v-else :disabled="! dynamicModule" color="white" fab small @click="chooseUserTalking(userMood)">
                      <v-icon :size="hoverButton ? 20 : 8" color="primary">fas fa-play</v-icon>
                    </v-btn>
                  </template>
                </v-hover>
              </td>
              <td class="py-1">
                <div class="text-subtitle-1 pointer" @click="infoUser = userMood">
                  <b>{{ userMood.user_name }}</b>
                </div>
                <div v-if="step.users_time && step.users_time[userMood.user_id]" class="display-flex align-center mt-1">
                  <v-icon v-if="step.users_time[userMood.user_id] > step.time" small>fas fa-microphone-slash</v-icon>
                  <v-icon v-else small>far fa-thumbs-up</v-icon>
                  <span class="mx-1 text-caption">{{ getTimeFormat(step.users_time[userMood.user_id]) }}</span>
                </div>
              </td>
              <td class="text-left"><div :class="userMood.mood" class="mood-icon"></div></td>
              <td class="text-left">{{ truncateMoodContent(userMood.past_news) }}</td>
              <td class="text-left">{{ truncateMoodContent(userMood.top) }}</td>
              <td class="text-left">{{ truncateMoodContent(userMood.flop) }}</td>
              <td class="text-left">{{ truncateMoodContent(userMood.team) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-slide-y-transition>
    <v-slide-y-reverse-transition>
      <div v-if="infoUser">
        <div class="display-flex mb-4">
          <div class="display-flex mb-2">
            <v-btn color="secondary" rounded outlined @click="infoUser = null"><v-icon small class="mr-2">fas fa-long-arrow-alt-left</v-icon>Retour</v-btn>
            <v-btn v-if="(talkingUser && talkingUser != infoUser)" rounded class="accent" @click="infoUser = talkingUser">
              <v-avatar v-if="talkingUser.user_avatar" size="32"><img :src="talkingUser.user_avatar"></v-avatar>
              <v-icon class="ml-2">fas fa-backward</v-icon>
            </v-btn>
          </div>
          <div class="display-flex align-center" style="margin: auto">
            <v-avatar v-if="infoUser.user_avatar" class="mr-2" size="42"><img :src="infoUser.user_avatar"></v-avatar>
            <h2 class="text-h5">{{ infoUser.user_name }}</h2>
          </div>
          <v-btn v-if="infoUser == talkingUser" :disabled="! dynamicModule" color="accent" rounded @click="validateUserTalking()">
            <v-icon small class="mr-2">fas fa-check</v-icon>Terminer
          </v-btn>
          <v-btn v-else :disabled="! dynamicModule" color="accent" fab small @click="chooseUserTalking(infoUser)">
            <v-icon color="white" size="20">fas fa-play</v-icon>
          </v-btn>
        </div>
        <v-divider class="mb-4"></v-divider>
        <!-- MOOD & PAST NEWS -->
        <div v-if="infoUser.mood || (infoUser.past_news_files && infoUser.past_news_files.length) || hasContent(infoUser.past_news)" class="pa-2 display-flex">
          <div v-if="infoUser.mood" class="elevation-3 align-center justify-center mx-6" style="width: 80px; height: 80px; border-radius: 50%; flex: 0 0 auto; display: flex">
            <div :class="infoUser.mood" class="mood-icon"></div>
          </div>
          <div v-if="hasContent(infoUser.past_news)" class="pa-4">
            <div class="text-h6 secondary--text display-flex">
              <v-icon color="secondary" class="mr-2">far fa-newspaper</v-icon>Élements d'actualité à partager
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="pre" v-html="infoUser.past_news"></div>
            <animation-moods-slider v-if="infoUser.past_news_files && infoUser.past_news_files.length" :files="infoUser.past_news_files" class="mt-2"></animation-moods-slider>
          </div>
        </div>
        <!-- TOP & FLOP -->
        <v-row v-if="(infoUser.team_files && infoUser.team_files.length) || (infoUser.flop_files && infoUser.flop_files.length) || hasContent(infoUser.top) || hasContent(infoUser.flop)" class="pa-2">
          <v-col cols="6">
            <div class="pa-4">
              <div class="text-h6 success--text display-flex">
                <v-icon color="success" class="mr-2">far fa-thumbs-up</v-icon>Top
              </div>
              <v-divider class="my-2"></v-divider>
              <div class="pre" v-html="infoUser.top"></div>
              <animation-moods-slider v-if="infoUser.top_files && infoUser.top_files.length" :files="infoUser.top_files" full-width class="mt-2"></animation-moods-slider>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="pa-4">
              <div class="text-h6 error--text display-flex">
                <v-icon color="error" class="mr-2">far fa-thumbs-down</v-icon>Flop
              </div>
              <v-divider class="my-2"></v-divider>
              <div class="pre" v-html="infoUser.flop"></div>
              <animation-moods-slider v-if="infoUser.flop_files && infoUser.flop_files.length" :files="infoUser.flop_files" full-width class="mt-2"></animation-moods-slider>
            </div>
          </v-col>
        </v-row>
        <!-- TEAM -->
        <div v-if="(infoUser.team_files && infoUser.team_files.length) || hasContent(infoUser.team)" class="pa-6">
          <div class="text-h6 secondary--text display-flex">
            <v-icon color="secondary" class="mr-2">fas fa-user-friends</v-icon>Équipe : Arrivée(s) / Départ(s)
          </div>
          <v-divider class="my-2"></v-divider>
          <div class="pre" v-html="infoUser.team"></div>
          <animation-moods-slider v-if="infoUser.team_files && infoUser.team_files.length" :files="infoUser.team_files" class="mt-2"></animation-moods-slider>
        </div>
      </div>
    </v-slide-y-reverse-transition>
  </div>
</template>

<style scoped>
  .mood-icon {
    width: 52px;
    height: 52px;
  }
</style>

<script>
  import { mapState } from 'vuex';
  import AnimationMoodsSlider from './AnimationMoodsSlider';

  export default {
    components: { AnimationMoodsSlider },
    props: {
      step: { required: true, type: Object },
      dynamicModule: { type: Boolean, default: true },
    },
    data() {
      return {
        talkingUser: null,
        lastTalkingUser: false,
        infoUser: null,
      };
    },
    computed: {
      sortedMoods() {
        const modules = this.meeting.modules.filter(moduleItem => moduleItem.module_name == this.step.module_name);
        const moods = this.meeting.getStepAttendees(this.step).map((user) => {
          const mood = modules.find(module => module.user_id == user.id);
          if (mood) {
            return {
              ...mood.content,
              user_id: user.id,
              user_avatar: user.avatar,
              user_name: this.$store.getters['users/getUsername'](user),
              validated_date: mood.first_validated_at || moment().format(),
            };
          }
          return {
            user_id: user.id,
            user_avatar: user.avatar,
            user_name: this.$store.getters['users/getUsername'](user),
            validated_date: moment().format(),
          };
        });
        moods.sort((moodA, moodB) => moodA.validated_date > moodB.validated_date);
        return moods;
      },
      ...mapState('current', ['meeting']),
    },
    watch: {
      step: {
        handler(step) {
          if (_.isArray(step.users_time)) step.users_time = {};
          step.users_time = step.users_time || {};
        },
        immediate: true,
      },
    },
    methods: {
      chooseUserTalking(moodUser) {
        this.infoUser = moodUser;
        if (this.dynamicModule) {
          this.talkingUser = moodUser;
          this.$emit('begin-turn');
        }
      },
      validateUserTalking() {
        this.$emit('stop-turn');
        this.talkingUser = null;
        this.infoUser = null;
      },
      getStatus(id) {
        if (this.talkingUser && this.talkingUser.user_id == id) return 'play';
        return this.step.users_time[id] ? 'done' : '';
      },
      startOfTurn() {
        if (! this.talkingUser && this.sortedMoods.length) {
          const nextMood = this.sortedMoods.find(item => this.getStatus(item.user_id) != 'done');
          if (nextMood) this.chooseUserTalking(nextMood);
        }
      },
      endOfTurn(time) {
        if (! this.sortedMoods.find(item => ! this.step.users_time[item.user_id])) {
          this.$emit('end-of-step');
        }
        const user = this.talkingUser;
        if (! user) return;
        this.step.users_time[user.user_id] = time;
        this.talkingUser = null;
        this.infoUser = null;
      },
      getTimeFormat(time) {
        let minutes = Math.floor(Math.abs(time) / 60) || 0;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        let seconds = Math.abs(Math.round(time) % 60);
        seconds = seconds < 10 ? `0${seconds}` : seconds;
        return `${minutes}:${seconds}`;
      },
      isSelected(item) {
        return this.talkingUser && this.talkingUser == item ? 'grey lighten-4' : '';
      },
      truncateMoodContent(html) {
        return this.$filters.truncate(window.html2text(html || '').trim(), 40, ' ...', true);
      },
      hasContent(html) {
        if (! html) return false;
        return !! window.html2text(html || '').trim();
      },
    },
  };
</script>
