import Vue from 'vue';

Vue.directive('fixToTop', {
  inserted(el, binding) {
    let offsetTop;
    let startTop;
    let height;
    let placeholder;
    const options = binding.value || {};

    if (options.placeholder) placeholder = el.parentNode.querySelector(options.placeholder);
    let elementReady = false;

    function updateClip() {
      if (el.classList.contains('fixed')) {
        el.style.top = `${offsetTop}px`;
      } else {
        el.style.top = '';
      }
    }

    function getScrollTop() {
      return document.documentElement.scrollTop || document.body.scrollTop;
    }

    function updateElementVars() {
      offsetTop = (options.topbar ? document.querySelector(options.topbar).offsetHeight : 0) || 0;
      startTop = Math.max(0, el.getBoundingClientRect().top + getScrollTop() - offsetTop);
      height = el.offsetHeight;
      if (placeholder) {
        placeholder.style.display = 'none';
        placeholder.style.height = `${height}px`;
      }
      elementReady = height > 0;
    }

    function onScroll() {
      if (! elementReady) return;
      if (getScrollTop() > startTop) {
        if (! el.classList.contains('fixed')) updateElementVars();
        el.classList.add('fixed');
        if (placeholder) placeholder.style.display = 'block';
      } else {
        el.classList.remove('fixed');
        if (placeholder) placeholder.style.display = 'none';
      }
      updateClip();
    }

    window.addEventListener('scroll', onScroll);

    function init() {
      updateElementVars();
      if (elementReady) {
        onScroll();
      } else {
        setTimeout(init, 300);
      }
    }
    setTimeout(init);
    el.onScroll = onScroll;
  },
  unbind(el) {
    window.removeEventListener('scroll', el.onScroll);
  },
});
