<template>
  <div>
    <h1 class="secondary--text pb-8 text-center">{{ step.title }}</h1>
    <div class="pa-6" style="font-size: 130%;">
      <div v-for="text in texts">
        <b v-if="text.user"> Par {{ $filters.username(text.user) }}: </b>
        <div v-html="text.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      step: { required: true, type: Object },
    },
    data() {
      return {
        texts: [],
      };
    },
    watch: {
      step(step) {
        this.changeStep(step);
      },
    },
    created() {
      this.changeStep();
    },
    methods: {
      changeStep() {
        const { meeting } = this.$store.state.current;
        const modules = meeting.modules.filter(module => module.module_name == this.step.module_name);
        if (modules.length == 1) {
          this.texts = [{ content: modules[0].content.text }];
        } else {
          this.texts = modules.map(module => ({ user: meeting.users.find(user => user.id == module.user_id), content: module.content.text }));
        }
      },
    },
  };
</script>
