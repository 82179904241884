<template>
  <v-dialog v-model="toggleOpen" :max-width="maxWidth" no-click-animation scrollable v-bind="$attrs">
    <!-- v-card required for scrollable -->
    <v-card :class="bgColor" style="position: relative;">
      <template v-if="title">
        <v-card-title class="white display-flex pr-4">
          <div style="font-size: 20px;">{{ title }}</div>
          <v-spacer></v-spacer>
          <v-btn v-if="! noClose" color="grey" text icon @click="$emit('modal-close')"><v-icon>fas fa-times-circle</v-icon></v-btn>
        </v-card-title>
        <v-divider class="mb-4"></v-divider>
      </template>
      <template v-else-if="! noClose">
        <div style="position: relative">
          <v-btn color="grey" text icon style="position: absolute; top: 6px; right: 12px; z-index: 1" @click="$emit('modal-close')"><v-icon>fas fa-times-circle</v-icon></v-btn>
        </div>
      </template>
      <v-card-text :class="bgColor" style="color: inherit">
        <slot></slot>
      </v-card-text>
      <template v-if="$slots.footer">
        <v-divider></v-divider>
        <v-card-actions class="white">
          <slot name="footer"></slot>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    inheritAttrs: false,
    props: {
      title: { type: String, default: null },
      isOpen: { required: true, type: Boolean },
      maxWidth: { type: Number, default: 700 },
      noClose: { type: Boolean, default: false },
      bgColor: { type: String, default: 'white' },
    },
    computed: {
      toggleOpen: {
        get() {
          return this.isOpen;
        },
        set(newVal) {
          if (! newVal) this.$emit('modal-close');
        },
      },
    },
  };
</script>
