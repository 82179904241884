<template>
  <div>
    <container-template :fluid="animationIsAvailable" :slot-class="animationIsAvailable ? 'px-0 pt-6 pb-2' : ''">
      <div v-if="animationIsAvailable" class="display-flex">
        <div
          :style="$vuetify.breakpoint.name == 'sm' ? { 'max-width': '200px' } : { 'min-width': '200px', 'max-width': '200px' }"
          class="pt-4 secondary--text">
          <div
            v-fix-to-top="{ topbar: '#topbar', placeholder: '#timer-wrapper-placeholder' }"
            class="timer-wrapper white px-2" style="z-index: 3; border-radius: 0px 0px 50% 50%;">
            <div v-if="statusIsAnimation && isMeetingAdmin && allStepsDone" class="text-center py-2" style="width: 184px">
              <v-btn color="accent" rounded @click="endAnimation">Fin de réunion</v-btn>
            </div>
            <animation-timer
              v-else ref="timer" :minutes="timer"
              :disable-buttons="! currentStep || ! dynamicModule"
              @timer-play="playCurrentStep()" @timer-validate="validateCurrentStep(); playNextStep()">
            </animation-timer>
          </div>
          <div id="timer-wrapper-placeholder"></div>
          <div class="mt-4 grey lighten-4" style="min-height: 60px; border-style: solid; border-width: 2px 0 0;">
            <div
              v-for="step in filteredMeetingSteps" :key="step.module_name"
              :class="currentStep && step.module_name == currentStep.module_name ? 'white primary--text' : ''"
              class="display-flex align-center pa-1"
              style="border-top: 1px solid transparent; border-bottom: 1px solid white">
              <v-hover>
                <template #default="{ hover: hoverButton }">
                  <v-btn :color="! statusIsAnimation ? 'white' : step.status == 'play' ? 'primary' : 'white'" fab small class="my-1 mx-2" @click="clickStep(step)">
                    <v-icon v-if="step.status == 'done'" :color="! statusIsAnimation ? 'grey lighten-1' : 'primary'" size="18">fas fa-check</v-icon>
                    <v-icon v-else-if="step.status == 'play'" :color="! statusIsAnimation ? 'grey lighten-1' : 'white'" size="20">fas fa-play</v-icon>
                    <v-icon v-else-if="statusIsAnimation" :size="hoverButton ? 20 : 8" color="primary">fas fa-play</v-icon>
                    <v-icon v-else color="grey lighten-1" size="12">fas fa-circle</v-icon>
                  </v-btn>
                </template>
              </v-hover>
              <div class="mx-1">
                <h4 class="pointer" @click="displayedStep = step">{{ $filters.truncate(step.title, 42, '...', true) }}</h4>
                <div v-if="timeSecInMin(step.time)" style="font-style: italic;">
                  {{ timeSecInMin(step.time) }} min
                  <span v-if="step.timePerAttendee"> / pers.</span>
                </div>
              </div>
            </div>
            <div v-if="statusIsAnimation && isMeetingAdmin" class="pt-4 pb-12 text-center">
              <v-btn :color="allStepsDone ? 'accent' : 'secondary'" :outlined="! allStepsDone" rounded
                     @click="endAnimation">
                Fin de réunion
              </v-btn>
            </div>
          </div>
        </div>
        <div style="overflow-x: auto; flex: 1 1 100%">
          <div class="pa-4 animation-components">
            <template v-if="displayedStep">
              <keep-alive>
                <component
                  :is="'animation-' + currentStep.module" v-show="displayedStep == currentStep" ref="currentModule"
                  :step="currentStep" :dynamic-module="dynamicModule" class="mb-6"
                  @begin-turn="playTimer" @stop-turn="validateCurrentStep(); playNextStep()"
                  @end-of-step="validateCurrentStep(true); playNextStep();">
                </component>
              </keep-alive>
              <component
                :is="'animation-' + displayedStep.module" v-show="displayedStep != currentStep"
                :step="displayedStep" :dynamic-module="false" class="mb-6">
              </component>
            </template>
            <div v-else>Aucun module sélectionné.</div>
          </div>
        </div>
      </div>
      <div v-else-if="! filteredMeetingSteps.length && isMeetingAdmin">
        <p>Cette réunion n'a aucune étape définie dans son déroulé. Vous pouvez vous rendre dans les paramètres pour en ajouter.</p>
        <v-btn :to="{ name: 'Settings' }" color="secondary" rounded right><i class="fa fa-cog mr-2"></i>Modifier les paramètres</v-btn>
      </div>
      <div v-else>La réunion n'est pas encore démarrée.</div>
    </container-template>
  </div>
</template>

<style>
  .animation-components img {
    max-width: 100%;
  }
  .timer-wrapper.fixed {
    position: fixed;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12)!important;
    padding-bottom: 8px;
  }
</style>

<script>
  import { mapState, mapGetters } from 'vuex';
  import Meeting from '@/models/Meeting';
  import ContainerTemplate from '@/components/Reusables/ContainerTemplate';
  import AnimationTimer from '@/components/Animation/AnimationTimer';
  import AnimationMoods from '@/components/Animation/AnimationMoods';
  import AnimationKpis from '@/components/Animation/AnimationKpis';
  import AnimationNews from '@/components/Animation/AnimationNews';
  import AnimationIssues from '@/components/Animation/AnimationIssues/AnimationIssues';
  import AnimationTopics from '@/components/Animation/AnimationTopics';
  import AnimationActionsEvolution from '@/components/Animation/AnimationActionsEvolution';
  import AnimationReviews from '@/components/Animation/AnimationReviews';

  export default {
    components: {
      ContainerTemplate,
      AnimationTimer,
      AnimationMoods,
      AnimationKpis,
      AnimationNews,
      AnimationIssues,
      AnimationTopics,
      'animation-actionsevolution': AnimationActionsEvolution,
      AnimationReviews,
    },
    data() {
      return {
        currentStep: null,
        displayedStep: null,
        timer: 0,
      };
    },
    computed: {
      animationIsAvailable() {
        return Meeting.statusIndex(this.meeting.status) >= Meeting.statusIndex('animation');
      },
      statusIsAnimation() {
        return this.meeting.status == 'animation';
      },
      dynamicModule() {
        return this.isMeetingAdmin && this.statusIsAnimation;
      },
      allStepsDone() {
        return ! this.filteredMeetingSteps.find(step => step.status !== 'done');
      },
      availableModulesSet() {
        return Meeting.getAvailableModulesSet('animation', true);
      },
      filteredMeetingSteps() {
        return (this.meeting.steps || []).filter(step => this.availableModulesSet.has(step.module));
      },
      ...mapState('current', ['meeting']),
      ...mapGetters('current', ['isMeetingAdmin']),
    },
    watch: {
      currentStep() {
        this.displayedStep = this.currentStep;
      },
    },
    created() {
      this.initCurrentStep();
    },
    methods: {
      initCurrentStep() {
        if (! this.filteredMeetingSteps.length) {
          this.currentStep = null;
          this.timer = 0;
          return;
        }
        const previousStepIndex = this.filteredMeetingSteps.indexOf(this.currentStep);
        let meetingStepsReordered = this.filteredMeetingSteps;
        if (previousStepIndex > -1) meetingStepsReordered = this.filteredMeetingSteps.slice(previousStepIndex).concat(this.filteredMeetingSteps.slice(0, previousStepIndex));
        this.currentStep = meetingStepsReordered.find(step => step.status == 'play');
        if (! this.currentStep) this.currentStep = meetingStepsReordered.find(step => step.status != 'done');
        if (! this.currentStep) [this.currentStep] = meetingStepsReordered;

        this.resetTimer();
      },
      playTimer() {
        if (this.$refs.timer) this.$refs.timer.play();
      },
      stopTimer() {
        if (this.$refs.timer) this.$refs.timer.stop();
      },
      resetTimer() {
        this.timer = this.currentStep.time ? Math.floor(this.currentStep.time / 60) : 0;
        if (this.$refs.timer) this.$refs.timer.reset();
      },
      clickStep(step) {
        this.stopTimer();
        if (this.dynamicModule) {
          if (step == this.currentStep) {
            if (this.currentStep.status == 'play') {
              this.validateCurrentStep();
              this.playNextStep();
            } else {
              this.playStep(step);
            }
            return;
          }
          if (this.currentStep && this.currentStep.status == 'play') {
            this.validateCurrentStep();
          }
          this.playStep(step);
        }
        this.currentStep = step;
        this.resetTimer();
        this.$nextTick(() => { this.$vuetify.goTo(0); });
      },
      playStep(step) {
        if (! this.dynamicModule) return;
        this.filteredMeetingSteps.forEach((item) => { if (item.status == 'play') item.status = ''; });
        step.status = 'play';
        if (step.timePerAttendee) {
          if (step == this.currentStep && this.$refs.currentModule && this.$refs.currentModule.startOfTurn) this.$refs.currentModule.startOfTurn();
        } else {
          this.playTimer();
        }
        this.saveCurrentStep();
      },
      playCurrentStep() {
        this.playStep(this.currentStep);
      },
      validateCurrentStep(bypassTimePerAttendee) {
        this.displayedStep = this.currentStep;
        const time = this.$refs.timer.elapsedTime();
        this.stopTimer();
        if (this.currentStep.timePerAttendee && ! bypassTimePerAttendee) {
          if (this.$refs.currentModule.endOfTurn) this.$refs.currentModule.endOfTurn(time);
        } else if (this.currentStep) {
          this.currentStep.time_spent = time;
          this.currentStep.status = 'done';
        }
      },
      playNextStep() {
        this.initCurrentStep();
        if (! this.currentStep.status) this.playStep(this.currentStep);
      },
      saveCurrentStep() {
        this.meeting.saveFields('steps', true);
      },
      timeSecInMin(seconds) {
        return Math.floor(Math.abs(seconds) / 60) || 0;
      },
      endAnimation() {
        this.meeting.status = 'minutes';
        this.meeting.saveFields('status', true);
        this.$router.push({ name: 'Minutes' });
      },
    },
  };
</script>
