<template>
  <div>
    <p>{{ $t('LOGIN.REMIND_PASSWORD_DESC') }}</p>
    <form @submit.prevent="submitRemindPassword">
      <v-text-field id="emailField" v-model="email" :label="$t('GLOBAL.EMAIL')" :error-messages="error" :success-messages="success" prepend-icon="fas fa-at"
                    required autofocus>
      </v-text-field>
      <v-btn :disabled="isSubmittingRemind" :loading="isSubmittingRemind" color="accent" rounded large block type="submit" class="mt-2">
        {{ $t('LOGIN.REMIND_PASSWORD') }}
      </v-btn>
    </form>
    <div class="mt-6">
      <v-btn text block class="ma-0" @click.prevent="$emit('navigate-section', 'register')">{{ $t('LOGIN.CREATE_ACCOUNT') }}</v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        email: "",
        error: "",
        success: "",
        isSubmittingRemind: false,
      };
    },
    methods: {
      submitRemindPassword() {
        this.error = "";
        this.success = "";
        if (! window.isValidEmailAddress(this.email)) {
          this.error = this.$t("LOGIN.ERROR_INVALID_EMAIL");
          return;
        }

        this.isSubmittingRemind = true;
        window.apiSrv.call('password/remind', 'store', { email: this.email }).then((response) => {
          this.success = response && response.data && response.data.message;
          this.email = "";
          this.isSubmittingRemind = false;
        }).catch((error) => {
          this.error = error.message;
          this.isSubmittingRemind = false;
        });
      },
    },
  };
</script>
