<template>
  <v-dialog :value="displayLoginWindow" :max-width="modalSize" persistent content-class="white" style="border-radius: 12px">
    <login-window @navigate="navigate"></login-window>
  </v-dialog>
</template>

<script>
  import LoginWindow from './LoginWindow';

  export default {
    components: {
      LoginWindow,
    },
    data() {
      return {
        modalSize: '360px',
      };
    },
    computed: {
      displayLoginWindow() {
        return this.$store.state.ui.displayLoginWindow;
      },
    },
    methods: {
      navigate(section) {
        this.modalSize = section == 'register' ? '600px' : '360px';
      },
    },
  };
</script>
