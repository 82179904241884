<template>
  <v-app class="cream-pixel-bg">
    <side-drawer v-if="routeNeedsMeeting && isLoaded"></side-drawer>
    <navigation :display-meeting-menu="routeNeedsMeeting && isLoaded"></navigation>
    <v-main style="z-index:0;">
      <premium-trial-alert></premium-trial-alert>
      <v-alert :value="!! showNewsAlert" color="info" icon="fas fa-gift" dismissible tile transition="slide-y-transition" class="mt-1 mb-0">
        <div>
          <b>Nouveauté : vous pouvez maintenant dupliquer une réunion</b> pour créer la réunion suivante plus rapidement
          (menu <v-btn icon small color="primary" style="position: relative; top: -2px"><v-icon small>fas fa-ellipsis-v</v-icon></v-btn> dans la liste des réunions)
        </div>
      </v-alert>
      <router-view v-if="isLoaded"></router-view>
      <div v-if="! isLoaded && ! error.message && ! loadMeetingError" class="my-12 text-center">
        <v-progress-circular :size="70" indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-if="! isLoaded && loadMeetingError" class="pa-4">
        {{ loadMeetingError }}
      </div>
    </v-main>
    <login-window-modal></login-window-modal>
    <modal-with-background :is-open="openErrorModal" :no-close="true" title="Erreur" persistent>
      <h3 class="text-center">{{ error.message }}</h3>
      <div class="text-center mt-6">
        <v-btn v-if="error.canClose" :to="closeErrorRedirection" rounded @click="error.message = ''">Continuer</v-btn>
      </div>
    </modal-with-background>
    <saving-bar></saving-bar>
  </v-app>
</template>

<style>
  /* credit: Background pattern from Toptal Subtle Patterns (https://www.toptal.com/designers/subtlepatterns) */
  .cream-pixel-bg {
    background: url('assets/images/background/cream_pixels.png');
  }
</style>

<script>
  import { mapState } from 'vuex';
  import Navigation from '@/components/Navigation/Navigation';
  import SideDrawer from '@/components/SideDrawer/SideDrawer';
  import PremiumTrialAlert from '@/components/Premium/PremiumTrialAlert';
  import LoginWindowModal from '@/components/User/LoginWindowModal';
  import SavingBar from '@/components/Reusables/SavingBar';

  const routesWithoutMeeting = ['List', 'ActionsList', 'MinutesDashboard', 'Manage'];

  export default {
    components: {
      Navigation,
      SideDrawer,
      PremiumTrialAlert,
      LoginWindowModal,
      SavingBar,
    },
    data() {
      return {
        me: {},
        meetingId: this.$route.params.meetingId,
        loadMeetingError: "",
      };
    },
    computed: {
      isLoaded() {
        return ! this.routeNeedsMeeting || (this.meetingLoaded && this.user.id > 0);
      },
      routeNeedsMeeting() {
        return ! routesWithoutMeeting.includes(this.$route.name);
      },
      openErrorModal() {
        return !! this.error.message;
      },
      closeErrorRedirection() {
        let link = this.error.backToList ? 'List' : null;
        link = this.error.backToHome ? 'Home' : link;
        return link ? { name: link } : {};
      },
      showNewsAlert() {
        return this.user.last_updated_at && moment(this.user.last_updated_at).isBefore('2019-02-12');
      },
      ...mapState(['error']),
      ...mapState('current', ['meeting', 'meetingLoaded']),
      ...mapState('users', ['user']),
    },
    watch: {
      '$route.params.meetingId': function onChangeMeeting(meetingId) {
        if (meetingId) this.loadMeeting(meetingId);
      },
      '$route.name': function onChangeRouteName(routeName) {
        if (routeName == 'Settings' && this.$route.params.meetingId == this.meeting.id) this.loadMeeting(this.meeting.id); // refresh meeting data
      },
    },
    created() {
      if (this.$route.params.meetingId) this.loadMeeting(this.$route.params.meetingId);
      this.$store.dispatch('users/load');
    },
    methods: {
      loadMeeting(meetingId) {
        this.loadMeetingError = "";
        this.$store.dispatch('loadMeeting', meetingId).catch((error) => {
          this.loadMeetingError = error.message;
        });
      },
    },
  };
</script>
