<template>
  <div>
    <div class="primary--text pa-4" style="border-bottom: 3px solid">
      <span class="text-h4">{{ $t(sectionTitles[section]) }}</span>
    </div>
    <div class="pa-6" style="max-height: 90vh; flex: 1 1 auto">
      <template v-if="section == 'login'">
        <form ref="formLogin" @submit.prevent="submitLogin">
          <v-text-field v-model="email" :label="$t('GLOBAL.EMAIL')" :disabled="isSubmittingLogin"
                        :rules="emailRules" validate-on-blur prepend-icon="fas fa-at" autocomplete="email" autofocus required>
          </v-text-field>
          <v-text-field v-model="password" :label="$t('GLOBAL.PASSWORD')" :disabled="isSubmittingLogin"
                        :type="hidePass ? 'password' : 'text'" :append-icon="hidePass ? 'fas fa-eye' : 'fas fa-eye-slash'"
                        :rules="passwordRules" :error-messages="loginMsg" prepend-icon="fas fa-lock" required
                        @click:append="hidePass = ! hidePass">
          </v-text-field>
          <v-text-field v-if="showTfa" v-model="tfaCode" label="2FA" :disabled="isSubmittingLogin"
                        hide-details prepend-icon="fas fa-phone">
          </v-text-field>
          <div ref="formCaptcha" class="mt-6"></div>
          <div class="text-center mt-6">
            <v-btn :disabled="isSubmittingLogin" :loading="isSubmittingLogin" type="submit" color="accent" rounded large>
              {{ $t('LOGIN.SUBMIT') }}
            </v-btn>
          </div>
        </form>
        <div class="mt-6">
          <v-btn text block @click.prevent="navigateSection('password-remind')">{{ $t('LOGIN.FORGOT_PASSWORD') }}</v-btn>
          <v-btn text block @click.prevent="navigateSection('register')">{{ $t('LOGIN.CREATE_ACCOUNT') }}</v-btn>
        </div>
      </template>
      <user-register v-if="section == 'register'" @navigate-section="navigateSection"></user-register>
      <user-password-remind v-if="section == 'password-remind'" @navigate-section="navigateSection"></user-password-remind>
      <user-password-reset v-if="section == 'password-reset'" @navigate-section="navigateSection"></user-password-reset>
      <!-- SECTION SSO -->
      <div v-if="isLoading" class="py-6 text-center"><i class="fas fa-spinner fa-spin fa-3x"></i></div>
      <div v-if="errorMessage" class="errorred--text" v-html="errorMessage"></div>
    </div>
  </div>
</template>

<script>
  import UserRegister from './UserRegister';
  import UserPasswordRemind from './UserPasswordRemind';
  import UserPasswordReset from './UserPasswordReset';

  const clientConfig = {};
  export default {
    components: {
      UserRegister,
      UserPasswordRemind,
      UserPasswordReset,
    },
    data() {
      return {
        section: clientConfig.sso ? 'sso' : 'login',
        sectionTitles: {
          login: 'LOGIN.TITLE',
          register: 'LOGIN.REGISTER_TITLE',
          'password-remind': 'LOGIN.REMIND_PASSWORD_TITLE',
          'password-reset': 'LOGIN.RESET_PASSWORD_TITLE',
          sso: 'Authentification',
        },
        email: "",
        emailRules: [
          v => !! v || "Renseignez votre e-mail",
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Le format d'e-mail est invalide",
        ],
        password: "",
        passwordRules: [
          v => !! v || "Renseignez votre mot de passe",
        ],
        hidePass: true,
        showTfa: false, // 2FA authentification
        tfaCode: undefined,
        loginMsg: "",
        isSubmittingLogin: false,
        isLoading: false,
        errorMessage: "",
      };
    },
    watch: {
      $route() {
        this.readQueryParams();
      },
    },
    created() {
      this.readQueryParams();
      if (clientConfig.sso) this.handleSSO();
    },
    methods: {
      readQueryParams() {
        if (this.$route.query.form == 'reset' && this.$route.query.token) {
          const query = { ...this.$route.query, resettoken: this.$route.query.token, form: undefined, token: undefined };
          this.$router.replace({ query });
        }
        if (this.$route.query.email) {
          const query = { ...this.$route.query, registeremail: this.$route.query.email, email: undefined };
          this.$router.replace({ query });
        }

        if (this.$route.query.resettoken) {
          this.navigateSection('password-reset');
        } else if (this.$route.query.registeremail) {
          this.navigateSection('register');
        }
      },
      navigateSection(section) {
        this.section = section;
        this.$emit('navigate', section);
      },
      submitLogin() {
        this.isSubmittingLogin = true;
        const captcha = (this.$refs.formLogin.querySelector("[name='g-recaptcha-response']") || {}).value;
        this.$refs.formCaptcha.innerHTML = '';
        window.apiSrv.login({ email: this.email, password: this.password, tfa_code: this.tfaCode, captcha }).catch((error) => {
          this.isSubmittingLogin = false;
          this.loginMsg = error.message;

          if (error.status == 423) {
            if (error.message == 'enter tfa code') {
              this.showTfa = true;
              return;
            }
            window.apiSrv.call('users/login/captcha').then((captchaResponse) => {
              this.$refs.formCaptcha.innerHTML = captchaResponse.data;
              // execute <script src="https://www.google.com/recaptcha/api.js?" async defer>
              const script = document.createElement("script");
              script.src = 'https://www.google.com/recaptcha/api.js?';
              document.body.appendChild(script);
            });
          }
        });
      },
      handleSSO() {
        if (! clientConfig.sso) return;
        this.isLoading = true;
        const responseType = clientConfig.sso.response_type || 'ticket';
        let responseData = window.location.search.match(new RegExp(`${responseType}=([^&]*)`));
        if (! responseData) responseData = window.location.hash.match(new RegExp(`${responseType}=([^&]*)`));
        if (responseData) {
          if (window.history) window.history.replaceState(null, null, './');

          // if (clientConfig.sso.protocol == 'PKCE') {
          //   PKCE.validate(responseData[1]).then((message) => {
          //     // TO DO
          //     this.errorMessage = message;
          //   }).catch((error) => {
          //     this.errorMessage = `Erreur : ${error}`;
          //   }).finally(() => { this.isLoading = false; });
          //   return;
          // }

          const ssoRedirect = window.sessionStorageWrapper.getItem('sso_redirect');
          window.apiSrv.ssoLogin({
            protocol: clientConfig.sso.protocol,
            [responseType]: responseData[1],
            allow_sso_anonymous: /#\/(viewer|planningview)\/.*\?(view)?rotoken=/i.test(ssoRedirect) || undefined,
          }).then(() => {
            if (ssoRedirect) {
              window.location.replace(ssoRedirect);
              window.sessionStorageWrapper.removeItem('sso_redirect');
            }
            window.location.reload();
          }).catch((error) => {
            this.isLoading = false;
            this.errorMessage = `Erreur : ${error.message}`;
          });
        } else {
          window.sessionStorageWrapper.setItem('sso_redirect', window.location.href);
          // if (clientConfig.sso.protocol == 'PKCE') {
          //   PKCE.login();
          //   return;
          // }
          window.location.href = clientConfig.sso.login;
        }
      },
    },
  };
</script>
