<template>
  <div style="position: relative;">
    <h1 class="secondary--text pb-8 text-center">{{ step.title }}</h1>
    <attendees-list :step="step" :dynamic-module="dynamicModule" :selected-attendee="currentAttendee" @select="selectAttendee"></attendees-list>

    <template v-if="currentKpis">
      <h2 class="text-center mt-6">
        {{ currentKpis.content.title }}
      </h2>
      <v-row class="px-4">
        <v-col v-for="(kpi, index) in kpiData" :key="index" :class="'col-sm-' + kpi.cols" class="mb-6 pa-2" style="max-height: 100%">
          <v-card class="pa-2" style="height: 100%">
            <div class="text-center"><span :class="kpi.mood" class="mood-icon" style="height:60px; width:60px"></span></div>
            <div v-if="kpi.type == 'text'" class="pre px-4">{{ kpi.value }}</div>
            <div v-if="kpi.type == 'image' && kpi.url" class="text-center"><img :src="kpi.url" style="max-width: 100%"></div>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-card v-else class="pa-6 pb-12" style="opacity: 0.3">
      <span class="text-h6">{{ currentAttendee ? 'Aucun indicateur' : 'Sélectionnez un participant' }}</span>
    </v-card>
  </div>
</template>

<style scoped>
  .mood-icon {
    width: 40px;
    height: 40px;
  }
</style>

<script>
  import { mapState } from 'vuex';
  import AttendeesList from './partials/AttendeesList';
  import TimePerAttendeeMixin from './partials/TimePerAttendeeMixin';

  export default {
    components: { AttendeesList },
    mixins: [TimePerAttendeeMixin],
    props: {
      step: { required: true, type: Object },
      dynamicModule: { type: Boolean, default: true },
    },
    data() {
      return {
        currentKpis: null,
        currentAttendee: null,
      };
    },
    computed: {
      kpisList() {
        return this.meeting.modules.filter(module => module.module_name == this.step.module_name && module.content && module.content.data && module.content.data.length)
          .reduce((prevKpis, module) => {
            prevKpis[module.user_id] = module;
            return prevKpis;
          }, {
          });
      },
      kpiData() {
        return this.currentKpis && this.currentKpis.content && this.currentKpis.content.data.filter(kpi => kpi.mood || kpi.url || kpi.value);
      },
      ...mapState('current', ['meeting']),
    },
    methods: {
      selectAttendee(user) {
        if (this.dynamicModule) {
          if (this.currentAttendee) {
            if (this.currentAttendee.id == user.id) {
              this.$emit('stop-turn');
              this.currentKpis = null;
              this.currentAttendee = null;
              return;
            }
            this.$emit('stop-turn');
          }
          delete this.step.users_time[user.id];
          this.$emit('begin-turn');
        }
        this.currentKpis = this.kpisList[user.id];
        this.currentAttendee = user;
      },
    },
  };
</script>
