<template>
  <div>
    <text-editor id="minutesEditor" v-model="module.content.text" class="ma-2" placeholder="Texte à afficher" @input="saveModule()"></text-editor>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import TextEditor from '@/components/Reusables/TextEditor';

  export default {
    components: { TextEditor },
    props: {
      moduleName: { required: true, type: String },
    },
    data() {
      return {
        module: {},
      };
    },
    computed: {
      ...mapState('current', ['meeting', 'meetingUser']),
    },
    created() {
      this.module = this.meeting.getUserModule(this.moduleName, this.meetingUser, { text: '' });
    },
    methods: {
      saveModule: _.debounce(function () { this.meeting.sendModule(this.module); }, 700),
    },
  };
</script>
