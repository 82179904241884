<template>
  <div>
    <h3>{{ step.title }} </h3>
    <template v-for="(review, index) in reviews">
      <!-- Username -->
      <strong style="display: flex; align-items: center;">
        <img :src="review.user_avatar" :alt="review.user_name" class="v-avatar ma-2" style="height: 25px; width: 25px;">
        <span>{{ review.user_name }} </span>
        <em v-if="step.users_time && step.users_time[review.user_id]" style="color: rgb(136, 136, 136);">{{ secToMin(step.users_time[review.user_id]) }}</em>
      </strong>
      <!-- Review -->
      <template v-if="review.text || reviewsIcons[review.mark]">
        <ul :key="index">
          <li>
            <span>{{ review.text }} </span>
            <span v-if="reviewsIcons[review.mark]" :class="`far ${reviewsIcons[review.mark]}`"></span>
          </li>
        </ul>
      </template>
    </template>
  </div>
</template>

<script>
  import meetingClosureModuleMixin from './meetingClosureModuleMixin';

  export default {
    mixins: [meetingClosureModuleMixin],
    data() {
      return {
        reviewsIcons: { 1: 'fa-frown', 2: 'fa-meh', 3: 'fa-smile', 4: 'fa-grin-beam' },
      };
    },
    computed: {
      reviews() {
        const { meetingUser } = this.$store.state.current;
        const module = this.meeting.getUserModule(this.step.module_name, meetingUser, []);
        return this.meeting.users.reduce((prevReviews, user) => {
          let review = null;
          if (module.content && module.content[user.id]) {
            review = {
              ...module.content[user.id],
              user_id: user.id,
              user_avatar: user.avatar,
              user_name: this.$store.getters['users/getUsername'](user),
            };
          } else {
            review = {
              text: "",
              mark: null,
              user_id: user.id,
              user_avatar: user.avatar,
              user_name: this.$store.getters['users/getUsername'](user),
            };
          }
          prevReviews[user.id] = review;
          return prevReviews;
        }, {});
      },
    },
  };
</script>
