<template>
  <v-row>
    <v-col md="6" cols="12">
      <v-card class="pa-4 white" style="height: 100%;display: flex; align-items: center; justify-content: center;">
        <img v-if="moduleId == 'moods'" src="@/assets/images/onBoarding/moods.png" style="display: flex; width:100%">
        <img v-if="moduleId == 'news'" src="@/assets/images/onBoarding/news.png" style="display: flex; width:100%; max-width: 600px">
        <img v-if="moduleId == 'actionsevolution'" src="@/assets/images/onBoarding/actionsevolution.png" style="display: flex;max-width: 650px">
        <img v-if="moduleId == 'topics'" src="@/assets/images/onBoarding/topics.png" style="display: flex; width:100%; max-width: 600px">
        <img v-if="moduleId == 'issues'" src="@/assets/images/onBoarding/issues.png" style="display: flex; width:100%">
        <img v-if="moduleId == 'kpis'" src="@/assets/images/onBoarding/kpis.png" style="display: flex; width:100%">
        <img v-if="moduleId == 'reviews'" src="@/assets/images/onBoarding/reviews.png" style="display: flex; width:100%">
      </v-card>
    </v-col>
    <v-col md="6" cols="12">
      <div class="display-flex flex-column" style="height: 100%">
        <v-card class="py-4 px-8">
          <div class="onboarding-card-title">
            Quand utiliser le module « {{ module.title }} » ?
          </div>
          <span class="onboarding-module-description" v-html="module.use"></span>
        </v-card>
        <v-card class="py-4 px-8 mt-3">
          <div class="onboarding-card-title">
            Quelle Préparation ?
          </div>
          <span class="onboarding-module-description" v-html="module.preparation"></span>
        </v-card>
        <v-card class="py-4 px-8 mt-3">
          <div class="onboarding-card-title">
            Quelle Animation dans la réunion ?
          </div>
          <span class="onboarding-module-description" v-html="module.animation"></span>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<style>
  .onboarding-module-description i.fas {
    display: inline-flex;
    margin-right: 5px;
  }
</style>

<script>
  export default {
    props: {
      moduleId: { required: true, type: String },
    },
    data() {
      return {
        modulesContent: {
          news: {
            title: "Présentation par l'organisateur",
            use: `Ce module est indiqué pour donner un temps de parole à l’organisateur, par exemple en introduction de la réunion.
Ce module peut également servir de support à des points de discussion de l’ordre du jour.`,
            preparation: `<p><i class="fas fa-fw fa-user-times"></i>Les participants n'ont rien à préparer.</p>
<i class="fas fa-fw fa-lock"></i>Les organisateurs peuvent saisir un texte à afficher lors de la phase d'Animation dans un éditeur de texte formatable.`,
            animation: "En phase d'Animation, les textes saisis par les organisateurs sont affichés à l'écran.",
            image: "@/assets/images/onBoarding/news.png",
          },
          moods: {
            title: "Tour de table des actus",
            use: `Ce module est indiqué pour faire un tour des participants en début de réunion, notamment lors d'une réunion de service.<br>
Chacun dispose alors d'un temps de parole chronométré pour partager ses actualités.`,
            preparation: `<i class="fas fa-fw fa-user"></i>Tous les participants disposent d'une slide dédiée dans laquelle ils peuvent indiquer
leur humeur / ressenti du moment à l'aide d'une icône météo, l’actualité à partager, les bonnes nouvelles (top),
les mauvaises nouvelles (flop) et les nouveaux arrivants ou départs dans l’équipe.`,
            animation: `En phase d'Animation, tous les participants ayant préparé le module sont affichés dans un tableau récapitulatif.<br>
Le clic sur un participant affiche sa slide et déclenche le chronomètre.`,
            image: "@/assets/images/onBoarding/moods.png",
          },
          topics: {
            title: "Sujets libres",
            use: `Ce module donne à tous les participants la possibilité de proposer des sujets de discussion.`,
            preparation: `<p><i class="fas fa-fw fa-user"></i>Tous les participants peuvent proposer un ou plusieurs sujets. En phase de Préparation, chacun ne voit que les sujets qu'il a proposés.</p>
<i class="fas fa-fw fa-lock"></i>Les organisateurs ont accès à tous les sujets et peuvent les modifier ou les supprimer.`,
            animation: `En phase d’Animation, tous les sujets sont listés en précisant leur auteur.`,
            image: "@/assets/images/onBoarding/topics.png",
          },
          issues: {
            title: "Points d'attention et prises de décisions",
            use: `Ce module permet de soulever des points d'attention ou de blocage que les participants ont pu rencontrer.<br>
Les points d'attention sont ensuite triés pour faciliter la prise de décision.`,
            preparation: `<p><i class="fas fa-fw fa-user"></i>Tous les participants peuvent remplir leurs points d'attention à traiter. En phase de Préparation, chacun ne voit que ceux qu'il a ajoutés.</p>
<i class="fas fa-fw fa-lock"></i>Les organisateurs ont accès à tous les points d'attention et peuvent les modifier ou les supprimer.`,
            animation: `En phase d'Animation, tous les points d'attention doivent être triés.<br>
Un point d'attention peut donner lieu soit à une action affectée à un intervenant pour une date d’échéance donnée, soit à une décision globale d'équipe.<br>
Si le point d'attention ne peut pas être trié immédiatement, il peut être placé au « Frigo » et réapparaîtra dans les réunions suivantes.`,
            image: "@/assets/images/onBoarding/issues.png",
          },
          actionsevolution: {
            title: "Revue des actions",
            use: `Ce module permet de passer en revue les actions décidées dans les précédentes réunions de la même suite de réunions.<br>
Les actions pourront provenir du module « Points d'attention et prises de décisions » d'une précédente réunion.`,
            preparation: `<i class="fas fa-fw fa-user"></i>La phase de Préparation est l'occasion pour les participants de mettre à jour le suivi des actions les concernant directement.<br>
Les actions sont triées en 3 colonnes et peuvent être modifiées ou marquées comme complétées.`,
            animation: `En phase d'Animation, toutes les actions des précédentes réunions sont affichées.
L'organisateur peut les mettre à jour en séance.`,
            image: `@/assets/images/onBoarding/actionsevolution.png`,
          },
          kpis: {
            title: "Revue des indicateurs",
            use: `Ce module permet aux participants de présenter des indicateurs texte ou image.<br>Des interfaces avec des modules de gestion de tableaux de bord sont possibles.`,
            preparation: `<i class="fas fa-fw fa-user"></i>Tous les participants sont libres de constituer leur slide d'indicateurs en leur attachant une icone d'humeur météo.<br>
La taille et la disposition des indicateurs sont libres.`,
            animation: `En phase d'Animation, les slides préparées par les participants sont affichées telles que préparées.`,
            image: "@/assets/images/onBoarding/kpis.png",
          },
          reviews: {
            title: "Tour de table d’évaluation",
            use: `Ce module permet une évaluation en séance par les participants. Il peut être utilisé par exemple en fin de réunion pour une auto-évaluation de l'efficacité de la réunion.<br>
Ou bien pour toute autre question sur laquelle l'organisateur souhaite sonder les participants, en personnalisant le titre de l'étape.`,
            preparation: `<i class="fas fa-fw fa-user-times"></i>Ce module ne nécessite pas de préparation.`,
            animation: `Chaque clic sur un participant déclenche le chronomètre. Le participant peut exprimer un ressenti général puis détailler avec un texte libre. Les données sont saisies par l'organisateur.`,
            image: "@/assets/images/onBoarding/reviews.png",
          },
        },
      };
    },
    computed: {
      module() {
        return this.modulesContent[this.moduleId];
      },
    },
  };
</script>
