import Meeting from '@/models/Meeting';

function getInitialState() {
  return {
    inprogress: false,
    success: false,
    error: false,
    saveagain: false,
    message: "",
    failedRequests: [],
  };
}

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset(state, values) {
      _.extend(state, getInitialState(), values);
    },
    start(state) {
      _.extend(state, { inprogress: true, success: false, error: false, saveagain: false });
    },
    success(state) {
      _.extend(state, { inprogress: false, success: true, error: false });
      setTimeout(() => { state.success = false; }, 2000);
    },
    error(state, { error, request }) {
      _.extend(state, { inprogress: false, success: false, error: error.message || true });
      const prevFailedRequest = state.failedRequests[state.failedRequests.length - 1];
      if (request && ! _.isEqual(request, prevFailedRequest)) {
        if (prevFailedRequest && request.api == prevFailedRequest.api && request.action == prevFailedRequest.action && request.data && prevFailedRequest.data && request.data.id == prevFailedRequest.data.id) {
          // Merge text update requests for example
          _.extend(prevFailedRequest, request);
        } else {
          state.failedRequests.push(request);
        }
      }
    },
    resetFailedRequests(state) {
      state.failedRequests = [];
    },
    pushFailedRequests(state, requests) {
      if (! requests) return;
      requests.forEach((request) => {
        state.failedRequests.push(request);
      });
    },
  },
  actions: {
    async retryFailedRequests({ state, commit }) {
      const requests = state.failedRequests.slice();
      commit('resetFailedRequests');
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < requests.length; i++) {
        const { api, action, data } = requests[i];
        await Meeting.sendRequest(api, action, data).catch((error) => {
          commit('pushFailedRequests', requests.slice(i + 1));
          throw error;
        });
      }
    },
    abandonErrors({ commit }) {
      commit('reset');
      commit('resetFailedRequests');
    },
  },
};
