<template>
  <v-row class="onboarding-phases">
    <v-col v-for="(phase, index) in phasesContent" :key="phase.title" :style="{ 'z-index': phasesContent.length - index }" style="position: relative;" lg="3" md="6">
      <v-card class="mx-4" style="height: 100%; position: relative; z-index: 0">
        <div class="phase-header white secondary--text pt-1" style="line-height: 1.1; display: flex; justify-content: center; align-items: center">
          <div class="text-center" style="line-height: 1.1;" v-html="phase.title"></div>
          <i v-if="index != phasesContent.length - 1" class="primary--text fas fa-chevron-right" style="position: absolute; right: -37px; font-size: 40px;"></i>
        </div>
        <div class="px-4 pb-6">
          <p style="font-style: italic" v-html="phase.introduction"></p>
          <p v-if="phase.lock">
            <v-icon small class="fa-fw">fas fa-lock</v-icon> <span v-html="phase.lock"></span>
          </p>
          <p>
            <v-icon small class="fa-fw">fas {{ phase.noUser ? 'fa-user-times' : 'fa-user' }}</v-icon> <span v-html="phase.user"></span>
          </p>
          <template v-if="phase.transition">
            <div class="mt-0 mb-1"><v-icon small class="fa-fw">fas fa-hourglass-end</v-icon> <b>Fin de phase</b></div>
            <div class="white py-1 px-2 mb-1 text-center" style="box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12); font-size: 17px">
              <v-icon class="pr-2 fa-fw" small>fas fa-mouse-pointer</v-icon>
              {{ phase.transition.title }}
            </div>
            <i style="line-height: 1.2"> {{ phase.transition.text }} </i>
          </template>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<style>
  .onboarding-phases .phase-header {
    width: 100%;
    font-size: 32px;
    font-family: 'Comfortaa', 'Lato', 'sans-serif';
    text-align: center;
    min-height: 4em;
  }

  .onboarding-phases .fa-fw {
    justify-content: left;
  }
</style>

<script>
  export default {
    data() {
      return {
        phasesContent: [
          {
            title: "Brouillon",
            introduction: "Création de l'espace de réunion et invitation des participants.",
            lock: "<b>Le créateur</b> peut paramétrer la réunion puis la publier. La publication déclenche l’envoi d’invitations aux participants.",
            noUser: true,
            user: "Les autres participants ne voient pas encore la réunion.",
            transition: { title: "Publication", text: " Après cette phase, il est possible de revenir modifier les paramètres plus tard." },
          },
          {
            title: "Préparation",
            introduction: "Préparation par tous les participants en amont de la réunion.",
            lock: "<b>Les organisateurs</b> peuvent modifier les paramètres de la réunion et démarrer la réunion.",
            user: "<b>Tous les participants</b> doivent préparer la réunion en complétant chaque module. Tous peuvent partager des documents et échanger des messages.",
            transition: { title: "Démarrer la réunion", text: "Après cette phase, la Préparation n’est plus modifiable par les participants." },
          },
          {
            title: "Animation <nobr>de réunion</nobr>",
            introduction: "Déroulé animé et chronométré pour une réunion efficace.",
            lock: "<b>Les organisateurs</b> contrôlent l’enchaînement des différents modules. Ils peuvent également remplir le compte rendu de réunion en séance.",
            user: "<b>Tous les participants</b> peuvent suivre la réunion, y compris à distance. Ils peuvent également prendre des notes.",
            transition: { title: "Fin de réunion", text: "Après cette phase, le compte rendu reste disponible pour validation." },
          },
          {
            title: "Finalisation",
            introduction: "Compte rendu et Contenu de la réunion conservés pour consultation ultérieure.",
            user: "<b>Tous les participants</b> peuvent voir le compte rendu. Toutes les données saisies dans la réunion restent accessibles, mais non modifiables.",
          },
        ],
      };
    },
  };
</script>
